import { useContext, useEffect, useState } from "react";
import { LanguegeContext } from "../../../../core/hooks/languages/LanguageContext";
import { regexList } from "../../../../core/utils/validationRegex";
import PropsPage from "../../models/propsWizardPage";
import InputFieldText from "../../../../core/components/formFields/InputFieldTextComponent";
import InputFieldSelect from "../../../../core/components/formFields/InputFieldSelectComponent";
import { InputSubsidiaryNames } from "../../models/inputSubsidiaryNames";
import getCategories from "../../service/getCategories.service";
import getSquares from "../../service/getSquares.service";
import getCompanies from "../../../companies/service/getCompanies.service";
import Company from "../../../companies/model/company.model";
import InputFieldMultipleSelect from "../../../../core/components/formFields/InputFieldMultipleSelectComponent";

export default function GeneralDataComponent(props: PropsPage) {
  const { json } = useContext(LanguegeContext);
  const [categories, setCategories] = useState<string[]>([]);
  const [squares, setSquares] = useState<string[]>([]);
  const [companies, setCompanies] = useState<string[]>([]);
  const [activeChecked, setActiveChecked] = useState(false);

  const [validityPage, setValidityPage] = useState({
    name: false,
    description: false,
    webPage: false,
  });

  useEffect(() => {
    setActiveChecked(props.company?.active || props.item?.active || false);

    setValidityPage({
      name: !!props.company || !!props.item,
      description: !!props.company || !!props.item,
      webPage: !!props.company || !!props.item,
    });
  }, [props.company]);

  useEffect(() => {
    async function fetchCategoriesAndSquares() {
      const responseCategiries = await getCategories();
      const responseSquares = await getSquares();
      if (props.isCompany) {
        const responseCompanies = await getCompanies();
        setCompanies(
          responseCompanies.map((company: Company) => {
            return company.name;
          })
        );
      }
      setCategories(responseCategiries);
      setSquares(responseSquares);
    }
    fetchCategoriesAndSquares();
  }, []);

  return (
    <fieldset className="col-sm-12 pb-3" hidden={props.page !== 1}>
      <legend className="bold mb-4">{json.companies.generalData}</legend>
      <p className="text-danger">{json.companies.requiredFields}</p>
      <div className="form-row">
        {props.isCompany && (
          <InputFieldSelect
            label={json.subsidiaries.company}
            items={companies}
            name="company"
          />
        )}
        <InputFieldText
          label={json.companies.name}
          name={InputSubsidiaryNames.Name}
          defaultValue={props.company?.name || props.item?.name || ""}
          placeholder={json.companies.namePlaceholder}
          regex={regexList.name}
          feedback={json.companies.nameFeedback}
          setValidity={(validity: boolean) =>
            setValidityPage({
              ...validityPage,
              name: validity,
            })
          }
          required
        />

        <InputFieldText
          label={json.companies.description}
          name={InputSubsidiaryNames.Description}
          defaultValue={
            props.company?.description || props.item?.description || ""
          }
          placeholder={json.subsidiaries.descriptionPlaceholder}
          regex={regexList.description}
          feedback={json.companies.descriptionFeedback}
          setValidity={(validity: boolean) =>
            setValidityPage({
              ...validityPage,
              description: validity,
            })
          }
          required
        />

        <InputFieldSelect
          name={InputSubsidiaryNames.Square}
          label={json.subsidiaries.square}
          items={squares}
        />

        <InputFieldMultipleSelect
          items={categories}
          label={json.subsidiaries.categorie}
          name={InputSubsidiaryNames.Categorie}
        />

        <InputFieldText
          label={json.companies.webPage}
          name={InputSubsidiaryNames.WebPage}
          defaultValue={props.company?.webPage || props.item?.webPage || ""}
          placeholder={json.companies.webPagePlaceholder}
          regex={regexList.URL}
          feedback={json.companies.webPageFeedback}
          setValidity={(validity: boolean) =>
            setValidityPage({
              ...validityPage,
              webPage: validity,
            })
          }
          required
        />
      </div>
      <div className="new">
        <p>{json.companies.active}</p>
        <div>
          <label className="ms-switch">
            <input
              type="checkbox"
              name={InputSubsidiaryNames.Active}
              checked={activeChecked}
              onChange={() => setActiveChecked(!activeChecked)}
            />
            <span className="ms-switch-slider round" />
          </label>
        </div>
      </div>

      <input
        type="button"
        value={json.companies.buttonCancel}
        className="btn btn-primary mx-2"
      />
      <input
        type="button"
        value={json.companies.buttonNext}
        className="btn btn-primary mx-2"
        onClick={() => props.setPage(props.page + 1)}
        disabled={
          !(
            validityPage.name &&
            validityPage.description &&
            validityPage.webPage
          )
        }
      />
    </fieldset>
  );
}
