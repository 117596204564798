import { Token } from "../models/token.model";

export function getRefreshToken(): string {
  return window.localStorage.getItem(Token.Refresh) || "";
}

export function getAccessToken(): string {
  return window.localStorage.getItem(Token.Access) || "";
}

export function updateTokens(access: string, refresh: string) {
  window.localStorage.setItem(Token.Access, access);
  window.localStorage.setItem(Token.Refresh, refresh);
}
