import { HttpMethod } from "../../../core/models/httpMethod";
import validateRequest from "../../../core/service/validateRequest.middleware";
import { getAccessToken } from "../../../core/storage/localStorage.db";
import { APISubsidiaryRoutes } from "../models/apiSubsidiaryRoutes";

export default async function deleteSubsidiary(id: string) {
  await validateRequest(
    HttpMethod.Delete,
    `${APISubsidiaryRoutes.Delete}${id}`,
    getAccessToken()
  );
}
