export default class AuthError extends Error {
  constructor() {
    super("Invalid Auth");

    this.name = "AuthError";

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, AuthError);
    }
  }
}
