import { ErrorForm } from "../../../../core/models/error.form.model";
import { FilterResult } from "../../model/filterResult.model";

export function isValidCompany(
  state: any,
  validStringRegex: RegExp
): FilterResult {
  if (state.searchCompany == undefined) {
    return { error: ErrorForm.Company };
  } else {
    if (
      state.searchCompany !== "" &&
      validStringRegex.test(state.searchCompany)
    ) {
      return {
        error: ErrorForm.None,
        url: `&companyID=${state.searchCompany}`,
      };
    } else {
      return { error: ErrorForm.Company };
    }
  }
}
