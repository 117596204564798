import { PaymentMethod } from "../model/paymentMethod.model";

export function getBarColor(method: PaymentMethod) {
  switch (method) {
    case PaymentMethod.Cash:
      return "#ff6384";
    case PaymentMethod.Card:
      return "#36a2eb";
    case PaymentMethod.Paypal:
      return "#cc65fe";
    case PaymentMethod.Specials:
      return "#ffce56";
    default:
      return "#ffffff";
  }
}
