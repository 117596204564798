import { ErrorForm } from "../../../../core/models/error.form.model";
import { FilterResult } from "../../model/filterResult.model";

export function isValidService(
  state: any,
  validStringRegex: RegExp
): FilterResult {
  if (state.searchService == undefined) {
    return { error: ErrorForm.Service };
  } else {
    if (
      state.searchService !== "" &&
      validStringRegex.test(state.searchService)
    ) {
      return { error: ErrorForm.None, url: `&services=${state.searchService}` };
    } else {
      return { error: ErrorForm.Service };
    }
  }
}
