import { Order } from "../../model/order.model";

export const mapOrdersData = (data: any[]): Order[] => {
  return data.map((item: any) => ({
    _id: item._id,
    orderId: item.orderId,
    companyInformation: {
      companyId: item.companyInformation.companyId,
      companyName: item.companyInformation.companyName,
      subsidaryId: item.companyInformation.subsidaryId,
      subsidaryName: item.companyInformation.subsidaryName,
      _id: item.companyInformation._id,
    },
    userInformation: {
      userId: item.userInformation.userId,
      fullName: item.userInformation.fullName,
      email: item.userInformation.email,
      phone: item.userInformation.phone,
      _id: item.userInformation._id,
      userContactInfo: `${item.userInformation?.fullName || ""}, ${
        item.userInformation?.email || ""
      }, ${item.userInformation?.phone || ""}`,
    },
    paymentInformation: {
      paymentId: item.paymentInformation.paymentId,
      paymentType: item.paymentInformation.paymentType,
      cardBrand: {
        stripe: item.paymentInformation.cardBrand.stripe,
        _id: item.paymentInformation.cardBrand._id,
      },
      _id: item.paymentInformation._id,
    },
    dateTime: item.dateTime,
    status: item.status,
    discount: item.discount,
    serviceType: item.serviceType,
    total: item.total,
    createdAt: item.createdAt,
    updatedAt: item.updatedAt,
  }));
};
