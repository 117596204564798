import { useContext } from "react";
import { LanguegeContext } from "../../../../core/hooks/languages/LanguageContext";
import PropsPage from "../../models/propsWizardPage";
import { useJsApiLoader, GoogleMap, Marker } from "@react-google-maps/api";
import Preloader from "../../../../core/components/Preloader";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

export default function GoogleMapComponent(props: PropsPage) {
  const { json } = useContext(LanguegeContext);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API || "",
    libraries: ["places"],
  });

  if (!isLoaded) return <Preloader />;

  return (
    <fieldset className="col-sm-12 pb-3" hidden={props.page !== 9}>
      <legend className="bold mb-3">{json.subsidiaries.location}</legend>
      <div>
        <div className="mb-3">
          <GooglePlacesAutocomplete
            apiKey={process.env.REACT_APP_GOOGLE_MAPS_API || ""}
          />
        </div>

        <GoogleMap
          center={{ lat: 19.0620329, lng: -98.3074149 }}
          zoom={15}
          mapContainerStyle={{ width: "100%", height: "400px" }}
          options={{
            zoomControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
          }}
        >
          <Marker position={{ lat: 19.0620329, lng: -98.3074149 }} />
        </GoogleMap>
      </div>
      <input
        type="button"
        value={json.companies.buttonBack}
        className="btn btn-primary mx-2"
        onClick={() => props.setPage(props.page - 1)}
      />
      <input
        type="submit"
        value={json.companies.buttonEnd}
        className="btn btn-primary mx-2"
      />
    </fieldset>
  );
}
