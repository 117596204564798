import { FilterResult } from "../model/filterResult.model";
import { ErrorForm } from "../../../core/models/error.form.model";
import { isValidStatus } from "./validator/orders.checkStatus.validator";
import { isValidCompany } from "./validator/orders.checkCompanyID.validator";
import { isValidBranch } from "./validator/orders.checkBranchID.validator";
import { isValidCorporateID } from "./validator/orders.checkCorporateID.validator";

export interface ReportFilters {
  corporate?: string;
  status?: string;
  company?: string;
  branch?: string;
}

export function applyReportFilters(
  baseUrl: string,
  filters: ReportFilters
): FilterResult {
  const validStringRegex = /.*/;

  let url = baseUrl;
  let error = ErrorForm.None;

  if (filters.status) {
    const statusResult = isValidStatus(
      { searchStatus: filters.status },
      validStringRegex
    );
    if (statusResult.error === ErrorForm.None) {
      url += statusResult.url;
    } else {
      error = statusResult.error;
    }
  }
  if (filters.corporate) {
    const companyResult = isValidCorporateID(
      { searchCorporate: filters.corporate },
      validStringRegex
    );
    if (companyResult.error === ErrorForm.None) {
      url += companyResult.url;
    } else {
      error = companyResult.error;
    }
  }
  if (filters.company) {
    const companyResult = isValidCompany(
      { searchCompany: filters.company },
      validStringRegex
    );
    if (companyResult.error === ErrorForm.None) {
      url += companyResult.url;
    } else {
      error = companyResult.error;
    }
  }

  if (filters.branch) {
    const branchResult = isValidBranch(
      { searchBranch: filters.branch },
      validStringRegex
    );
    if (branchResult.error === ErrorForm.None) {
      url += branchResult.url;
    } else {
      error = branchResult.error;
    }
  }

  return { error, url };
}
