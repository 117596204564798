import { Modal } from "react-bootstrap";
import AddCompany from "./AddCompanyScreen";
import Logo from "../../../core/components/LogoComponent";
import ListCompanies from "./ListCompaniesScreen";
import { useContext, useState } from "react";
import LanguageChanger from "../../../core/components/LanguajeChangerComponent";
import BreadCrumb from "../components/BreadCrumbComponent";
import { LanguegeContext } from "../../../core/hooks/languages/LanguageContext";
import { Link } from "react-router-dom";
import useConfig from "../../../core/hooks/config/useConfig";
import { ModulesNames } from "../../dashboard/model/modulesNames.model";

export default function CompaniesScreen() {
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [sizeModal, setSizeModal] = useState<"lg" | "xl">("lg");
  const { json } = useContext(LanguegeContext);
  const config = useConfig();

  return (
    <div>
      <LanguageChanger />
      <Logo />
      <div className="ms-panel mb-0">
        <div className="ms-panel-header">
          <BreadCrumb />
          <h3 className="mb-0 mt-4">
            {config.includes(ModulesNames.Corporates)
              ? json.companies.title
              : json.dashboard["company"]}
          </h3>
        </div>
        {config.includes(ModulesNames.Corporates) && (
          <div className="ms-panel-header py-2">
            <Link
              onClick={() => setShowModalAdd(true)}
              to="#"
              className="btn btn-primary"
            >
              {json.companies.newCompanie}
            </Link>
          </div>
        )}
        <ListCompanies />
      </div>
      <Modal
        size={sizeModal}
        show={showModalAdd}
        onHide={() => setShowModalAdd(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <button
            type="button"
            className="close"
            onClick={() => setShowModalAdd(false)}
          >
            <span aria-hidden="true">×</span>
          </button>
          <AddCompany
            setSizeModal={setSizeModal}
            isCorporate={config.includes(ModulesNames.Corporates)}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}
