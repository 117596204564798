import { Link } from "react-router-dom";

function BreadCrumb() {
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb pl-0">
        <li className="breadcrumb-item">
          <Link to="/">
            <i className="material-icons">home</i> Dashboard
          </Link>
        </li>
        <li className="breadcrumb-item">
          <Link to="/empresas">Empresas</Link>
        </li>
      </ol>
    </nav>
  );
}

export default BreadCrumb;
