import { useState } from "react";

export default function InputFieldMultipleSelectComponent(props: {
  label: string;
  name: string;
  items: string[];
}) {
  const [inputs, setInputs] = useState<number[]>([1]);

  function handleNewInput(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    const newNumCategories = [...inputs, inputs.length + 2];
    setInputs(newNumCategories);
  }

  return (
    <div className="col-md-12 mb-2">
      <label htmlFor="validationCustom04">
        {props.label}
        <span className="text-danger"> *</span>
      </label>

      {inputs.map((input, key) => (
        <div className="input-group" key={key}>
          <select
            name={`${props.name}_${input}`}
            className="form-control form-select border-secondar"
            defaultValue="bebidas"
          >
            {props.items.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </select>
        </div>
      ))}

      <button className="btn btn-info mt-0 mb-3" onClick={handleNewInput}>
        Nueva categoria
      </button>
    </div>
  );
}
