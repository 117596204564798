import { useContext, useState } from "react";
import { LanguegeContext } from "../../../../core/hooks/languages/LanguageContext";
import { regexList } from "../../../../core/utils/validationRegex";
import PropsPage from "../../model/propsWizardPage.model";
import InputFieldText from "../../../../core/components/formFields/InputFieldTextComponent";
import { InputCorporateNames } from "../../model/inputCorporateNames";

export default function GeneralDataComponent(props: PropsPage) {
  const { json } = useContext(LanguegeContext);

  const [validityPage, setValidityPage] = useState({
    name: !!props.item,
    description: !!props.item,
    webPage: !!props.item,
  });

  return (
    <fieldset className="col-sm-12 pb-3" hidden={props.page !== 1}>
      <legend className="bold mb-4">{json.companies.generalData}</legend>
      <p className="text-danger">{json.companies.requiredFields}</p>
      <div className="form-row">
        <InputFieldText
          label={json.companies.name}
          name={InputCorporateNames.Name}
          placeholder={json.companies.namePlaceholder}
          defaultValue={props.item?.name || ""}
          regex={regexList.name}
          feedback={json.companies.nameFeedback}
          required
          setValidity={(validity: boolean) =>
            setValidityPage({ ...validityPage, name: validity })
          }
        />
        <InputFieldText
          label={json.companies.description}
          name={InputCorporateNames.Description}
          placeholder={json.corporates.descriptionPlaceholder}
          defaultValue={props.item?.description || ""}
          regex={regexList.description}
          feedback={json.companies.descriptionFeedback}
          required
          setValidity={(validity: boolean) =>
            setValidityPage({ ...validityPage, description: validity })
          }
        />
        <InputFieldText
          label={json.companies.webPage}
          name={InputCorporateNames.WebPage}
          placeholder={json.companies.webPagePlaceholder}
          defaultValue={props.item?.webPage || ""}
          regex={regexList.URL}
          feedback={json.companies.webPageFeedback}
          required
          setValidity={(validity: boolean) =>
            setValidityPage({ ...validityPage, webPage: validity })
          }
        />
      </div>
      <div className="new mb-4">
        <p>{json.companies.active}</p>
        <div>
          <label className="ms-switch">
            <input
              type="checkbox"
              name={InputCorporateNames.Generic}
              defaultChecked={props.item?.active}
            />
            <span className="ms-switch-slider round" />
          </label>
        </div>
      </div>
      <div className="new">
        <p>{json.corporates.generic}</p>
        <div>
          <label className="ms-switch">
            <input
              type="checkbox"
              name={InputCorporateNames.Active}
              defaultChecked={props.item?.generic}
            />
            <span className="ms-switch-slider round" />
          </label>
        </div>
      </div>

      <input
        type="button"
        value={json.companies.buttonCancel}
        className="btn btn-primary mx-2"
      />
      <input
        type="button"
        value={json.companies.buttonNext}
        className="btn btn-primary mx-2"
        onClick={() => props.setPage(props.page + 1)}
        disabled={
          !(
            validityPage.name &&
            validityPage.description &&
            validityPage.webPage
          )
        }
      />
    </fieldset>
  );
}
