import React, { useContext, useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { options } from "../../model/lineChartOptions";
import { LineChartData } from "../../model/lineChartData.model";
import { AppFiltersContext } from "../../../service/appFiltersContext";
import { fetchUsersChartData } from "../../service/usersChartFetch.service";
import { useHistory } from "react-router-dom";
import handleError from "../../../../../core/errors/handleError";

interface UsersChartComponentProps {
  setExecuteSearch: (searchFunction: () => void) => void;
}
const UsersChartComponent: React.FC<UsersChartComponentProps> = ({
  setExecuteSearch,
}) => {
  const { filters } = useContext(AppFiltersContext);
  const [chartData, setChartData] = useState<LineChartData>({
    labels: [],
    datasets: [],
  });

  const history = useHistory();
  const handleSearch = async () => {
    try {
      const newData = await fetchUsersChartData(filters);
      setChartData(newData);
    } catch (error) {
      history.push(handleError(error));
    }
  };
  useEffect(() => {
    handleSearch();
  }, []);
  useEffect(() => {
    setExecuteSearch(() => handleSearch);
  }, [setExecuteSearch, filters]);

  return (
    <>
      <Line data={chartData} options={options} />
    </>
  );
};

export default UsersChartComponent;
