import { useContext, useEffect, useState } from "react";
import Page1 from "../components/companyWizard/GeneralDataComponent";
import Page2 from "../components/companyWizard/ContactInfoComponent";
import Page3 from "../components/companyWizard/CollectionsAndCommissionsComponent";
import Page4 from "../components/companyWizard/ModalityComponent";
import Page5 from "../components/companyWizard/FiscalDetailsComponent";
import Page6 from "../components/companyWizard/FilesComponent";
import { LanguegeContext } from "../../../core/hooks/languages/LanguageContext";
import Company from "../model/company.model";
import { useHistory } from "react-router-dom";
import Modality from "../model/modality.model";
import PropsUpdateCompanies from "../model/propsUpdateCompanies.model";
import handleError from "../../../core/errors/handleError";
import updateCompany from "../service/updateCompany.service";
import { InputCompanyNames } from "../model/inputCompanyNames";

export default function UpdateCompanieScreen(props: PropsUpdateCompanies) {
  const { json } = useContext(LanguegeContext);
  const history = useHistory();

  const [page, setPage] = useState(1);

  useEffect(() => {
    if (page == 4) {
      props.setSizeModal("xl");
    } else {
      props.setSizeModal("lg");
    }
  }, [page]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const elements = e.currentTarget.elements;
    const name = (
      elements.namedItem(InputCompanyNames.Name) as HTMLInputElement
    ).value;
    const description = (
      elements.namedItem(InputCompanyNames.Description) as HTMLInputElement
    ).value;
    const webPage = (
      elements.namedItem(InputCompanyNames.WebPage) as HTMLInputElement
    ).value;
    const active = (
      elements.namedItem(InputCompanyNames.Active) as HTMLInputElement
    ).checked;

    const contactName = (
      elements.namedItem(InputCompanyNames.ContactName) as HTMLInputElement
    ).value;
    const contactEmail = (
      elements.namedItem(InputCompanyNames.ContactEmail) as HTMLInputElement
    ).value;
    const phone = (
      elements.namedItem(InputCompanyNames.Phone) as HTMLInputElement
    ).value;
    const countryCode = (
      elements.namedItem(InputCompanyNames.CountryCode) as HTMLInputElement
    ).value;
    const whatsapp = (
      elements.namedItem(InputCompanyNames.Whatsapp) as HTMLInputElement
    ).value;

    const commission = parseInt(
      (elements.namedItem(InputCompanyNames.Commission) as HTMLInputElement)
        .value
    );
    const licensee = (
      elements.namedItem(InputCompanyNames.Licensee) as HTMLInputElement
    ).value;
    const cash = (
      elements.namedItem(InputCompanyNames.Cash) as HTMLInputElement
    ).checked;
    const paypal = (
      elements.namedItem(InputCompanyNames.Paypal) as HTMLInputElement
    ).checked;
    const stripe = (
      elements.namedItem(InputCompanyNames.Stripe) as HTMLInputElement
    ).checked;

    const eatTheLine = (
      elements.namedItem(InputCompanyNames.EatTheLine) as HTMLInputElement
    ).checked;
    const minTimeEat = (
      elements.namedItem(InputCompanyNames.MinTimeEat) as HTMLInputElement
    ).value;
    const minConsumptionEat = (
      elements.namedItem(
        InputCompanyNames.MinConsumptionEat
      ) as HTMLInputElement
    ).value;
    const serviceEat = (
      elements.namedItem(InputCompanyNames.ServiceEat) as HTMLInputElement
    ).value;

    const delivery = (
      elements.namedItem(InputCompanyNames.Delivery) as HTMLInputElement
    ).checked;
    const minTimeDelivery = (
      elements.namedItem(InputCompanyNames.MinTimeDelivery) as HTMLInputElement
    ).value;
    const minConsumptionDelivery = (
      elements.namedItem(
        InputCompanyNames.MinConsumptionDelivery
      ) as HTMLInputElement
    ).value;
    const serviceDelivery = (
      elements.namedItem(InputCompanyNames.ServiceDelivery) as HTMLInputElement
    ).value;
    const zone = (
      elements.namedItem(InputCompanyNames.Zone) as HTMLInputElement
    ).value;
    const zone2 = (
      elements.namedItem(InputCompanyNames.Zone2) as HTMLInputElement
    ).value;
    const distinct = (
      elements.namedItem(InputCompanyNames.Distinct) as HTMLInputElement
    ).value;
    const range = (
      elements.namedItem(InputCompanyNames.Range) as HTMLInputElement
    ).value;
    const range2 = (
      elements.namedItem(InputCompanyNames.Range2) as HTMLInputElement
    ).value;

    const stadium = (
      elements.namedItem(InputCompanyNames.Stadium) as HTMLInputElement
    ).checked;
    const minTimeStadium = (
      elements.namedItem(InputCompanyNames.MinTimeStadium) as HTMLInputElement
    ).value;
    const minConsumptionStadium = (
      elements.namedItem(
        InputCompanyNames.MinConsumptionStadium
      ) as HTMLInputElement
    ).value;
    const serviceStadium = (
      elements.namedItem(InputCompanyNames.ServiceStadium) as HTMLInputElement
    ).value;

    const regime = (
      elements.namedItem(InputCompanyNames.Regime) as HTMLInputElement
    ).value;
    const business = (
      elements.namedItem(InputCompanyNames.Business) as HTMLInputElement
    ).value;
    const RFC = (elements.namedItem(InputCompanyNames.RFC) as HTMLInputElement)
      .value;
    const residence = (
      elements.namedItem(InputCompanyNames.Residence) as HTMLInputElement
    ).value;
    const representative = (
      elements.namedItem(InputCompanyNames.Representative) as HTMLInputElement
    ).value;
    const curp = (
      elements.namedItem(InputCompanyNames.Curp) as HTMLInputElement
    ).value;
    const entity = (
      elements.namedItem(InputCompanyNames.Entity) as HTMLInputElement
    ).value;
    const clabe = (
      elements.namedItem(InputCompanyNames.Clabe) as HTMLInputElement
    ).value;
    const billingEmail = (
      elements.namedItem(InputCompanyNames.BillingEmail) as HTMLInputElement
    ).value;

    const proofTaxSituation = (
      elements.namedItem(
        InputCompanyNames.ProofTaxSituation
      ) as HTMLInputElement
    ).value;
    const bankCover = (
      elements.namedItem(InputCompanyNames.BankCover) as HTMLInputElement
    ).value;
    const idCopy = (
      elements.namedItem(InputCompanyNames.IdCopy) as HTMLInputElement
    ).value;
    const alcoholLicense = (
      elements.namedItem(InputCompanyNames.AlcoholLicense) as HTMLInputElement
    ).value;

    const paymentMethods: string[] = [];
    if (cash) paymentMethods.push("Cash");
    if (paypal) paymentMethods.push("PayPal");
    if (stripe) paymentMethods.push("Stripe");

    const modalities: Modality[] = [];
    if (eatTheLine)
      modalities.push({
        eatTheLine: {
          minTime: +minTimeEat,
          minConsumption: +minConsumptionEat,
          service: +serviceEat,
        },
      });
    if (stadium)
      modalities.push({
        stadium: {
          minTime: +minTimeStadium,
          minConsumption: +minConsumptionStadium,
          service: +serviceStadium,
        },
      });
    if (delivery)
      modalities.push({
        delivery: {
          minTime: +minTimeDelivery,
          minConsumption: +minConsumptionDelivery,
          service: +serviceDelivery,
          zone: +zone,
          zone2: +zone2,
          distinct: +distinct,
          range: +range,
          range2: +range2,
        },
      });

    const documents = { proofTaxSituation, bankCover, idCopy, alcoholLicense };
    const companyToUpdate: Company = {
      corporate: "corporativo1",
      name,
      description,
      webPage,
      active,
      contactName,
      contactEmail,
      phone,
      countryCode,
      whatsapp,
      commission,
      licensee,
      paymentMethods,
      modalities,
      regime,
      business,
      RFC,
      residence,
      representative,
      curp,
      entity,
      clabe,
      billingEmail,
      documents,
    };

    try {
      await updateCompany(companyToUpdate, props.item._id || "");
      history.go(0);
    } catch (error: unknown) {
      if (error instanceof Error) {
        history.push(handleError(error));
      }
    }
  };

  return (
    <div className="ms-content-wrapper">
      <div className="ms-panel-header mb-4 pl-0">
        <h6>{`${json.companies.updateCompany} ${props.item.name}`}</h6>
      </div>
      <form
        className="needs-validation pb-3"
        noValidate
        encType="multipart/form-data"
        onSubmit={handleSubmit}
      >
        <Page1
          item={props.item}
          page={page}
          setPage={setPage}
          isCorporate={props.isCorporate}
        />
        <Page2 item={props.item} page={page} setPage={setPage} />
        <Page3 item={props.item} page={page} setPage={setPage} />
        <Page4 item={props.item} page={page} setPage={setPage} />
        <Page5 item={props.item} page={page} setPage={setPage} />
        <Page6 item={props.item} page={page} setPage={setPage} />
      </form>
    </div>
  );
}
