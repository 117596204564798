import { HttpMethod } from "../../../../core/models/httpMethod";
import { Order } from "../model/order.model";
import { ProductDetail } from "../model/productDetail.model";
import { OrderState } from "../model/orderState.model";
import { APIOrderRoutes } from "../model/apiOrderRoutes";
import validateRequest from "../../../../core/service/validateRequest.middleware";
import { getAccessToken } from "../../../../core/storage/localStorage.db";

export const fetchOrderDetails = async (
  selectedOrder: Order | null,
  state: OrderState
): Promise<ProductDetail[]> => {
  if (!selectedOrder) return [];

  const accessToken = getAccessToken();

  const salesInfoApiUrl = `${APIOrderRoutes.AllOrders}?page=${state.page}&perPage=${state.perPage}&orderId=${selectedOrder.orderId}`;

  const productDetailsApiUrl = `${APIOrderRoutes.AllOrders}?page=${state.page}&perPage=${state.perPage}&orderId=${selectedOrder.orderId}&productDetailId=${selectedOrder.orderId}`;

  try {
    const salesResponse = await validateRequest(
      HttpMethod.Get,
      salesInfoApiUrl,
      accessToken
    );
    if (!salesResponse || !Array.isArray(salesResponse.sales)) {
      throw new Error("Invalid response format");
    }

    const productDetailsResponse = await validateRequest(
      HttpMethod.Get,
      productDetailsApiUrl,
      accessToken
    );
    if (!productDetailsResponse || !Array.isArray(productDetailsResponse)) {
      throw new Error("Invalid response format");
    }

    const productDetails: ProductDetail[] = productDetailsResponse.map(
      (detail: any) => ({
        orderId: selectedOrder.orderId,
        products: detail.products.map((product: any) => ({
          name: product.name,
          quantity: product.quantity,
          unitPrice: product.unitPrice,
          extras: product.extras,
          complements: product.complements,
          subtotal: product.subtotal,
          refund: product.refund,
          total: productTotal(product),
        })),
        refund: 0,
        total: parseFloat(detail.total) || 0,
      })
    );

    return productDetails;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const productTotal = (product: any): number => {
  const validExtras = Array.isArray(product.extras) ? product.extras : [];
  const productTotal =
    product.quantity * parseFloat(product.unitPrice) +
    validExtras.reduce(
      (acc: number, extraItem: any) => acc + extraItem.price,
      0
    );
  const totalAfterRefund = productTotal - parseFloat(product.refund) || 0;
  return totalAfterRefund;
};
