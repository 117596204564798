import React, { useState } from "react";
import { fetchReportData } from "../service/reportFetch.service";
import GeneralReportTable from "../components/model/generalReportTable.model";
import { containsDateRange } from "../../usecase/validator/orders.checkDate.validator";
import { validateEndDate } from "../../usecase/validator/orders.checkEndDate.validator";
import { ErrorForm } from "../../../../core/models/error.form.model";
import { GeneralReportTableProps } from "../model/generalReportTableProps.model";
import ReportBreadCrumbComponent from "../components/ReportBreadCrumbComponent";
import { EnumStatusTranslations } from "../model/enumTranslation.model";
import { useContext } from "react";
import { LanguegeContext } from "../../../../core/hooks/languages/LanguageContext";
import { StatusKeys } from "../../model/statusKeys.model";
import TopNavigation from "../../components/layouts/TopNavigationComponent";
import SideNavigation from "../../components/layouts/SideNavigationComponent";
import { exportPDF } from "../utils/exportPDF";
import { UserInfoContext } from "../../service/userInfoContext";
import UserSelector from "../../components/selector/UserSelectorComponent";
import { AppConfigProvider } from "../../service/appConfigContext";
import { UserInfoProvider } from "../../service/userInfoContext";
import { AppFiltersProvider } from "../../service/appFiltersContext";
import { useHistory } from "react-router-dom";
import handleError from "../../../../core/errors/handleError";

const ReportPage = () => {
  const { json } = useContext(LanguegeContext);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [status, setStatus] = useState(StatusKeys.All);
  const [corporate, setCorporate] = useState("");
  const [company, setCompany] = useState("");
  const [branch, setBranch] = useState("");
  const { statusTranslations } = EnumStatusTranslations();
  const [showReport, setShowReport] = useState(false);
  const userInfoContext = useContext(UserInfoContext);
  const userInfo = userInfoContext ? userInfoContext.userInfo : null;
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [reportData, setReportData] = useState<GeneralReportTableProps | null>(
    null
  );
  const [error, setError] = useState("");
  const history = useHistory();
  const handleFetchReport = async () => {
    setReportData(null);
    setError("");
    if (
      userInfo &&
      startDateResult.error === ErrorForm.None &&
      endDateResult.error === ErrorForm.None
    ) {
      const reportState = {
        startDate,
        endDate,
        status,
        corporate,
        company,
        branch,
      };
      try {
        const reportResponse = await fetchReportData(reportState);
        setReportData({
          ...reportResponse,
          userInfo: userInfo,
          startDate: new Date(startDate),
          endDate: new Date(endDate),
        });
        setShowReport(true);
      } catch (error) {
        history.push(handleError(error));
      }
    } else {
      setShowReport(false);
    }
  };
  const handleCloseReport = () => {
    setShowReport(false);
    setReportData(null);
    setError("");
  };

  const startDateResult = containsDateRange(
    { searchStartDate: startDate },
    /^\d{4}-\d{2}-\d{2}$/
  );
  const endDateResult = validateEndDate(
    { searchStartDate: startDate, searchEndDate: endDate },
    /^\d{4}-\d{2}-\d{2}$/
  );
  const handleChangeStatus = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value as keyof typeof StatusKeys;
    const newStatus = StatusKeys[value];

    setStatus(newStatus);
  };

  const isDateRangeValid =
    startDateResult.error === ErrorForm.None &&
    endDateResult.error === ErrorForm.None;

  return (
    <AppFiltersProvider>
      <AppConfigProvider>
        <UserInfoProvider>
          <div className="ms-body ms-aside-left-open ms-primary-theme ">
            <SideNavigation isSidebarOpen={isSidebarOpen} />
            <main className="body-content">
              <TopNavigation />
              <div className="ms-content-wrapper">
                <div className="row">
                  <div className="col-md-12">
                    <ReportBreadCrumbComponent />

                    <div className="ms-panel">
                      <div className="ms-panel-header header-mini">
                        <h6>{json.sales.reports}</h6>
                      </div>
                      <div className="ms-panel-body">
                        <div className="row mb-4">
                          <div className="col-md-6 mb-3">
                            <label htmlFor="startDate">
                              {json.sales.startDateLabel}
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              value={startDate}
                              onChange={(e) => setStartDate(e.target.value)}
                              disabled={showReport}
                            />
                          </div>
                          <div className="col-md-6 mb-3">
                            <label htmlFor="endDate">
                              {json.sales.dateLabel}
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              value={endDate}
                              onChange={(e) => setEndDate(e.target.value)}
                              disabled={showReport}
                            />
                          </div>
                          <div className="col-md-12 mb-3">
                            <label htmlFor="status">{json.sales.status}</label>
                            <select
                              className="form-control"
                              value={status}
                              onChange={handleChangeStatus}
                              disabled={showReport}
                            >
                              {Object.entries(statusTranslations).map(
                                ([key, value]) => (
                                  <option key={key} value={key}>
                                    {value}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                          <UserSelector
                            isDisabled={showReport}
                            setCorporateValue={setCorporate}
                            setCompanyValue={setCompany}
                            setBranchValue={setBranch}
                          />
                        </div>

                        <button
                          onClick={
                            showReport ? handleCloseReport : handleFetchReport
                          }
                          className={`btn ${
                            showReport ? "btn-secondary" : "btn-primary"
                          } w-100`}
                          disabled={!isDateRangeValid && !showReport}
                        >
                          {showReport
                            ? json.sales.hideReport
                            : json.sales.generateReport}
                        </button>

                        {showReport && reportData && (
                          <div id="reportTable">
                            <GeneralReportTable {...reportData} />
                          </div>
                        )}

                        {showReport && (
                          <div className="report-actions">
                            <button
                              onClick={exportPDF}
                              className="btn btn-secondary"
                            >
                              {json.sales.exportPDF}
                            </button>
                            <button
                              onClick={handleCloseReport}
                              className="btn btn-primary"
                            >
                              {json.sales.closeButton}
                            </button>
                          </div>
                        )}

                        {error && <div className="text-danger">{error}</div>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </UserInfoProvider>
      </AppConfigProvider>
    </AppFiltersProvider>
  );
};

export default ReportPage;
