import { SingleValue } from "react-select";
import { SelectOption } from "../model/selectOption.model";

type UpdateFiltersFunction = (filters: { branch: string }) => void;

export const handleBranchSelector = (
  newValue: SingleValue<SelectOption>,
  updateFilters: UpdateFiltersFunction
) => {
  if (!newValue) return;

  const branchId = newValue.value;
  updateFilters({ branch: branchId });
};
