import { ErrorForm } from "../../../../core/models/error.form.model";
import { FilterResult } from "../../model/filterResult.model";

export function isValidBranch(
  state: any,
  validStringRegex: RegExp
): FilterResult {
  if (state.searchBranch == undefined) {
    return { error: ErrorForm.Branch };
  } else {
    if (
      state.searchBranch !== "" &&
      validStringRegex.test(state.searchBranch)
    ) {
      return { error: ErrorForm.None, url: `&branch=${state.searchBranch}` };
    } else {
      return { error: ErrorForm.Branch };
    }
  }
}
