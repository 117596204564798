export enum InputSubsidiaryNames {
  Name = "name",
  Description = "description",
  Square = "square",
  Categorie = "categorie",
  WebPage = "webPage",
  Active = "active",

  ContactName = "contactName",
  ContactEmail = "contactEmail",
  Phone = "phone",
  CountryCode = "countryCode",
  Whatsapp = "whatsapp",

  Commission = "commission",
  Licensee = "licensee",
  Cash = "cash",
  Paypal = "paypal",
  Stripe = "stripe",

  EatTheLine = "eatTheLine",
  MinTimeEat = "minTimeEat",
  MinConsumptionEat = "minConsumptionEat",
  ServiceEat = "serviceEat",
  Delivery = "delivery",
  MinTimeDelivery = "minTimeDelivery",
  MinConsumptionDelivery = "minConsumptionDelivery",
  ServiceDelivery = "serviceDelivery",
  Zone = "zone",
  Zone2 = "zone2",
  Distinct = "distinct",
  Range = "range",
  Range2 = "range2",
  Stadium = "stadium",
  MinTimeStadium = "minTimeStadium",
  MinConsumptionStadium = "minConsumptionStadium",
  ServiceStadium = "serviceStadium",

  CreditCards = "creditCards",
  ParkingLot = "parkingLot",
  Music = "music",
  PetFriendly = "petFriendly",
  VeganFood = "veganFood",
  ChildishGames = "childishGames",

  Monday = "monday",
  Tuesday = "tuesday",
  Wednesday = "wednesday",
  Thursday = "thursday",
  Friday = "friday",
  Saturday = "saturday",
  Sunday = "sunday",
  start = "_start",
  end = "_end",

  Regime = "regime",
  Business = "business",
  RFC = "rfc",
  Residence = "residence",
  Representative = "representative",
  Curp = "curp",
  Entity = "entity",
  Clabe = "clabe",
  BillingEmail = "billingEmail",
}
