export const regexList = {
  email: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
  name: /^.{3,}$/,
  description: /^.{10,}$/,
  URL: /^https:\/\/.{3,}(\.[a-z]+)+[^\s]*$/,
  phone: /^\+?\d{10}$/,
  number: /^[1-9]\d*$/,
  RFC: /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/,
  CURP: /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
  CLABE: /^\d{18}$/,
  porcent: /^(100|[1-9]\d|\d)$/,
  time: /^(60|[1-5]?\d)$/,
  file: /\.pdf$/,
  user: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
  password: new RegExp(
    "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"
  ),
};
