import React from "react";
import { Order } from "../../model/order.model";

interface TableColumnsProps {
  json: any;
  selectOrderId: (order: Order) => any;
  selectCompanyName: (order: Order) => any;
  selectSubsidiaryName: (order: Order) => any;
  selectUserContactInfo: (order: Order) => any;
  selectDateTime: (order: Order) => any;
  selectServiceType: (order: Order) => any;
  selectTotal: (order: Order) => any;
  selectStatus: (order: Order) => any;
  handleEmailClick: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    row: Order
  ) => void;
  handleDetailsClick: (row: Order) => void;
}

export const getTableColumns = ({
  json,
  selectOrderId,
  selectCompanyName,
  selectSubsidiaryName,
  selectUserContactInfo,
  selectDateTime,
  selectServiceType,
  selectTotal,
  selectStatus,
  handleEmailClick,
  handleDetailsClick,
}: TableColumnsProps) => {
  return [
    { name: json.sales.order, selector: selectOrderId, sortable: true },
    { name: json.sales.company, selector: selectCompanyName, sortable: true },
    { name: json.sales.branch, selector: selectSubsidiaryName, sortable: true },
    {
      name: json.sales.customer,
      selector: selectUserContactInfo,
      cell: (row: Order) => (
        <a
          href="#"
          className="email-link"
          onClick={(e) => handleEmailClick(e, row)}
        >
          {row.userInformation.fullName}
        </a>
      ),
      sortable: true,
    },
    {
      name: json.sales.dateTime,
      selector: selectDateTime,
      sortable: true,
    },
    { name: json.sales.service, selector: selectServiceType, sortable: true },
    { name: json.sales.subtotal, selector: selectTotal, sortable: true },
    {
      name: json.sales.details,
      selector: selectOrderId,
      cell: (row: Order) => (
        <button
          type="button"
          className="ms-btn-icon btn-primary details-button"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => handleDetailsClick(row)}
        >
          <i className="flaticon-information"></i>
        </button>
      ),
      sortable: true,
    },
    { name: json.sales.status, selector: selectStatus, sortable: true },
  ];
};
