import { applyFilters } from "../../usecase/orders.applyFilters.usecase";
import { Dispatch, SetStateAction } from "react";
import { Order } from "../model/order.model";
import { OrderState } from "../model/orderState.model";
import { ErrorForm } from "../../../../core/models/error.form.model";
import validateRequest from "../../../../core/service/validateRequest.middleware";
import { getAccessToken } from "../../../../core/storage/localStorage.db";
import { HttpMethod } from "../../../../core/models/httpMethod";
import { APIOrderRoutes } from "../model/apiOrderRoutes";

export const applyQuickStatusFilter = async (
  state: OrderState,
  setFilteredData: Dispatch<SetStateAction<Order[]>>,
  newStatus: string
) => {
  const newState = { ...state, searchStatus: newStatus };
  const apiUrl = `${APIOrderRoutes.AllOrders}?page=${newState.page}&perPage=${newState.perPage}`;

  const completeUrl = applyFilters(apiUrl, newState);

  if (completeUrl.error === ErrorForm.None && completeUrl.url) {
    const accessToken = getAccessToken();
    try {
      const response = await validateRequest(
        HttpMethod.Get,
        completeUrl.url,
        accessToken
      );

      if (
        response &&
        typeof response === "object" &&
        Array.isArray(response.sales)
      ) {
        setFilteredData(response.sales);
      } else {
        throw new Error("Invalid data format");
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
};
