import { ErrorForm } from "../../../../core/models/error.form.model";
import { FilterResult } from "../../model/filterResult.model";

export function isValidOrder(
  state: any,
  validStringRegex: RegExp
): FilterResult {
  if (state.searchOrderNumber == undefined) {
    return { error: ErrorForm.Order };
  } else {
    if (
      state.searchOrderNumber !== "" &&
      validStringRegex.test(state.searchOrderNumber)
    ) {
      return {
        error: ErrorForm.None,
        url: `&orderId=${state.searchOrderNumber}`,
      };
    } else {
      return { error: ErrorForm.Order };
    }
  }
}
