import { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { LanguegeContext } from "../../../core/hooks/languages/LanguageContext";
import { regexList } from "../../../core/utils/validationRegex";
import LanguageChanger from "../../../core/components/LanguajeChangerComponent";
import getTokens from "../service/getTokens.service";
import { updateTokens } from "../../../core/storage/localStorage.db";
import InputFieldText from "../../../core/components/formFields/InputFieldTextComponent";
import InputFieldPassword from "../../../core/components/formFields/InputFieldPasswordComponent";
import { InputLoginNames } from "../models/inputLoginNames";

function LoginScreen() {
  const { json } = useContext(LanguegeContext);
  const history = useHistory();

  const [show1, setShow] = useState<boolean>(false);
  const [errorAuth, setErrorAuth] = useState<boolean>(false);
  const [validatedFields, setValidatedFields] = useState({
    user: false,
    password: false,
  });
  const [loader, setLoader] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoader(true);
    setErrorAuth(false);

    const elements = e.currentTarget.elements;
    const email = (
      elements.namedItem(InputLoginNames.Email) as HTMLInputElement
    ).value;
    const password = (
      elements.namedItem(InputLoginNames.Password) as HTMLInputElement
    ).value;

    const response = await getTokens(email, password);
    if (response) {
      updateTokens(response.accessToken, response.refreshToken);
      history.push("/");
    } else {
      setErrorAuth(true);
      setLoader(false);
    }
  };

  return (
    <div className="ms-content-wrapper ms-auth">
      <div className="ms-auth-container">
        <div className="ms-auth-col">
          <div className="ms-auth-bg" />
        </div>
        <div className="ms-auth-col">
          <div className="ms-auth-form">
            <LanguageChanger />
            <form
              onSubmit={handleSubmit}
              className="needs-validation"
              id="formulario"
              noValidate
            >
              <h3 className="white-md">{json.login.title}</h3>
              <p className="white-md">{json.login.subtitle}</p>
              <InputFieldText
                label={json.login.inputEmail}
                defaultValue=""
                name={InputLoginNames.Email}
                feedback={json.login.userFeedback}
                placeholder={json.login.placeholderEmail}
                regex={regexList.email}
                setValidity={(validity: boolean) =>
                  setValidatedFields({ ...validatedFields, user: validity })
                }
                required={false}
              />

              <InputFieldPassword
                label={json.login.inputPassword}
                name={InputLoginNames.Password}
                defaultValue=""
                feedback={json.login.passwordFeedback}
                placeholder={json.login.palceholderPassword}
                regex={regexList.password}
                required={false}
                setValidity={(validity: boolean) =>
                  setValidatedFields({ ...validatedFields, password: validity })
                }
              />

              {errorAuth && (
                <label className="text-danger font-italic">
                  {json.login.errors}
                </label>
              )}

              <div className="form-group">
                <label className="ms-checkbox-wrap">
                  <input className="form-check-input" type="checkbox" />
                  <i className="ms-checkbox-check" />
                </label>
                <span className="white-md">
                  {json.login.inputRememberPassword}
                </span>
                <label className="d-block mt-3">
                  <a
                    href="#"
                    className="btn-link"
                    onClick={() => setShow(true)}
                  >
                    {json.login.forgetPasswordInput}
                  </a>
                </label>
              </div>

              <button
                disabled={!(validatedFields.password && validatedFields.user)}
                className="btn btn-primary mt-4 d-block w-100"
                type="submit"
              >
                {loader ? (
                  <div className="spinner spinner-7 my-0">
                    <div className="bounce1" />
                    <div className="bounce2" />
                    <div className="bounce3" />
                  </div>
                ) : (
                  json.login.button
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
      <Modal
        className="modal-min"
        show={show1}
        onHide={() => setShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="text-center">
          <button
            type="button"
            className="close"
            onClick={() => setShow(false)}
          >
            <span aria-hidden="true">×</span>
          </button>
          <i className="flaticon-secure-shield d-block" />
          <h1>{json.login.modalTitle}</h1>
          <p>{json.login.modalSubtitle}</p>
          <form>
            <div className="ms-form-group has-icon">
              <input
                type="email"
                placeholder={json.login.placeholderEmail}
                className="form-control"
                name="forgot-password"
              />
              <i className="material-icons">email</i>
            </div>
            <button type="submit" className="btn btn-primary shadow-none">
              {json.login.modalButton}
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default LoginScreen;
