import React, { useContext } from "react";
import { LanguegeContext } from "../../../../core/hooks/languages/LanguageContext";

interface DateRangeSelectorProps {
  selectedRange: string;
  onChange: (range: string) => void;
}

const DateRangeSelectorComponent: React.FC<DateRangeSelectorProps> = ({
  selectedRange,
  onChange,
}) => {
  const { json } = useContext(LanguegeContext);
  const handleRangeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newRange = e.target.value;
    onChange(newRange);
  };
  const ranges = {
    thisWeek: json.sales.thisWeek,
    lastMonth: json.sales.lastMonth,
    lastThreeMonths: json.sales.threeMonths,
    lastYear: json.sales.lastYear,
  };

  return (
    <div className="form-group" style={{ marginLeft: "auto", flex: 1 }}>
      <label htmlFor="date-range-selector">{json.sales.range}</label>
      <select
        id="date-range-selector"
        className="form-control"
        value={selectedRange}
        onChange={handleRangeChange}
      >
        {Object.entries(ranges).map(([key, value]) => (
          <option key={key} value={key}>
            {value}
          </option>
        ))}
      </select>
    </div>
  );
};

export default DateRangeSelectorComponent;
