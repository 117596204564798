import { useContext, useState } from "react";
import { LanguegeContext } from "../../../../core/hooks/languages/LanguageContext";
import PropsPage from "../../models/propsWizardPage";
import InputFieldSchedule from "../InputFieldScheduleComponent";
import InputFieldSelect from "../../../../core/components/formFields/InputFieldSelectComponent";
import InputFieldDoubleSchedule from "../InputFieldDoubleScheduleComponent";
import { ScheduleTurns } from "../../models/scheduleTurn";

export default function ScheduleComponent(props: PropsPage) {
  const { json } = useContext(LanguegeContext);
  const [turn, setTurn] = useState(ScheduleTurns.Single);

  const daysOfWeek: string[] = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];
  return (
    <fieldset className="col-sm-12 pb-3" hidden={props.page !== 7}>
      <legend className="bold mb-3">{json.subsidiaries.schedule}</legend>
      {
        <div className="form-row">
          <InputFieldSelect
            items={[json.subsidiaries.oneShift, json.subsidiaries.twoShifts]}
            name="turnos"
            label={json.subsidiaries.shiftNumber}
            onChange={() => {
              if (turn == ScheduleTurns.Single) setTurn(ScheduleTurns.Double);
              else setTurn(ScheduleTurns.Single);
            }}
          />
          {daysOfWeek.map((day, index) =>
            turn == ScheduleTurns.Single ? (
              <InputFieldSchedule
                day={day}
                key={index}
                checked={
                  props.item?.schedule?.some((value) => value[day]) || false
                }
                open={
                  props.item?.schedule?.find((sch) => sch[day])?.[day]
                    .openTime || ""
                }
                close={
                  props.item?.schedule?.find((sch) => sch[day])?.[day]
                    .closeTime || ""
                }
              />
            ) : (
              <InputFieldDoubleSchedule
                day={day}
                key={index}
                checked={
                  props.item?.schedule?.some((value) => value[day]) || false
                }
                open={
                  props.item?.schedule?.find((sch) => sch[day])?.[day]
                    .openTime || ""
                }
                close={
                  props.item?.schedule?.find((sch) => sch[day])?.[day]
                    .closeTime || ""
                }
              />
            )
          )}
        </div>
      }
      <input
        type="button"
        defaultValue={json.companies.buttonBack}
        className="btn btn-primary mx-2"
        onClick={() => props.setPage(props.page - 1)}
      />

      <input
        type="button"
        value={json.companies.buttonNext}
        className="btn btn-primary mx-2"
        onClick={() => props.setPage(props.page + 1)}
      />
    </fieldset>
  );
}
