import { useContext, useEffect, useState } from "react";
import { LanguegeContext } from "../../../core/hooks/languages/LanguageContext";
import Subsidiary from "../components/SubsidiaryComponent";
import SubsidiaryInterface from "../models/subsidiary.model";
import handleError from "../../../core/errors/handleError";
import { useHistory } from "react-router-dom";
import getSubsidiaries from "../service/getSubsidiaries.service";
import Preloader from "../../../core/components/Preloader";
import useConfig from "../../../core/hooks/config/useConfig";
import { ModulesNames } from "../../dashboard/model/modulesNames.model";

export default function ListSubsidiaries() {
  const { json } = useContext(LanguegeContext);
  const [filter, setFilter] = useState<string>("");
  const history = useHistory();
  const config = useConfig();

  const [subsidiaries, setSubsidiaries] = useState<SubsidiaryInterface[]>([]);
  const [filtredSubsidiaries, setFiltredSubsidiaries] = useState<
    SubsidiaryInterface[]
  >([]);

  useEffect(() => {
    async function fetchSubsidiaries() {
      try {
        const fetchedCompanies = await getSubsidiaries();
        setSubsidiaries(fetchedCompanies);
        setFiltredSubsidiaries(fetchedCompanies);
      } catch (error: unknown) {
        if (error instanceof Error) history.push(handleError(error));
      }
    }
    fetchSubsidiaries();
  }, []);

  useEffect(() => {
    const res = subsidiaries?.filter((subsidiarie) =>
      subsidiarie.name.toLowerCase().match(filter.toLowerCase())
    );
    setFiltredSubsidiaries(res);
  }, [filter]);

  return (
    <>
      <div className="ms-panel-body">
        {subsidiaries.length == 0 ? (
          <Preloader />
        ) : (
          <>
            <form className="needs-validation">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                  placeholder={json.companies.placeholderSearch}
                />
              </div>
            </form>
            {filtredSubsidiaries.length > 0 ? (
              <div className="row">
                {filtredSubsidiaries?.map((item) => {
                  return (
                    <Subsidiary
                      key={item._id}
                      item={item}
                      canErase={config.includes(ModulesNames.Companies)}
                    />
                  );
                })}
              </div>
            ) : (
              <p>{json.companies.noElements}</p>
            )}
          </>
        )}
      </div>
    </>
  );
}
