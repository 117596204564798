import { ErrorForm } from "../../../../core/models/error.form.model";
import { FilterResult } from "../../model/filterResult.model";

export function containsDateRange(
  state: any,
  validDateRegex: RegExp
): FilterResult {
  if (state.searchStartDate !== undefined && state.searchStartDate !== "") {
    if (!validDateRegex.test(state.searchStartDate)) {
      return { error: ErrorForm.Date };
    }

    const startDateObj = new Date(state.searchStartDate);
    if (isNaN(startDateObj.getTime())) {
      return { error: ErrorForm.Date };
    }

    const todayDate = new Date();
    todayDate.setHours(0, 0, 0, 0);

    if (startDateObj.getTime() > todayDate.getTime()) {
      return { error: ErrorForm.Date };
    }

    const formattedStartDate = startDateObj.toISOString().split("T")[0];

    return {
      error: ErrorForm.None,
      url: `&startDate=${formattedStartDate}`,
    };
  }

  return { error: ErrorForm.Date };
}
