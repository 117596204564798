import { useContext } from "react";
import { LanguegeContext } from "../hooks/languages/LanguageContext";

export default function LanguageChangerComponent() {
  const { json, setLanguage, language } = useContext(LanguegeContext);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setLanguage(e.target.value);
  };

  return (
    <div className="col-md-6 languageChanger">
      <label>{json.login.buttonChangeLenguage}</label>
      <div className="input-group">
        <select
          className="form-control"
          value={language}
          onChange={handleChange}
        >
          <option value="es_MX">Español</option>
          <option value="en_US">Inglés</option>
        </select>
      </div>
    </div>
  );
}
