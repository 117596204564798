export default function Preloader() {
  return (
    <div className="w-100">
      <div className="spinner spinner-9">
        <div className="ms-cube ms-cube1" />
        <div className="ms-cube ms-cube2" />
        <div className="ms-cube ms-cube3" />
        <div className="ms-cube ms-cube4" />
        <div className="ms-cube ms-cube5" />
        <div className="ms-cube ms-cube6" />
        <div className="ms-cube ms-cube7" />
        <div className="ms-cube ms-cube8" />
        <div className="ms-cube ms-cube9" />
      </div>
    </div>
  );
}
