import { useContext, useEffect, useState } from "react";
import CompanyComponent from "../components/CompanyComponent";
import Company from "../model/company.model";
import { LanguegeContext } from "../../../core/hooks/languages/LanguageContext";
import { useHistory } from "react-router-dom";
import getCompanies from "../service/getCompanies.service";
import handleError from "../../../core/errors/handleError";
import Preloader from "../../../core/components/Preloader";
import useConfig from "../../../core/hooks/config/useConfig";
import { ModulesNames } from "../../dashboard/model/modulesNames.model";

export default function ListCompaniesScreen() {
  const history = useHistory();
  const { json } = useContext(LanguegeContext);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [filtredComanies, setFiltredCompanies] = useState<Company[]>([]);
  const config = useConfig();

  const [filter, setFilter] = useState<string>("");

  useEffect(() => {
    async function fetchCompanies() {
      try {
        const fetchedCompanies = await getCompanies();
        setCompanies(fetchedCompanies);
        setFiltredCompanies(fetchedCompanies);
      } catch (error: unknown) {
        if (error instanceof Error) history.push(handleError(error));
      }
    }
    fetchCompanies();
  }, []);

  useEffect(() => {
    const res = companies?.filter((companie) =>
      companie.name.toLowerCase().match(filter.toLowerCase())
    );
    setFiltredCompanies(res);
  }, [filter]);

  return (
    <>
      <div className="ms-panel-body">
        {companies.length == 0 ? (
          <Preloader />
        ) : (
          <>
            <form className="needs-validation">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                  placeholder={json.companies.placeholderSearch}
                />
              </div>
            </form>
            {filtredComanies.length > 0 ? (
              <div className="row">
                {filtredComanies?.map((item, key) => {
                  return (
                    <CompanyComponent
                      key={key}
                      item={item}
                      canErase={config.includes(ModulesNames.Corporates)}
                    />
                  );
                })}
              </div>
            ) : (
              <p>{json.companies.noElements}</p>
            )}
          </>
        )}
      </div>
    </>
  );
}
