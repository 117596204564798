import { HttpMethod } from "../../../core/models/httpMethod";
import validateRequest from "../../../core/service/validateRequest.middleware";
import { getAccessToken } from "../../../core/storage/localStorage.db";
import { APISubsidiaryRoutes } from "../models/apiSubsidiaryRoutes";
import Subsidiary from "../models/subsidiary.model";

export default async function updateSubsidiary(
  subsidiary: Subsidiary,
  id: string
) {
  await validateRequest(
    HttpMethod.Put,
    `${APISubsidiaryRoutes.Update}${id}`,
    getAccessToken(),
    subsidiary
  );
}
