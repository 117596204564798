import { HttpMethod } from "../../../core/models/httpMethod";
import validateRequest from "../../../core/service/validateRequest.middleware";
import { getAccessToken } from "../../../core/storage/localStorage.db";
import { APICorporateRoutes } from "../model/apiCorporateRoutes";
import Corporate from "../model/corporate.model";

export default async function updateCorporate(
  corporate: Corporate,
  id: string
) {
  await validateRequest(
    HttpMethod.Put,
    `${APICorporateRoutes.Update}${id}`,
    getAccessToken(),
    corporate
  );
}
