export default class ConnectionError extends Error {
  constructor() {
    super("Invalid Connection");

    this.name = "ConnectionError";

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ConnectionError);
    }
  }
}
