export enum InputCorporateNames {
  Name = "name",
  Description = "description",
  WebPage = "webPage",
  Active = "active",
  Generic = "generic",

  ContactName = "contactName",
  ContactEmail = "contactEmail",
  Phone = "phone",
  CountryCode = "countryCode",
  Whatsapp = "whatsapp",

  Commission = "commission",
  Licensee = "licensee",
  Cash = "cash",
  Paypal = "paypal",
  Stripe = "stripe",

  EatTheLine = "eatTheLine",
  MinTimeEat = "minTimeEat",
  MinConsumptionEat = "minConsumptionEat",
  ServiceEat = "serviceEat",
  Delivery = "delivery",
  MinTimeDelivery = "minTimeDelivery",
  MinConsumptionDelivery = "minConsumptionDelivery",
  ServiceDelivery = "serviceDelivery",
  Zone = "zone",
  Zone2 = "zone2",
  Distinct = "distinct",
  Range = "range",
  Range2 = "range2",
  Stadium = "stadium",
  MinTimeStadium = "minTimeStadium",
  MinConsumptionStadium = "minConsumptionStadium",
  ServiceStadium = "serviceStadium",

  Regime = "regime",
  Business = "business",
  RFC = "rfc",
  Residence = "residence",
  Representative = "representative",
  Curp = "curp",
  Entity = "entity",
  Clabe = "clabe",

  BillingEmail = "billingEmail",
  ProofTaxSituation = "proofTaxSituation",
  BankCover = "bankCover",
  IdCopy = "idCopy",
  AlcoholLicense = "alcoholLicense",
}
