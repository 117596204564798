import React, { useContext, useEffect } from "react";
import { Bar as BarChart } from "react-chartjs-2";
import { BarChartData } from "../../model/barChartData.model";
import { BarChartOptions } from "../../model/barChartOptions.model";
import { AppFiltersContext } from "../../../service/appFiltersContext";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import handleError from "../../../../../core/errors/handleError";
import { fetchTotalOrdersChartData } from "../../service/totalOrdersFetch.service";

interface TotalOrderschartComponentProps {
  setExecuteSearch: (searchFunction: () => void) => void;
}

const TotalOrdersChartComponent: React.FC<TotalOrderschartComponentProps> = ({
  setExecuteSearch,
}) => {
  const { filters, updateFilters } = useContext(AppFiltersContext);
  const [chartData, setChartData] = useState<BarChartData>({
    labels: [],
    datasets: [],
  });
  const history = useHistory();
  const handleSearch = async () => {
    try {
      const newData = await fetchTotalOrdersChartData(filters);
      setChartData(newData);
    } catch (error) {
      history.push(handleError(error));
    }
  };

  useEffect(() => {
    handleSearch();
  }, []);
  useEffect(() => {
    setExecuteSearch(() => handleSearch);
  }, [setExecuteSearch, filters]);

  return (
    <>
      <BarChart data={chartData} options={BarChartOptions} />
    </>
  );
};

export default TotalOrdersChartComponent;
