import React from "react";
import { UserInfo } from "../model/userInfo.model";
import { Company } from "../model/company.model";
import { SelectOption } from "../model/selectOption.model";
import { ConfigNames } from "../../reports/model/configNames.model";

type UpdateFiltersFunction = (filters: {
  company: string;
  branch: string;
}) => void;
type SetOptionsFunction = React.Dispatch<React.SetStateAction<SelectOption[]>>;
type SetStringFunction = React.Dispatch<React.SetStateAction<string>>;

const handleCompanyChange = (
  event: React.ChangeEvent<HTMLSelectElement>,
  updateFilters: UpdateFiltersFunction,
  setBranchOptions: SetOptionsFunction,
  setBranchValue: SetStringFunction,
  userInfo: UserInfo | null
) => {
  const companyId = event.target.value;
  updateFilters({ company: companyId, branch: "" });
  setBranchOptions([]);
  setBranchValue("");

  let companiesArray: Company[] = [];

  if (userInfo?.type === ConfigNames.Admin) {
    companiesArray =
      userInfo.corporates?.flatMap((corporate) => corporate.companies ?? []) ??
      [];
  } else if (userInfo?.companies) {
    companiesArray = userInfo.companies;
  }

  const selectedCompany = companiesArray.find(
    (company) => company.id === companyId
  );
  if (selectedCompany && selectedCompany.subsidiaries) {
    const newBranchOptions = selectedCompany.subsidiaries.map((subsidiary) => ({
      label: subsidiary.name,
      value: subsidiary.id,
    }));
    setBranchOptions(newBranchOptions);
  }
};

export default handleCompanyChange;
