import { FunctionComponent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { APIAuthRoutes } from "../../modules/auth/models/apiAuthRoutes.model";
import Preloader from "../components/Preloader";
import { HandleErrorRoutes } from "../models/handleErrorRoutes.model";
import { HttpMethod } from "../models/httpMethod";
import {
  getAccessToken,
  getRefreshToken,
  updateTokens,
} from "../storage/localStorage.db";
import validateRequest from "./validateRequest.middleware";
interface AuthGuardProps {
  children: JSX.Element | JSX.Element[];
}
const AuthGuard: FunctionComponent<AuthGuardProps> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  useEffect(() => {
    const validateToken = async () => {
      const accessToken = getAccessToken();
      if (!accessToken) {
        setIsLoading(false);
        history.push(HandleErrorRoutes.Login);
        return;
      }
      try {
        await validateRequest(
          HttpMethod.Post,
          APIAuthRoutes.Refresh,
          accessToken,
          {
            refreshToken: getRefreshToken(),
          }
        );
      } catch (error) {
        updateTokens("", "");
        history.push(HandleErrorRoutes.Login);
      } finally {
        setIsLoading(false);
      }
    };
    validateToken();
  }, []);
  if (isLoading) {
    return <Preloader />;
  } else return <>{children}</>;
};
export default AuthGuard;
