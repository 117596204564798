import { useContext, useState, useEffect } from "react";
import Modal from "react-modal";
import { Order } from "../model/order.model";
import { ProductDetail } from "../model/productDetail.model";
import { LanguegeContext } from "../../../../core/hooks/languages/LanguageContext";
import { useDetailsCaller } from "../service/detailsCaller.service";
import { OrderState } from "../model/orderState.model";

interface DetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedOrder: Order | null;
  state: OrderState;
}

Modal.setAppElement("#root");

function DetailsModal({
  isOpen,
  onClose,
  selectedOrder,
  state,
}: DetailsModalProps) {
  const { json } = useContext(LanguegeContext);
  const [order, setOrder] = useState<Order | null>(selectedOrder);

  useEffect(() => {
    setOrder(selectedOrder);
  }, [selectedOrder]);

  const { products: productsFromApi, error } = useDetailsCaller(order, state);

  const filteredProducts: ProductDetail[] = Array.isArray(productsFromApi)
    ? productsFromApi
    : [];

  const updateOrderStatus = (newStatus: string) => {
    if (order) {
      setOrder({ ...order, status: newStatus });
    }
  };

  if (!order) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel={`Order Number: ${order.orderId} - Date and Time: ${order.dateTime}`}
      style={{
        overlay: {
          zIndex: 9999,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        content: {
          maxWidth: "800px",
          maxHeight: "80%",
          margin: "auto",
          padding: "20px",
          borderRadius: "8px",
          backgroundColor: "#fff",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      <div className="modal-header">
        <h4 className="modal-title">
          {json.sales.order} {order.orderId}
        </h4>
        <button className="close" onClick={onClose}>
          <span>&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <h5 className="text-center mt-4">{json.sales.orderProductsTitle}</h5>
        <div className="table-responsive">
          <table
            className="table table-hover thead-primary mx-auto"
            style={{ fontSize: "13px" }}
          >
            <thead>
              <tr>
                <th className="text-center">{json.sales.product}</th>
                <th className="text-center">{json.sales.quantity}</th>
                <th className="text-center">{json.sales.price}</th>
                <th className="text-center">{json.sales.extras}</th>
                <th className="text-center">{json.sales.complements}</th>
                <th className="text-center">{json.sales.refund}</th>
                <th className="text-center">{json.sales.subtotal}</th>
              </tr>
            </thead>
            <tbody>
              {filteredProducts.flatMap((productDetail, productDetailIndex) =>
                productDetail.products.map((product, productIndex) => (
                  <tr key={productDetailIndex + "-" + productIndex}>
                    <td className="text-center">{product.name || ""}</td>
                    <td className="text-center">{product.quantity || ""}</td>
                    <td className="text-center">{product.unitPrice || ""}</td>
                    <td className="text-center">
                      <ul>
                        {product.extras?.map((extra, extraIndex) => (
                          <li key={extraIndex}>
                            {extra.name} $ {extra.price || ""}
                          </li>
                        ))}
                      </ul>
                    </td>
                    <td className="text-center">
                      <ul>
                        {product.complements?.map(
                          (complement, complementIndex) => (
                            <li key={complementIndex}>
                              {complement.name || ""}
                            </li>
                          )
                        )}
                      </ul>
                    </td>
                    <td className="text-center">{productDetail.refund || 0}</td>
                    <td className="text-center">{productDetail.total || ""}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <h5 className="text-center mt-4">Pago</h5>
        <table
          className="table table-hover thead-primary mx-auto"
          style={{ fontSize: "13px" }}
        >
          <thead>
            <tr>
              <th className="text-center">{json.sales.paymentMethod}</th>
              <th className="text-center">{json.sales.cardBrand}</th>
              <th className="text-center">{json.sales.subtotal}</th>
              <th className="text-center">{json.sales.service}</th>
              <th className="text-center">{json.sales.status}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-center">
                {order.paymentInformation.paymentType}
              </td>
              <td className="text-center">
                {order.paymentInformation.cardBrand.stripe}
              </td>
              <td className="text-center">{order.total}</td>
              <td className="text-center">{order.serviceType}</td>
              <td className="text-center">{order.status}</td>
            </tr>
          </tbody>
        </table>
        <h5 className="text-center mt-4">{json.sales.companyInfoTitle}</h5>
        <table
          className="table table-hover thead-primary mx-auto"
          style={{ fontSize: "13px" }}
        >
          <thead>
            <tr>
              <th className="text-center">{json.sales.company}</th>
              <th className="text-center">{json.sales.branch}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-center">
                {order.companyInformation.companyName}
              </td>
              <td className="text-center">
                {order.companyInformation.subsidaryName}
              </td>
            </tr>
          </tbody>
        </table>
        <h5 className="text-center mt-4">{json.sales.customer}</h5>
        <table
          className="table table-hover thead-primary mx-auto"
          style={{ fontSize: "13px" }}
        >
          <thead>
            <tr>
              <th className="text-center">{json.sales.customer}</th>
              <th className="text-center">{json.sales.email}</th>
              <th className="text-center">{json.sales.phone}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-center">
                {order.userInformation.fullName || ""}
              </td>
              <td className="text-center">
                {order.userInformation.email || ""}
              </td>
              <td className="text-center">
                {order.userInformation.phone || ""}
              </td>
            </tr>
          </tbody>
        </table>

        <div className="form-group mb-4">
          <h5 className="text-center mt-4">{json.sales.status}</h5>
          <select
            id="searchStatus"
            className="form-control"
            value={order?.status}
            onChange={(e) => updateOrderStatus(e.target.value)}
          >
            <option value="Confirmado">{json.sales.confirm}</option>
            <option value="Enviado">{json.sales.sending}</option>
            <option value="Entregado">{json.sales.delivered}</option>
            <option value="Cancelado">{json.sales.cancelled}</option>
            <option value="Reembolso">{json.sales.refund}</option>
            <option value="Contracargo">{json.sales.chargeback}</option>
          </select>
        </div>
      </div>
      <div className="modal-footer">
        <button onClick={onClose} className="btn btn-primary">
          {json.sales.closeButton}
        </button>
      </div>
    </Modal>
  );
}

export default DetailsModal;
