import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import DetailsModal from "./DetailsModalScreen";
import { Order } from "../model/order.model";
import { OrderState } from "../model/orderState.model";
import { LanguegeContext } from "../../../../core/hooks/languages/LanguageContext";
import validateRequest from "../../../../core/service/validateRequest.middleware";
import { HttpMethod } from "../../../../core/models/httpMethod";
import { getAccessToken } from "../../../../core/storage/localStorage.db";
import { APIOrderRoutes } from "../model/apiOrderRoutes";

interface OrderHistoryModalProps {
  isOpen: boolean;
  onClose: () => void;
  orderHistory: Order[];
  state: OrderState;
  selectedUserID: string | null;
}

Modal.setAppElement("#root");

function OrderHistoryModal({
  isOpen,
  onClose,
  orderHistory,
  state,
  selectedUserID,
}: OrderHistoryModalProps) {
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [filteredData, setFilteredData] = useState<Order[]>([]);
  const { json } = React.useContext(LanguegeContext);

  useEffect(() => {
    const fetchFilteredOrders = async () => {
      if (selectedUserID) {
        const apiUrl = `${APIOrderRoutes.ResumeCharts}?page=${state.page}&perPage=${state.perPage}&userId=${selectedUserID}`;
        const accessToken = getAccessToken();

        const response = await validateRequest(
          HttpMethod.Get,
          apiUrl,
          accessToken
        );
        if (response && response.sales && Array.isArray(response.sales)) {
          setFilteredData(response.sales);
          const userOrder = response.sales.find(
            (order: Order) => order.userInformation.userId === selectedUserID
          );
          setSelectedOrder(userOrder || null);
        } else {
          throw new Error("Empty API Response");
        }
      }
    };

    if (isOpen) {
      fetchFilteredOrders();
    }
    return () => {
      setSelectedOrder(null);
    };
  }, [isOpen, selectedUserID, state.page, state.perPage]);

  const openDetailsModal = (order: Order) => {
    setSelectedOrder(order);
    setIsDetailsModalOpen(true);
  };
  const handleClose = () => {
    setSelectedOrder(null);
    setIsDetailsModalOpen(false);
    onClose();
  };
  if (!isOpen) {
    return null;
  }
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      contentLabel="Sale Details"
      style={{
        overlay: {
          zIndex: 9999,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        content: {
          maxWidth: "800px",
          maxHeight: "80%",
          margin: "auto",
          padding: "20px",
          borderRadius: "8px",
          backgroundColor: "#fff",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      {selectedOrder && (
        <div>
          <div className="modal-header">
            <h4 className="modal-title">{json.sales.clientInfo}</h4>
            <button className="close" onClick={onClose}>
              <span>&times;</span>
            </button>
          </div>
          <table
            className="table table-hover thead-primary mx-auto"
            style={{ fontSize: "13px" }}
          >
            <thead>
              <tr>
                <th>{json.sales.customer}</th>
                <th>{json.sales.email}</th>
                <th>{json.sales.phone}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{selectedOrder.userInformation.fullName}</td>
                <td>{selectedOrder.userInformation.email}</td>
                <td>{selectedOrder.userInformation.phone}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      <div className="modal-header">
        <h4 className="modal-title">{json.sales.modalTitle}</h4>
      </div>
      <div className="modal-body">
        <table
          className="table table-hover thead-primary mx-auto"
          style={{ fontSize: "13px" }}
        >
          <thead>
            <tr>
              <th>{json.sales.order}</th>
              <th>{json.sales.subtotal}</th>
              <th>{json.sales.dateTime}</th>
              <th>{json.sales.details}</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((order, index) => (
              <tr key={index}>
                <td>{order.orderId}</td>
                <td>{order.total}</td>
                <td>{order.dateTime}</td>
                <td>
                  <button
                    className="ms-btn-icon btn-primary details-button"
                    onClick={() => openDetailsModal(order)}
                  >
                    <i className="flaticon-information" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="modal-footer">
        <button onClick={onClose} className="btn btn-primary">
          {json.sales.closeButton}
        </button>
      </div>
      {isDetailsModalOpen && (
        <DetailsModal
          isOpen={isDetailsModalOpen}
          onClose={() => setIsDetailsModalOpen(false)}
          selectedOrder={selectedOrder}
          state={state}
        />
      )}
    </Modal>
  );
}

export default OrderHistoryModal;
