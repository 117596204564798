import { HttpMethod } from "../../../core/models/httpMethod";
import validateRequest from "../../../core/service/validateRequest.middleware";
import { getAccessToken } from "../../../core/storage/localStorage.db";
import { APICompanyRoutes } from "../model/apiCompanyRoutes";
import Company from "../model/company.model";

export default async function updateCompany(company: Company, id: string) {
  await validateRequest(
    HttpMethod.Put,
    `${APICompanyRoutes.Update}${id}`,
    getAccessToken(),
    company
  );
}
