import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Preloader from "../../../core/components/Preloader";
import handleError from "../../../core/errors/handleError";
import { LanguegeContext } from "../../../core/hooks/languages/LanguageContext";
import CompanyComponent from "../components/CorporateComponent";
import Corporate from "../model/corporate.model";
import getCorporate from "../service/getCorporates.service";

export default function ListCorporatesScreen() {
  const history = useHistory();
  const { json } = useContext(LanguegeContext);
  const [corporates, setCorporates] = useState<Corporate[]>([]);
  const [filtredCorporates, setFiltredCorporates] = useState<Corporate[]>([]);
  const [filter, setFilter] = useState<string>("");

  const [page, setPage] = useState<number>(1);
  const [groupBy, setGroupBy] = useState<number>(20);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalCompanies, setTotalCompanies] = useState<number>(0);

  useEffect(() => {
    async function fetchCorporates() {
      try {
        const response = await getCorporate(
          page,
          groupBy,
          totalPages,
          totalCompanies
        );
        setCorporates(response.data);
        setFiltredCorporates(response.data);
        setTotalPages(response.totalPages);
        setGroupBy(response.groupBy);
        setTotalCompanies(response.totalCompanies);
      } catch (error: unknown) {
        if (error instanceof Error) history.push(handleError(error));
      }
    }
    fetchCorporates();
  }, [page, groupBy]);

  useEffect(() => {
    const res = corporates?.filter((companie) =>
      companie.name.toLowerCase().match(filter.toLowerCase())
    );
    setFiltredCorporates(res);
  }, [filter]);

  const handlePageChange = (newPage: number) => {
    if (newPage > 0) {
      setPage(newPage);
    }
  };

  return (
    <>
      <div className="ms-panel-body">
        {(corporates || []).length === 0 ? (
          <Preloader />
        ) : (
          <>
            <form className="needs-validation">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                  placeholder={json.companies.placeholderSearch}
                />
              </div>
            </form>

            {filtredCorporates.length > 0 ? (
              <div className="row">
                {filtredCorporates?.map((item, key) => {
                  return <CompanyComponent key={key} item={item} />;
                })}
              </div>
            ) : (
              <p>{json.companies.noElements}</p>
            )}
          </>
        )
        }
        <div className="pagination-controls">
          <button
            onClick={() => handlePageChange(page - 1)}
            disabled={page === 1}
          >
            {json.corporates.previousPage}
          </button>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index + 1}
              onClick={() => handlePageChange(index + 1)}
              disabled={page === index + 1}
              className={page === index + 1 ? "active" : ""}
            >
              {index + 1}
            </button>
          ))}
          <button
            onClick={() => handlePageChange(page + 1)}
            disabled={page === totalPages}
          >
            {json.corporates.nextPage}
          </button>
        </div>
      </div>
    </>
  );
}
