import { useContext, useState } from "react";
import { LanguegeContext } from "../../../../core/hooks/languages/LanguageContext";
import PropsPage from "../../models/propsWizardPage";
import Carousel from "react-bootstrap/Carousel";
import img1 from "../../../../assets/img/costic/food-1.jpg";
import img2 from "../../../../assets/img/costic/food-5.jpg";
import img3 from "../../../../assets/img/costic/food-3.jpg";

export default function CarouselComponent(props: PropsPage) {
  const { json } = useContext(LanguegeContext);
  const [images, setImages] = useState([img1, img2, img3]);

  function handleImage(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    if (e.target.files) {
      const newImages = [...images, URL.createObjectURL(e.target.files[0])];
      setImages(newImages);
    }
  }

  return (
    <fieldset className="col-sm-12 pb-3" hidden={props.page !== 8}>
      <legend className="bold mb-3">{json.subsidiaries.carousel}</legend>
      {
        <Carousel>
          {images.map((image, i) => (
            <Carousel.Item key={i}>
              <img
                className="mx-auto d-block img-fluid mw-100"
                style={{ height: "250px" }}
                src={image}
              />
            </Carousel.Item>
          ))}
        </Carousel>
      }
      <div className="custom-file mb-4 mt-3">
        <input
          className="custom-file-input zindex-tooltip"
          type="file"
          id="formFile"
          accept="image/png, image/gif, image/jpeg"
          onChange={handleImage}
        />
        <input
          type="button"
          className="custom-file-label btn-primary"
          value={json.subsidiaries.newImage}
        />
      </div>
      <br />

      <input
        type="button"
        defaultValue={json.companies.buttonBack}
        className="btn btn-primary mx-2"
        onClick={() => props.setPage(props.page - 1)}
      />
      <input
        type="button"
        value={json.companies.buttonNext}
        className="btn btn-primary mx-2"
        onClick={() => props.setPage(props.page + 1)}
      />
    </fieldset>
  );
}
