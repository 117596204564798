import { useContext, useEffect, useState } from "react";
import { LanguegeContext } from "../../../../core/hooks/languages/LanguageContext";
import { regexList } from "../../../../core/utils/validationRegex";
import PropsPage from "../../models/propsWizardPage";
import InputFieldText from "../../../../core/components/formFields/InputFieldTextComponent";
import { InputSubsidiaryNames } from "../../models/inputSubsidiaryNames";

export default function CollectionsAndCommissionsComponent(props: PropsPage) {
  const { json } = useContext(LanguegeContext);

  const [cashChecked, setCashChecked] = useState(false);
  const [paypalChecked, setPaypalChecked] = useState(false);
  const [stripeChecked, setStripeChecked] = useState(false);

  const [validityPage, setValidityPage] = useState({
    commission: false,
  });

  useEffect(() => {
    setValidityPage({
      commission: !!props.company || !!props.item,
    });
  }, [props.company]);

  useEffect(() => {
    setCashChecked(
      props.company?.paymentMethods.includes("Cash") ||
        props.item?.paymentMethods.includes("Cash") ||
        false
    );
    setPaypalChecked(
      props.company?.paymentMethods.includes("PayPal") ||
        props.item?.paymentMethods.includes("PayPal") ||
        false
    );
    setStripeChecked(
      props.company?.paymentMethods.includes("Stripe") ||
        props.item?.paymentMethods.includes("Stripe") ||
        false
    );
  }, [props.company]);

  return (
    <fieldset className="col-sm-12 pb-3" hidden={props.page !== 3}>
      <legend className="bold mb-4">{json.companies.commissions}</legend>
      <p className="text-danger">{json.companies.requiredFields}</p>
      <div className="form-row">
        <InputFieldText
          label={json.companies.commission}
          name={InputSubsidiaryNames.Commission}
          defaultValue={
            props.company?.commission || props.item?.commission || ""
          }
          placeholder={json.companies.commissionPlaceholder}
          regex={regexList.porcent}
          feedback={json.companies.commissionFeedback}
          setValidity={(validity: boolean) =>
            setValidityPage({
              commission: validity,
            })
          }
          required
        />
        <InputFieldText
          label={json.companies.licensee}
          name={InputSubsidiaryNames.Licensee}
          defaultValue={props.company?.licensee || props.item?.licensee || ""}
          placeholder={json.companies.licenseePlaceholder}
          regex={regexList.name}
          feedback={json.companies.licenseeFeedback}
          setValidity={() => {
            return;
          }}
          required
        />

        <div className="col-md-12 mb-2">
          <label className="bold lead mb-4">
            {json.companies.paymentMethods}
          </label>
        </div>
        <div className="col-md-12 mb-2">
          <div className="input-group new">
            <p>{json.companies.cash}</p>
            <div>
              <label className="ms-switch">
                <input
                  type="checkbox"
                  name={InputSubsidiaryNames.Cash}
                  checked={cashChecked}
                  onChange={() => setCashChecked(!cashChecked)}
                />
                <span className="ms-switch-slider round" />
              </label>
            </div>
          </div>
          <div className="input-group new">
            <p>{json.companies.paypal}</p>
            <div>
              <label className="ms-switch">
                <input
                  type="checkbox"
                  name={InputSubsidiaryNames.Paypal}
                  checked={paypalChecked}
                  onChange={() => setPaypalChecked(!paypalChecked)}
                />
                <span className="ms-switch-slider round" />
              </label>
            </div>
          </div>
          <div className="input-group new">
            <p>{json.companies.stripe}</p>
            <div>
              <label className="ms-switch">
                <input
                  type="checkbox"
                  name={InputSubsidiaryNames.Stripe}
                  checked={stripeChecked}
                  onChange={() => setStripeChecked(!setStripeChecked)}
                />
                <span className="ms-switch-slider round" />
              </label>
            </div>
          </div>
        </div>
      </div>
      <input
        type="button"
        value={json.companies.buttonBack}
        className="btn btn-primary mx-2"
        onClick={() => props.setPage(props.page - 1)}
      />
      <input
        type="button"
        value={json.companies.buttonNext}
        className="btn btn-primary mx-2"
        onClick={() => props.setPage(props.page + 1)}
        disabled={!validityPage.commission}
      />
    </fieldset>
  );
}
