import { useContext, useState } from "react";
import { LanguegeContext } from "../../../../core/hooks/languages/LanguageContext";
import { regexList } from "../../../../core/utils/validationRegex";
import PropsPage from "../../model/propsWizardPage.model";
import InputFieldText from "../../../../core/components/formFields/InputFieldTextComponent";
import { InputCompanyNames } from "../../model/inputCompanyNames";

export default function CollectionsAndCommissionsComponent(props: PropsPage) {
  const { json } = useContext(LanguegeContext);

  const [validityPage3, setValidityPage3] = useState({
    commission: !!props.item,
  });

  return (
    <fieldset className="col-sm-12 pb-3" hidden={props.page !== 3}>
      <legend className="bold mb-4">{json.companies.commissions}</legend>
      <p className="text-danger">{json.companies.requiredFields}</p>
      <div className="form-row">
        <InputFieldText
          label={json.companies.commission}
          name={InputCompanyNames.Commission}
          defaultValue={props.item?.commission || ""}
          placeholder={json.companies.commissionPlaceholder}
          regex={regexList.porcent}
          feedback={json.companies.commissionFeedback}
          required
          setValidity={(validity: boolean) =>
            setValidityPage3({ commission: validity })
          }
        />

        <InputFieldText
          label={json.companies.licensee}
          name={InputCompanyNames.Licensee}
          defaultValue={props.item?.licensee || ""}
          placeholder={json.companies.licenseePlaceholder}
          regex={regexList.name}
          feedback={json.companies.licenseeFeedback}
          required
        />

        <div className="col-md-12 mb-2">
          <label className="bold lead mb-4">
            {json.companies.paymentMethods}
          </label>
        </div>
        <div className="col-md-12 mb-2">
          <div className="input-group new">
            <p>{json.companies.cash}</p>
            <div>
              <label className="ms-switch">
                <input
                  type="checkbox"
                  name={InputCompanyNames.Cash}
                  defaultChecked={props.item?.paymentMethods.includes("Cash")}
                />
                <span className="ms-switch-slider round" />
              </label>
            </div>
          </div>
          <div className="input-group new">
            <p>{json.companies.paypal}</p>
            <div>
              <label className="ms-switch">
                <input
                  type="checkbox"
                  name={InputCompanyNames.Paypal}
                  defaultChecked={props.item?.paymentMethods.includes("PayPal")}
                />
                <span className="ms-switch-slider round" />
              </label>
            </div>
          </div>
          <div className="input-group new">
            <p>{json.companies.stripe}</p>
            <div>
              <label className="ms-switch">
                <input
                  type="checkbox"
                  name={InputCompanyNames.Stripe}
                  defaultChecked={props.item?.paymentMethods.includes("Stripe")}
                />
                <span className="ms-switch-slider round" />
              </label>
            </div>
          </div>
        </div>
      </div>
      <input
        type="button"
        value={json.companies.buttonBack}
        className="btn btn-primary mx-2"
        onClick={() => props.setPage(props.page - 1)}
      />
      <input
        type="button"
        value={json.companies.buttonNext}
        className="btn btn-primary mx-2"
        onClick={() => props.setPage(props.page + 1)}
        disabled={!validityPage3.commission}
      />
    </fieldset>
  );
}
