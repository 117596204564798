import { DateRange } from "../model/dateRange.model";
import { DayOfWeek } from "../model/dayOfWeek.model";
export const getCurrentMonthAndPrevious = (count: number) => {
  const months = [];
  const date = new Date();
  for (let i = count; i >= 0; i--) {
    const month = new Date(date.getFullYear(), date.getMonth() - i, 1);
    months.push(month.toLocaleString("es", { month: "long" }));
  }
  return months;
};

export const getLastMonthDailyLabels = () => {
  const dates = [];
  const today = new Date();
  const lastMonth = today.getMonth() - 1;
  const year = today.getFullYear();
  const lastDayOfLastMonth = new Date(year, today.getMonth(), 0);
  const daysInLastMonth = lastDayOfLastMonth.getDate();

  for (let i = 1; i <= daysInLastMonth; i++) {
    dates.push(i.toString());
  }

  return dates;
};

export const getLabelsForRange = (range: string) => {
  const weekDays: DayOfWeek[] = [
    DayOfWeek.Monday,
    DayOfWeek.Tuesday,
    DayOfWeek.Wednesday,
    DayOfWeek.Thursday,
    DayOfWeek.Friday,
    DayOfWeek.Saturday,
    DayOfWeek.Sunday,
  ];
  const months = getMonths();

  switch (range) {
    case DateRange.ThisWeek:
      return weekDays;
    case DateRange.LastMonth:
      return getLastMonthDailyLabels();
    case DateRange.LastThreeMonths:
      return getCurrentMonthAndPrevious(3);
    case DateRange.LastYear:
      return months;
    default:
      return [];
  }
};

export const getMonths = () => {
  return Array.from({ length: 12 }, (_, i) =>
    new Date(0, i).toLocaleString("es", { month: "long" })
  );
};
