import { OrderState } from "../model/orderState.model";
import { OrderAction } from "../model/orderAction.model";

export const filterReducer = (
  state: OrderState,
  action: OrderAction
): OrderState => {
  switch (action.type) {
    case "SET_SEARCH_START_DATE":
      return { ...state, searchStartDate: action.payload };
    case "SET_SEARCH_START_TIME":
      return { ...state, searchStartTime: action.payload };
    case "SET_SEARCH_END_DATE":
      return { ...state, searchEndDate: action.payload };
    case "SET_SEARCH_END_TIME":
      return { ...state, searchEndTime: action.payload };
    case "SET_SEARCH_ORDER_NUMBER":
      return { ...state, searchOrderNumber: action.payload };
    case "SET_SEARCH_CLIENT":
      return { ...state, searchClient: action.payload };
    case "SET_SEARCH_EMAIL":
      return { ...state, searchEmail: action.payload };
    case "SET_SEARCH_COMPANY":
      return { ...state, searchCompany: action.payload };
    case "SET_SEARCH_BRANCH":
      return { ...state, searchBranch: action.payload };
    case "SET_SEARCH_SERVICE":
      return { ...state, searchService: action.payload };
    case "SET_SEARCH_STATUS":
      return { ...state, searchStatus: action.payload };

    default:
      return state;
  }
};

export const handleInputChange = (
  e: React.ChangeEvent<HTMLInputElement>,
  dispatch: React.Dispatch<OrderAction>
) => {
  const { id, value } = e.target;

  switch (id) {
    case "searchStartDate":
      dispatch({ type: "SET_SEARCH_START_DATE", payload: value });
      break;
    case "searchStartTime":
      dispatch({ type: "SET_SEARCH_START_TIME", payload: value });
      break;
    case "searchEndDate":
      dispatch({ type: "SET_SEARCH_END_DATE", payload: value });
      break;
    case "searchEndTime":
      dispatch({ type: "SET_SEARCH_END_TIME", payload: value });
      break;
    case "searchOrderNumber":
      dispatch({ type: "SET_SEARCH_ORDER_NUMBER", payload: value });
      break;
    case "searchClient":
      dispatch({ type: "SET_SEARCH_CLIENT", payload: value });
      break;
    case "searchEmail":
      dispatch({ type: "SET_SEARCH_EMAIL", payload: value });
      break;
    case "searchCompany":
      dispatch({ type: "SET_SEARCH_COMPANY", payload: value });
      break;
    case "searchBranch":
      dispatch({ type: "SET_SEARCH_BRANCH", payload: value });
      break;
    case "searchService":
      dispatch({ type: "SET_SEARCH_SERVICE", payload: value });
      break;
    case "searchStatus":
      dispatch({ type: "SET_SEARCH_STATUS", payload: value });
      break;

    default:
      break;
  }
};
