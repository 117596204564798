import { Modal } from "react-bootstrap";
import AddCorporate from "./AddCorporateScreen";
import Logo from "../../../core/components/LogoComponent";
import ListCorporate from "./ListCorporatesScreen";
import { useContext, useState } from "react";
import LanguageChanger from "../../../core/components/LanguajeChangerComponent";
import BreadCrumb from "../components/BreadCrumbComponent";
import { LanguegeContext } from "../../../core/hooks/languages/LanguageContext";
import { Link } from "react-router-dom";
import useConfig from "../../../core/hooks/config/useConfig";
import { ModulesNames } from "../../dashboard/model/modulesNames.model";

export default function CorporatesScreen() {
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [sizeModal, setSizeModal] = useState<"lg" | "xl">("lg");
  const { json } = useContext(LanguegeContext);
  const config = useConfig();

  return (
    <div>
      <LanguageChanger />
      <Logo />
      <div className="ms-panel mb-0">
        <div className="ms-panel-header">
          <BreadCrumb />
          <h3 className="mb-0 mt-4">
            {config.includes(ModulesNames.Login)
              ? json.corporates.title
              : json.corporates["corporate"]}
          </h3>
        </div>
        <div className="ms-panel-header py-3">
          <Link
            onClick={() => setShowModalAdd(true)}
            to="#"
            className="btn btn-primary"
          >
            {json.corporates.newCorporate}
          </Link>
        </div>
        <ListCorporate />
      </div>
      <Modal
        size={sizeModal}
        show={showModalAdd}
        onHide={() => setShowModalAdd(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <button
            type="button"
            className="close"
            onClick={() => setShowModalAdd(false)}
          >
            <span aria-hidden="true">×</span>
          </button>
          <AddCorporate setSizeModal={setSizeModal} />
        </Modal.Body>
      </Modal>
    </div>
  );
}
