import { APIOrderRoutes } from "../../sales/model/apiOrderRoutes";
import { getAccessToken } from "../../../../core/storage/localStorage.db";
import validateRequest from "../../../../core/service/validateRequest.middleware";
import { HttpMethod } from "../../../../core/models/httpMethod";
import ParsingError from "../../../../core/errors/ParsingError";
import { ChartNames } from "../model/chartNames.model";
import { applyReportFilters } from "../../usecase/report.applyReportFilter";
import { ErrorForm } from "../../../../core/models/error.form.model";

export const fetchChartData = async (filters: any) => {
  const apiUrl = `${APIOrderRoutes.ResumeCharts}?chart=${ChartNames.WeeklyChart}&dateRange=${filters.dateRange}`;
  const completeUrl = applyReportFilters(apiUrl, filters);

  if (completeUrl.error === ErrorForm.None && completeUrl.url) {
    const accessToken = getAccessToken();

    const response = await validateRequest(
      HttpMethod.Get,
      completeUrl.url,
      accessToken
    );

    if (!response || typeof response !== "object" || !response.weeklyData) {
      throw new ParsingError();
    }

    return response;
  } else {
    throw new Error("Error handling filters");
  }
};
