import { useState, useEffect } from "react";
import handleError from "../../errors/handleError";
import { useHistory } from "react-router-dom";
import getConfig from "../../../modules/dashboard/service/getConfig.service";

export default function useConfig() {
  const [config, setConfig] = useState<string[]>([]);
  const history = useHistory();

  useEffect(() => {
    async function fetchCompanies() {
      try {
        const fetchedConfig: string[] = await getConfig();
        setConfig(fetchedConfig);
      } catch (error: unknown) {
        if (error instanceof Error) history.push(handleError(error));
      }
    }
    fetchCompanies();
  }, []);
  return config;
}
