import { HttpMethod } from "../../../core/models/httpMethod";
import validateRequest from "../../../core/service/validateRequest.middleware";
import { getAccessToken } from "../../../core/storage/localStorage.db";
import { APIAuthRoutes } from "../models/apiAuthRoutes.model";

export default async function getTokens(email: string, password: string) {
  try {
    const response = await validateRequest(
      HttpMethod.Post,
      APIAuthRoutes.Signin,
      getAccessToken(),
      {
        email,
        password,
      }
    );
    return {
      accessToken: response.tokens.accessToken,
      refreshToken: response.tokens.refreshToken.token,
    };
  } catch (error) {
    console.error('Login error:', error);
    console.log;
    return null;
  }
}
