import { useContext, useEffect, useState } from "react";
import Page1 from "../components/corporateWizard/GeneralDataComponent";
import Page2 from "../components/corporateWizard/ContactInfoComponent";
import { LanguegeContext } from "../../../core/hooks/languages/LanguageContext";
import { useHistory } from "react-router-dom";
import PropsUpdateCompanies from "../model/propsUpdateCorporate.model";
import handleError from "../../../core/errors/handleError";
import { InputCorporateNames } from "../model/inputCorporateNames";
import Corporate from "../model/corporate.model";
import updateCorporate from "../service/updateCorporate.service";

export default function UpdateCompanieScreen(props: PropsUpdateCompanies) {
  const { json } = useContext(LanguegeContext);
  const history = useHistory();

  const [page, setPage] = useState(1);

  useEffect(() => {
    if (page == 4) {
      props.setSizeModal("xl");
    } else {
      props.setSizeModal("lg");
    }
  }, [page]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const elements = e.currentTarget.elements;
    const name = (
      elements.namedItem(InputCorporateNames.Name) as HTMLInputElement
    ).value;
    const description = (
      elements.namedItem(InputCorporateNames.Description) as HTMLInputElement
    ).value;
    const webPage = (
      elements.namedItem(InputCorporateNames.WebPage) as HTMLInputElement
    ).value;
    const active = (
      elements.namedItem(InputCorporateNames.Active) as HTMLInputElement
    ).checked;
    const generic = false;

    const contactName = (
      elements.namedItem(InputCorporateNames.ContactName) as HTMLInputElement
    ).value;
    const contactEmail = (
      elements.namedItem(InputCorporateNames.ContactEmail) as HTMLInputElement
    ).value;
    const phone = (
      elements.namedItem(InputCorporateNames.Phone) as HTMLInputElement
    ).value;
    const countryCode = (
      elements.namedItem(InputCorporateNames.CountryCode) as HTMLInputElement
    ).value;
    const whatsapp = (
      elements.namedItem(InputCorporateNames.Whatsapp) as HTMLInputElement
    ).value;

    const corporateToUpdate: Corporate = {
      name,
      description,
      webPage,
      active,
      generic,
      contactName,
      contactEmail,
      phone,
      countryCode,
      whatsapp,
    };

    try {
      await updateCorporate(corporateToUpdate, props.item._id || "");
      history.go(0);
    } catch (error: unknown) {
      if (error instanceof Error) history.push(handleError(error));
    }
  };

  return (
    <div className="ms-content-wrapper">
      <div className="ms-panel-header mb-4 pl-0">
        <h6>{`${json.companies.updateCompany} ${props.item.name}`}</h6>
      </div>
      <form
        className="needs-validation pb-3"
        noValidate
        encType="multipart/form-data"
        onSubmit={handleSubmit}
      >
        <Page1 item={props.item} page={page} setPage={setPage} />
        <Page2 item={props.item} page={page} setPage={setPage} />
      </form>
    </div>
  );
}
