import { Link } from "react-router-dom";

export default function LogoComponent() {
  return (
    <div style={{ padding: "0px 30px 0 30px" }}>
      <Link to="/" style={{ fontSize: "30px" }}>
        Check-Eat
      </Link>
    </div>
  );
}
