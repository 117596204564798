import { Link, useHistory } from "react-router-dom";
import { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faLocationDot,
  faPhone,
  faImage,
  faEdit,
  faMoneyBill,
  faUtensils,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import UpdateCorporate from "../screens/UpdateCorporateScreen";
import { LanguegeContext } from "../../../core/hooks/languages/LanguageContext";
import handleError from "../../../core/errors/handleError";
import Corporate from "../model/corporate.model";
import deleteCorporate from "../service/deleteCorporate.service";

library.add(
  faLocationDot,
  faPhone,
  faImage,
  faEdit,
  faMoneyBill,
  faUtensils,
  faTrash
);

export default function CorporateComponent(props: { item: Corporate }) {
  const { json } = useContext(LanguegeContext);
  const history = useHistory();

  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const [sizeModal, setSizeModal] = useState<"lg" | "xl">("lg");

  const deleteButton = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    try {
      await deleteCorporate(props.item._id || "");
      history.go(0);
    } catch (error: unknown) {
      if (error instanceof Error) history.push(handleError(error));
    }
  };

  return (
    <>
      <div className="shadow mb-3 col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-lg-20">
        <div className="ms-card no-margin">
          <div className="ms-card-img">
            <img
              className="companies-image"
              src={require(`../../../assets/img/costic/food-${
                Math.floor(Math.random() * 5) + 1
              }.jpg`)}
              alt="card_img"
            />
          </div>
          <div className="ms-card-body">
            <div className="ms-card-heading-title">
              <h6>{props.item.name}</h6>
            </div>
            <div>
              <p>{props.item.description}</p>
            </div>
            <div>
              <p>
                <FontAwesomeIcon className="mr-2" icon="phone" />
                {props.item.phone}
              </p>
            </div>
            <nav className="d-flex justify-content-end">
              <ul className="d-flex flex-row justify-content-between">
                <Link
                  role="updateCorporate"
                  to="#"
                  onClick={() => setShowModalUpdate(true)}
                  className="px-1"
                >
                  <FontAwesomeIcon icon="edit" />
                </Link>
                <Link to="#" className="px-1">
                  <FontAwesomeIcon icon="utensils" />
                </Link>
                <Link to="#" className="px-1">
                  <FontAwesomeIcon icon="money-bill" />
                </Link>
                <Link
                  role="deleteCorporate"
                  to="#"
                  onClick={() => setShowModalDelete(true)}
                  className="px-1"
                >
                  <FontAwesomeIcon icon="trash" />
                </Link>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <Modal
        className="modal-min"
        show={showModalDelete}
        onHide={() => setShowModalDelete(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="text-center">
          <button
            type="button"
            className="close"
            onClick={() => setShowModalDelete(false)}
          >
            <span aria-hidden="true">×</span>
          </button>
          <i className="flaticon-alert-1 d-block" />
          <h1>{json.corporates.deleteCorporate}</h1>
          <p>{json.corporates.warningCorporate}</p>
          <form className="d-flex justify-content-around">
            <button
              type="submit"
              onClick={deleteButton}
              className="btn btn-primary shadow-none"
            >
              {json.corporates.buttonDelete}
            </button>
            <button type="submit" className="btn btn-primary shadow-none">
              {json.companies.buttonCancel}
            </button>
          </form>
        </Modal.Body>
      </Modal>

      <Modal
        size={sizeModal}
        show={showModalUpdate}
        onHide={() => setShowModalUpdate(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <button
            type="button"
            className="close"
            onClick={() => setShowModalUpdate(false)}
          >
            <span aria-hidden="true">×</span>
          </button>
          <UpdateCorporate setSizeModal={setSizeModal} item={props.item} />
        </Modal.Body>
      </Modal>
    </>
  );
}
