import { useContext } from "react";
import { LanguegeContext } from "../../../core/hooks/languages/LanguageContext";

export default function ServiceComponent(props: {
  service: string;
  name: string;
  checked: boolean;
}) {
  const { json } = useContext(LanguegeContext);

  return (
    <div className="col-md-4 mb-2">
      <div className="form-group">
        <div className="d-flex flex-column input-group">
          <p>{json.subsidiaries[props.service]}</p>
          <label className="ms-switch">
            <input
              type="checkbox"
              defaultChecked={props.checked}
              name={props.name}
            />
            <span className="ms-switch-slider round" />
          </label>
        </div>
      </div>
    </div>
  );
}
