import React, { useContext, useEffect, useState } from "react";
import { ActionMeta, SingleValue } from "react-select";
import { AppFiltersContext } from "../../service/appFiltersContext";
import { LanguegeContext } from "../../../../core/hooks/languages/LanguageContext";
import { UserInfoContext } from "../../service/userInfoContext";
import { AppConfigContext } from "../../service/appConfigContext";
import { SelectOption } from "../../charts/model/selectOption.model";
import { ConfigNames } from "../../reports/model/configNames.model";
import { UserSelectorProps } from "../../charts/model/userSelectorProps.model";
import handleCompanyChange from "../../charts/usecase/handleCompanyChange";
import handleBranchChange from "../../charts/usecase/handleBranchChange";
import AsyncSelect from "react-select/async";
import { handleCorporateSelector } from "../../charts/usecase/handleCorporateSelector";
import { handleCompanySelector } from "../../charts/usecase/handleCompanySelector";
import { handleBranchSelector } from "../../charts/usecase/handleBranchSelector";

const UserSelectorComponent: React.FC<UserSelectorProps> = ({
  isDisabled,
  setCorporateValue,
  setCompanyValue,
  setBranchValue,
  setFiltersForSearch,
}) => {
  const [corporateOptions, setCorporateOptions] = useState<SelectOption[]>([]);
  const [companyOptions, setCompanyOptions] = useState<SelectOption[]>([]);
  const [branchOptions, setBranchOptions] = useState<SelectOption[]>([]);
  const [corporateValue] = useState("");
  const [companyValue] = useState("");
  const [branchValue] = useState("");
  const [localCorporate, setLocalCorporate] = useState("");
  const [localCompany, setLocalCompany] = useState("");
  const [localBranch, setLocalBranch] = useState("");
  const { json } = useContext(LanguegeContext);
  const { filters, updateFilters } = useContext(AppFiltersContext);
  const userInfoContext = useContext(UserInfoContext);
  const userInfo = userInfoContext ? userInfoContext.userInfo : null;
  const { config } = useContext(AppConfigContext);

  useEffect(() => {
    if (userInfo?.type === ConfigNames.Admin && userInfo.corporates) {
      setCorporateOptions(
        userInfo.corporates.map((corporate) => ({
          label: corporate.name,
          value: corporate.id,
        }))
      );
    }

    if (userInfo?.type === ConfigNames.Corporates && userInfo.companies) {
      setCompanyOptions(
        userInfo.companies.map((company) => ({
          label: company.name,
          value: company.id,
        }))
      );
    }

    if (userInfo?.type === ConfigNames.Companies && userInfo.subsidiaries) {
      setBranchOptions(
        userInfo.subsidiaries.map((subsidiary) => ({
          label: subsidiary.name,
          value: subsidiary.id,
        }))
      );
    }
  }, [userInfo]);

  const onCorporateSelectorAdminChange = (
    newValue: SingleValue<SelectOption>,
    actionMeta: ActionMeta<SelectOption>
  ) => {
    if (!userInfo) {
      console.error("UserInfo is null");
      return;
    }
    setCorporateValue(newValue ? newValue.value : "");
    setLocalCorporate(newValue ? newValue.value : "");
    handleCorporateSelector(
      newValue,
      updateFilters,
      setCompanyOptions,
      setBranchOptions,
      userInfo
    );
  };

  const onCompanySelectorChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newCompanyValue = event.target.value;
    setCompanyValue(newCompanyValue);
    setLocalCompany(newCompanyValue);
    handleCompanyChange(
      event,
      updateFilters,
      setBranchOptions,
      setBranchValue,
      userInfo
    );
  };

  const onCompanySelectorAdminChange = (
    newValue: SingleValue<SelectOption>,
    actionMeta: ActionMeta<SelectOption>
  ) => {
    if (actionMeta.action === "select-option" && newValue) {
      setCompanyValue(newValue.value);
      setLocalCompany(newValue ? newValue.value : "");
      if (userInfo) {
        handleCompanySelector(
          newValue,
          updateFilters,
          setBranchOptions,
          setBranchValue,
          userInfo
        );
      } else {
        console.error("UserInfo is null");
      }
    }
  };

  const onBranchSelectorChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newBranchValue = event.target.value;
    setBranchValue(newBranchValue);
    setLocalBranch(newBranchValue);
    handleBranchChange(event, updateFilters);
  };

  const onBranchSelectorAdminChange = (
    newValue: SingleValue<SelectOption>,
    actionMeta: ActionMeta<SelectOption>
  ) => {
    if (actionMeta.action === "select-option" && newValue) {
      setBranchValue(newValue.value);
      setLocalBranch(newValue ? newValue.value : "");
      handleBranchSelector(newValue, updateFilters);
    }
  };

  const loadCorporateOptions = async (inputValue: any) => {
    return corporateOptions.filter((option) =>
      option.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };
  const loadCompanyOptions = async (inputValue: any) => {
    return companyOptions.filter((option) =>
      option.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };
  const loadBranchOptions = async (inputValue: any) => {
    return branchOptions.filter((option) =>
      option.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  return (
    <div
      className="modal-body"
      style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}
    >
      {config.includes(ConfigNames.Admin) ? (
        <>
          <div className="form-group" style={{ flex: 1, minWidth: "120px" }}>
            <label htmlFor="corporate-selector">{json.sales.corporate}</label>
            <AsyncSelect
              cacheOptions
              defaultOptions
              loadOptions={loadCorporateOptions}
              value={corporateOptions.find(
                (option) => option.value === corporateValue
              )}
              onChange={onCorporateSelectorAdminChange}
              isDisabled={isDisabled}
            />
          </div>
          <div className="form-group" style={{ flex: 1, minWidth: "120px" }}>
            <label htmlFor="company-selector">{json.sales.company}</label>
            <AsyncSelect
              cacheOptions
              defaultOptions
              loadOptions={loadCompanyOptions}
              value={companyOptions.find(
                (option) => option.value === companyValue
              )}
              onChange={onCompanySelectorAdminChange}
              isDisabled={isDisabled}
            />
          </div>
          <div className="form-group" style={{ flex: 1, minWidth: "120px" }}>
            <label htmlFor="branch-selector">{json.sales.branch}</label>
            <AsyncSelect
              cacheOptions
              defaultOptions
              loadOptions={loadBranchOptions}
              value={branchOptions.find(
                (option) => option.value === branchValue
              )}
              onChange={onBranchSelectorAdminChange}
              isDisabled={isDisabled}
            />
          </div>
        </>
      ) : (
        <>
          {config.includes(ConfigNames.Corporates) && (
            <div className="form-group" style={{ flex: 1, minWidth: "120px" }}>
              <label htmlFor="company-selector">{json.sales.company}</label>
              <select
                id="company-selector"
                className="form-control"
                value={filters.company}
                onChange={onCompanySelectorChange}
                disabled={isDisabled}
              >
                <option value="">{json.sales.all}</option>
                {companyOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          )}

          {config.includes(ConfigNames.Companies) && (
            <div className="form-group" style={{ flex: 1, minWidth: "120px" }}>
              <label htmlFor="branch-selector">{json.sales.branch}</label>
              <select
                id="branch-selector"
                className="form-control"
                value={filters.branch}
                onChange={onBranchSelectorChange}
                disabled={isDisabled}
              >
                <option value="">{json.sales.all}</option>
                {branchOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default UserSelectorComponent;
