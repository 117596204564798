import { useContext, useEffect, useState } from "react";
import { LanguegeContext } from "../../../../core/hooks/languages/LanguageContext";
import { regexList } from "../../../../core/utils/validationRegex";
import PropsPage from "../../models/propsWizardPage";
import InputFieldText from "../../../../core/components/formFields/InputFieldTextComponent";
import InputFieldCellphone from "../../../../core/components/formFields/InputFieldCellphoneComponent";
import { InputSubsidiaryNames } from "../../models/inputSubsidiaryNames";

export default function ContactInfoComponent(props: PropsPage) {
  const { json } = useContext(LanguegeContext);

  const [validityPage, setValidityPage] = useState({
    name: false,
    email: false,
    phone: false,
  });

  useEffect(() => {
    setValidityPage({
      name: !!props.company || !!props.item,
      email: !!props.company || !!props.item,
      phone: !!props.company || !!props.item,
    });
  }, [props.company]);

  return (
    <fieldset className="col-sm-12 pb-3" hidden={props.page !== 2}>
      <legend className="bold mb-4">{json.companies.contactInfo}</legend>
      <p className="text-danger">{json.companies.requiredFields}</p>
      <div className="form-row">
        <InputFieldText
          label={json.companies.contactName}
          name={InputSubsidiaryNames.ContactName}
          defaultValue={
            props.company?.contactName || props.item?.contactName || ""
          }
          placeholder={json.companies.namePlaceholder}
          regex={regexList.name}
          feedback={json.companies.nameFeedback}
          setValidity={(validity: boolean) =>
            setValidityPage({
              ...validityPage,
              name: validity,
            })
          }
          required
        />
        <InputFieldText
          label={json.companies.contactEmail}
          name={InputSubsidiaryNames.ContactEmail}
          defaultValue={
            props.company?.contactEmail || props.item?.contactEmail || ""
          }
          placeholder={json.companies.emailPlaceholder}
          regex={regexList.email}
          feedback={json.companies.contactEmailFeedback}
          setValidity={(validity: boolean) =>
            setValidityPage({
              ...validityPage,
              email: validity,
            })
          }
          required
        />
        <InputFieldText
          label={json.companies.phone}
          name={InputSubsidiaryNames.Phone}
          defaultValue={props.company?.phone || props.item?.phone || ""}
          placeholder={json.companies.phonePlaceholder}
          regex={regexList.phone}
          feedback={json.companies.phoneFeedback}
          setValidity={(validity: boolean) =>
            setValidityPage({
              ...validityPage,
              phone: validity,
            })
          }
          required
        />
        <InputFieldCellphone
          label={json.companies.whatsapp}
          countryCode={props.item?.countryCode || "+52"}
          defaultValue={props.company?.whatsapp || props.item?.whatsapp || ""}
          placeholder={json.companies.whatsappPlaceholder}
          feedback={json.companies.phoneFeedback}
          name={InputSubsidiaryNames.Whatsapp}
          regex={regexList.phone}
        />
      </div>
      <input
        type="button"
        value={json.companies.buttonBack}
        className="btn btn-primary mx-2"
        onClick={() => props.setPage(props.page - 1)}
      />
      <input
        type="button"
        value={json.companies.buttonNext}
        className="btn btn-primary mx-2"
        onClick={() => props.setPage(props.page + 1)}
        disabled={
          !(validityPage.name && validityPage.email && validityPage.phone)
        }
      />
    </fieldset>
  );
}
