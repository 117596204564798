import { useState, useEffect } from "react";
import { Order } from "../model/order.model";
import { fetchOrderDetails } from "./detailsFetch.service";
import { ProductDetail } from "../model/productDetail.model";
import { OrderState } from "../model/orderState.model";
import { useHistory } from "react-router-dom";
import handleError from "../../../../core/errors/handleError";

export const useDetailsCaller = (
  selectedOrder: Order | null,
  state: OrderState
) => {
  const [products, setProducts] = useState<ProductDetail[]>([]);
  const [error, setError] = useState<Error | null>(null);
  const history = useHistory();

  useEffect(() => {
    const fetchAndHandle = async () => {
      if (!selectedOrder) return;

      try {
        const fetchedDetails = await fetchOrderDetails(selectedOrder, state);
        setProducts(fetchedDetails);
      } catch (error) {
        history.push(handleError(error));
      }
    };

    fetchAndHandle();
  }, [selectedOrder, state]);

  return { products, error };
};
