export enum StatusKeys {
  All = "All",
  Cancelled = "Cancelled",
  Confirm = "Confirm",
  Chargeback = "Chargeback",
  Delivered = "Delivered",
  Process = "Process",
  Ready = "Ready",
  Refund = "Refund",
  Sending = "Sending",
}
