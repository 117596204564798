import { HttpMethod } from "../../../core/models/httpMethod";
import validateRequest from "../../../core/service/validateRequest.middleware";
import { getAccessToken } from "../../../core/storage/localStorage.db";
import { APICompanyRoutes } from "../model/apiCompanyRoutes";

export default async function deleteCompany(id: string) {
  await validateRequest(
    HttpMethod.Delete,
    `${APICompanyRoutes.Delete}${id}`,
    getAccessToken()
  );
}
