import React from "react";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { LanguegeContext } from "../../../../core/hooks/languages/LanguageContext";

function SalesBreadCrumbComponent() {
  const { json } = useContext(LanguegeContext);
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb pl-0">
        <li className="breadcrumb-item">
          <Link to="/">
            <i className="material-icons">home</i>
            {json.sales.dashboard}
          </Link>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          {json.sales.sales}
        </li>
      </ol>
    </nav>
  );
}

export default SalesBreadCrumbComponent;
