import { useContext, useEffect, useState } from "react";
import InputFieldSelect from "../../../../core/components/formFields/InputFieldSelectComponent";
import InputFieldText from "../../../../core/components/formFields/InputFieldTextComponent";
import { LanguegeContext } from "../../../../core/hooks/languages/LanguageContext";
import { regexList } from "../../../../core/utils/validationRegex";
import Corporate from "../../../corporates/model/corporate.model";
import getCorporate from "../../../corporates/service/getCorporates.service";
import { InputCompanyNames } from "../../model/inputCompanyNames";
import PropsPage from "../../model/propsWizardPage.model";

export default function GeneralDataComponent(props: PropsPage) {
  const { json } = useContext(LanguegeContext);
  const [corporates, setCorporates] = useState<string[]>([]);
  const [page] = useState<number>(1);
  const [groupBy] = useState<number>(20);
  const [totalPages] = useState<number>(0);
  const [totalCompanies] = useState<number>(0);


  useEffect(() => {
    async function fetchCategoriesAndSquares() {
      if (props.isCorporate) {
        const responseCorporates = await getCorporate(page, groupBy, totalPages, totalCompanies);
        setCorporates(
          responseCorporates.map((corporate: Corporate) => {
            return corporate.name;
          })
        );
      }
    }
    fetchCategoriesAndSquares();
  }, []);

  const [validityPage, setValidityPage] = useState({
    name: !!props.item,
    description: !!props.item,
    webPage: !!props.item,
  });

  return (
    <fieldset className="col-sm-12 pb-3" hidden={props.page !== 1}>
      <legend className="bold mb-4">{json.companies.generalData}</legend>
      <p className="text-danger">{json.companies.requiredFields}</p>
      <div className="form-row">
        {props.isCorporate && (
          <InputFieldSelect
            label={json.companies.corporate}
            items={corporates}
            name="company"
          />
        )}
        <InputFieldText
          label={json.companies.name}
          name={InputCompanyNames.Name}
          placeholder={json.companies.namePlaceholder}
          defaultValue={props.item?.name || ""}
          regex={regexList.name}
          feedback={json.companies.nameFeedback}
          required
          setValidity={(validity: boolean) =>
            setValidityPage({ ...validityPage, name: validity })
          }
        />
        <InputFieldText
          label={json.companies.description}
          name={InputCompanyNames.Description}
          placeholder={json.companies.descriptionPlaceholder}
          defaultValue={props.item?.description || ""}
          regex={regexList.description}
          feedback={json.companies.descriptionFeedback}
          required
          setValidity={(validity: boolean) =>
            setValidityPage({ ...validityPage, description: validity })
          }
        />
        <InputFieldText
          label={json.companies.webPage}
          name={InputCompanyNames.WebPage}
          placeholder={json.companies.webPagePlaceholder}
          defaultValue={props.item?.webPage || ""}
          regex={regexList.URL}
          feedback={json.companies.webPageFeedback}
          required
          setValidity={(validity: boolean) =>
            setValidityPage({ ...validityPage, webPage: validity })
          }
        />
      </div>
      <div className="new">
        <p>{json.companies.active}</p>
        <div>
          <label className="ms-switch">
            <input
              name={InputCompanyNames.Active}
              type="checkbox"
              defaultChecked={props.item?.active}
            />
            <span className="ms-switch-slider round" />
          </label>
        </div>
      </div>

      <input
        type="button"
        value={json.companies.buttonCancel}
        className="btn btn-primary mx-2"
      />
      <input
        type="button"
        value={json.companies.buttonNext}
        className="btn btn-primary mx-2"
        onClick={() => props.setPage(props.page + 1)}
        disabled={
          !(
            validityPage.name &&
            validityPage.description &&
            validityPage.webPage
          )
        }
      />
    </fieldset>
  );
}
