import { APIOrderRoutes } from "../../sales/model/apiOrderRoutes";
import { getAccessToken } from "../../../../core/storage/localStorage.db";
import validateRequest from "../../../../core/service/validateRequest.middleware";
import { HttpMethod } from "../../../../core/models/httpMethod";
import ParsingError from "../../../../core/errors/ParsingError";
import { ChartNames } from "../model/chartNames.model";
import { applyReportFilters } from "../../usecase/report.applyReportFilter";
import { ErrorForm } from "../../../../core/models/error.form.model";
import { getLabelsForRange } from "../utils/dateUtils";
import { getBarColor } from "../utils/getBarColor";
import { PaymentMethod } from "../model/paymentMethod.model";

export const fetchTotalOrdersChartData = async (filters: any) => {
  const apiUrl = `${APIOrderRoutes.ResumeCharts}?chart=${ChartNames.OrderChart}&dateRange=${filters.dateRange}`;
  const completeUrl = applyReportFilters(apiUrl, filters);

  if (completeUrl.error === ErrorForm.None && completeUrl.url) {
    const accessToken = getAccessToken();

    const response = await validateRequest(
      HttpMethod.Get,
      completeUrl.url,
      accessToken
    );

    if (!response || typeof response !== "object" || !response.data) {
      throw new ParsingError();
    }

    const newData = {
      labels: getLabelsForRange(filters.dateRange),
      datasets: Object.keys(response.data).map((method) => ({
        label: method,
        backgroundColor: getBarColor(method as PaymentMethod),
        data: response.data[method],
        stack: 0,
      })),
    };

    return newData;
  } else {
    throw new Error("Error handling filters");
  }
};
