import { APIOrderRoutes } from "../../sales/model/apiOrderRoutes";
import { getAccessToken } from "../../../../core/storage/localStorage.db";
import validateRequest from "../../../../core/service/validateRequest.middleware";
import { HttpMethod } from "../../../../core/models/httpMethod";
import { applyReportFilters } from "../../usecase/report.applyReportFilter";
import { ErrorForm } from "../../../../core/models/error.form.model";
import { ChartNames } from "../model/chartNames.model";

export const fetchOrderData = async (filters: any) => {
  const apiUrl = `${APIOrderRoutes.ResumeCharts}?orders=${ChartNames.LastOrders}&dateRange=${filters.dateRange}`;
  const completeUrl = applyReportFilters(apiUrl, filters);

  if (completeUrl.error === ErrorForm.None && completeUrl.url) {
    const accessToken = getAccessToken();

    const response = await validateRequest(
      HttpMethod.Get,
      completeUrl.url,
      accessToken
    );

    if (
      !response ||
      typeof response !== "object" ||
      !Array.isArray(response.data)
    ) {
      throw new Error("Error in server response");
    }

    return response.data;
  } else {
    throw new Error("Error handling filters");
  }
};
