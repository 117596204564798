import { HttpMethod } from "../../../core/models/httpMethod";
import validateRequest from "../../../core/service/validateRequest.middleware";
import { getAccessToken } from "../../../core/storage/localStorage.db";
import { APICompanyRoutes } from "../model/apiCompanyRoutes";

export default async function getCompanies() {
  const response = await validateRequest(
    HttpMethod.Get,
    `${APICompanyRoutes.GetByCorporate}corporativo1`,
    getAccessToken()
  );
  return response;
}
