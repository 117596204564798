import { useContext, useEffect, useState } from "react";
import { LanguegeContext } from "../../../../core/hooks/languages/LanguageContext";
import { regexList } from "../../../../core/utils/validationRegex";
import PropsPage from "../../model/propsWizardPage.model";
import InputFieldText from "../../../../core/components/formFields/InputFieldTextComponent";
import { InputCompanyNames } from "../../model/inputCompanyNames";

export default function ModalityComponent(props: PropsPage) {
  const { json } = useContext(LanguegeContext);

  const [eatModality, setEatModality] = useState<boolean>(false);
  const [deliveryModality, setDeliveryModality] = useState<boolean>(false);
  const [stadiumModality, setStadiumModality] = useState<boolean>(false);

  const [validityEat, setValidityEat] = useState({
    minTime: false,
    minConsumption: false,
    service: false,
  });

  const [validityStadium, setValidityStadium] = useState({
    minTime: false,
    minConsumption: false,
    service: false,
  });

  const [validityDelivery, setValidityDelivery] = useState({
    minTime: false,
    minConsumption: false,
    service: false,
    zone: false,
    zone2: false,
    distintc: false,
    range: false,
    range2: false,
  });

  useEffect(() => {
    const eatModalityTrue = props.item?.modalities.some(
      (modality) => modality?.eatTheLine
    );
    setEatModality(!!eatModalityTrue);
    setValidityEat({
      minTime: !!eatModalityTrue,
      minConsumption: !!eatModalityTrue,
      service: !!eatModalityTrue,
    });

    const deliveryModalityTrue = props.item?.modalities.some(
      (modality) => modality?.delivery
    );
    setDeliveryModality(!!deliveryModalityTrue);
    setValidityDelivery({
      minTime: !!deliveryModalityTrue,
      minConsumption: !!deliveryModalityTrue,
      service: !!deliveryModalityTrue,
      zone: !!deliveryModalityTrue,
      zone2: !!deliveryModalityTrue,
      distintc: !!deliveryModalityTrue,
      range: !!deliveryModalityTrue,
      range2: !!deliveryModalityTrue,
    });

    const stadiumModalityTrue = props.item?.modalities.some(
      (modality) => modality?.stadium
    );
    setStadiumModality(!!stadiumModalityTrue);
    setValidityStadium({
      minTime: !!stadiumModalityTrue,
      minConsumption: !!stadiumModalityTrue,
      service: !!stadiumModalityTrue,
    });
  }, []);

  const enableButton = () => {
    if (!eatModality && !deliveryModality && !stadiumModality) return true;

    if (
      eatModality &&
      !(
        validityEat.minTime &&
        validityEat.minConsumption &&
        validityEat.service
      )
    ) {
      return true;
    }

    if (
      deliveryModality &&
      !(
        validityDelivery.minTime &&
        validityDelivery.minConsumption &&
        validityDelivery.service &&
        validityDelivery.zone &&
        validityDelivery.zone2 &&
        validityDelivery.distintc &&
        validityDelivery.range &&
        validityDelivery.range2
      )
    ) {
      return true;
    }

    if (
      stadiumModality &&
      !(
        stadiumModality &&
        validityStadium.minTime &&
        validityStadium.minConsumption &&
        validityStadium.service
      )
    ) {
      return true;
    }

    return false;
  };

  return (
    <fieldset className="col-sm-12" hidden={props.page !== 4}>
      <legend className="bold mb-3">{json.companies.modality}</legend>
      <div className="row">
        <div className="col-lg-4 mb-5 shadow">
          <div className="row mb-4 pt-4">
            <h5 className="col-sm-6">{json.companies.eatTheLine}</h5>
            <div className="col-sm-6">
              <label className="ms-switch">
                <input
                  type="checkbox"
                  name={InputCompanyNames.EatTheLine}
                  onChange={() => {
                    setEatModality(!eatModality);
                    setStadiumModality(false);
                  }}
                  checked={eatModality}
                />
                <span className="ms-switch-slider round" />
              </label>
            </div>
          </div>
          <InputFieldText
            label={json.companies.minTime}
            name={InputCompanyNames.MinTimeEat}
            defaultValue={
              props.item?.modalities.find((modality) => modality?.eatTheLine)
                ?.eatTheLine?.minTime || ""
            }
            placeholder={json.companies.minTimePlaceholder}
            regex={regexList.number}
            feedback={json.companies.invalidValue}
            setValidity={(validity: boolean) =>
              setValidityEat({
                ...validityEat,
                minTime: validity,
              })
            }
            disabled={!eatModality}
            required
          />

          <InputFieldText
            label={json.companies.minConsumption}
            name={InputCompanyNames.MinConsumptionEat}
            defaultValue={
              props.item?.modalities.find((modality) => modality?.eatTheLine)
                ?.eatTheLine?.minConsumption || ""
            }
            placeholder={json.companies.minConsumptionPlaceholder}
            regex={regexList.number}
            feedback={json.companies.invalidConsume}
            setValidity={(validity: boolean) =>
              setValidityEat({
                ...validityEat,
                minConsumption: validity,
              })
            }
            disabled={!eatModality}
            required
          />

          <InputFieldText
            label={json.companies.service}
            name={InputCompanyNames.ServiceEat}
            defaultValue={
              props.item?.modalities.find((modality) => modality?.eatTheLine)
                ?.eatTheLine?.service || ""
            }
            placeholder={json.companies.cost}
            regex={regexList.number}
            feedback={json.companies.invalidCost}
            setValidity={(validity: boolean) =>
              setValidityEat({
                ...validityEat,
                service: validity,
              })
            }
            disabled={!eatModality}
            required
          />
        </div>
        <div className="col-lg-4 mb-5 shadow pb-3">
          <div className="row mb-4 pt-4">
            <h5 className="col-sm-6">{json.companies.delivery}</h5>
            <div className="col-sm-6">
              <label className="ms-switch">
                <input
                  type="checkbox"
                  name={InputCompanyNames.Delivery}
                  onChange={() => {
                    setDeliveryModality(!deliveryModality);
                    setStadiumModality(false);
                  }}
                  checked={deliveryModality}
                />
                <span className="ms-switch-slider round" />
              </label>
            </div>
          </div>
          <InputFieldText
            label={json.companies.minTime}
            name={InputCompanyNames.MinTimeDelivery}
            defaultValue={
              props.item?.modalities.find((modality) => modality?.delivery)
                ?.delivery?.minTime || ""
            }
            placeholder={json.companies.minTimePlaceholder}
            regex={regexList.number}
            feedback={json.companies.invalidValue}
            setValidity={(validity: boolean) =>
              setValidityDelivery({
                ...validityDelivery,
                minTime: validity,
              })
            }
            disabled={!deliveryModality}
            required
          />

          <InputFieldText
            label={json.companies.minConsumption}
            name={InputCompanyNames.MinConsumptionDelivery}
            defaultValue={
              props.item?.modalities.find((modality) => modality?.delivery)
                ?.delivery?.minConsumption || ""
            }
            placeholder={json.companies.minConsumptionPlaceholder}
            regex={regexList.number}
            feedback={json.companies.invalidConsume}
            setValidity={(validity: boolean) =>
              setValidityDelivery({
                ...validityDelivery,
                minConsumption: validity,
              })
            }
            disabled={!deliveryModality}
            required
          />

          <InputFieldText
            label={json.companies.service}
            name={InputCompanyNames.ServiceDelivery}
            defaultValue={
              props.item?.modalities.find((modality) => modality?.delivery)
                ?.delivery?.service || ""
            }
            placeholder={json.companies.cost}
            regex={regexList.number}
            feedback={json.companies.invalidCost}
            setValidity={(validity: boolean) =>
              setValidityDelivery({
                ...validityDelivery,
                service: validity,
              })
            }
            disabled={!deliveryModality}
            required
          />

          <InputFieldText
            label={json.companies.zone}
            name={InputCompanyNames.Zone}
            defaultValue={
              props.item?.modalities.find((modality) => modality?.delivery)
                ?.delivery?.zone || ""
            }
            placeholder={json.companies.cost}
            regex={regexList.number}
            feedback={json.companies.invalidCost}
            setValidity={(validity: boolean) =>
              setValidityDelivery({
                ...validityDelivery,
                zone: validity,
              })
            }
            disabled={!deliveryModality}
            required
          />

          <InputFieldText
            label={json.companies.zone2}
            name={InputCompanyNames.Zone2}
            defaultValue={
              props.item?.modalities.find((modality) => modality?.delivery)
                ?.delivery?.zone2 || ""
            }
            placeholder={json.companies.cost}
            regex={regexList.number}
            feedback={json.companies.invalidCost}
            setValidity={(validity: boolean) =>
              setValidityDelivery({
                ...validityDelivery,
                zone2: validity,
              })
            }
            disabled={!deliveryModality}
            required
          />

          <InputFieldText
            label={json.companies.distinct}
            name={InputCompanyNames.Distinct}
            defaultValue={
              props.item?.modalities.find((modality) => modality?.delivery)
                ?.delivery?.distinct || ""
            }
            placeholder={json.companies.cost}
            regex={regexList.number}
            feedback={json.companies.invalidCost}
            setValidity={(validity: boolean) =>
              setValidityDelivery({
                ...validityDelivery,
                distintc: validity,
              })
            }
            disabled={!deliveryModality}
            required
          />

          <InputFieldText
            label={json.companies.range}
            name={InputCompanyNames.Range}
            defaultValue={
              props.item?.modalities.find((modality) => modality?.delivery)
                ?.delivery?.range || ""
            }
            placeholder={json.companies.rangePlaceholder}
            regex={regexList.number}
            feedback={json.companies.invalidDistance}
            setValidity={(validity: boolean) =>
              setValidityDelivery({
                ...validityDelivery,
                range: validity,
              })
            }
            disabled={!deliveryModality}
            required
          />

          <InputFieldText
            label={json.companies.range2}
            name={InputCompanyNames.Range2}
            defaultValue={
              props.item?.modalities.find((modality) => modality?.delivery)
                ?.delivery?.range2 || ""
            }
            placeholder={json.companies.rangePlaceholder}
            regex={regexList.number}
            feedback={json.companies.invalidDistance}
            setValidity={(validity: boolean) =>
              setValidityDelivery({
                ...validityDelivery,
                range2: validity,
              })
            }
            disabled={!deliveryModality}
            required
          />
        </div>
        <div className="col-lg-4 mb-5 shadow">
          <div className="row mb-4 pt-4">
            <h5 className="col-sm-6">{json.companies.stadium}</h5>
            <div className="col-sm-6">
              <label className="ms-switch">
                <input
                  type="checkbox"
                  name={InputCompanyNames.Stadium}
                  onChange={() => {
                    setStadiumModality(!stadiumModality);
                    setEatModality(false);
                    setDeliveryModality(false);
                  }}
                  checked={stadiumModality}
                />
                <span className="ms-switch-slider round" />
              </label>
            </div>
          </div>

          <InputFieldText
            label={json.companies.minTime}
            name={InputCompanyNames.MinTimeStadium}
            defaultValue={
              props.item?.modalities.find((modality) => modality?.stadium)
                ?.stadium?.minTime || ""
            }
            placeholder={json.companies.minTimePlaceholder}
            regex={regexList.number}
            feedback={json.companies.invalidValue}
            setValidity={(validity: boolean) =>
              setValidityStadium({
                ...validityStadium,
                minTime: validity,
              })
            }
            disabled={!stadiumModality}
            required
          />

          <InputFieldText
            label={json.companies.minConsumption}
            name={InputCompanyNames.MinConsumptionStadium}
            defaultValue={
              props.item?.modalities.find((modality) => modality?.stadium)
                ?.stadium?.minConsumption || ""
            }
            placeholder={json.companies.minConsumptionPlaceholder}
            regex={regexList.number}
            feedback={json.companies.invalidConsume}
            setValidity={(validity: boolean) =>
              setValidityStadium({
                ...validityStadium,
                minConsumption: validity,
              })
            }
            disabled={!stadiumModality}
            required
          />

          <InputFieldText
            label={json.companies.service}
            name={InputCompanyNames.ServiceStadium}
            defaultValue={
              props.item?.modalities.find((modality) => modality?.stadium)
                ?.stadium?.service || ""
            }
            placeholder={json.companies.cost}
            regex={regexList.number}
            feedback={json.companies.invalidCost}
            setValidity={(validity: boolean) =>
              setValidityStadium({
                ...validityStadium,
                service: validity,
              })
            }
            disabled={!stadiumModality}
            required
          />
        </div>
      </div>
      <input
        type="button"
        value={json.companies.buttonBack}
        className="btn btn-primary mx-2"
        onClick={() => props.setPage(props.page - 1)}
      />
      <input
        type="button"
        value={json.companies.buttonNext}
        className="btn btn-primary mx-2"
        onClick={() => props.setPage(props.page + 1)}
        disabled={enableButton()}
      />
    </fieldset>
  );
}
