import { HttpMethod } from "../../../core/models/httpMethod";
import validateRequest from "../../../core/service/validateRequest.middleware";
import { getAccessToken } from "../../../core/storage/localStorage.db";
import { APICompanyRoutes } from "../model/apiCompanyRoutes";
import Company from "../model/company.model";

export default async function addCompany(company: Company) {
  await validateRequest(
    HttpMethod.Post,
    APICompanyRoutes.Create,
    getAccessToken(),
    company
  );
}
