import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export const exportPDF = async () => {
  const domElement = document.getElementById("reportTable");
  if (!domElement) {
    return;
  }

  const canvas = await html2canvas(domElement, {
    scale: 2,
    useCORS: true,
  });

  const imgData = canvas.toDataURL("image/png");

  const pdf = new jsPDF({
    orientation: "landscape",
    unit: "mm",
    format: "a4",
  });

  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight = pdf.internal.pageSize.getHeight();

  const margin = 10;
  const contentWidth = pdfWidth - 2 * margin;
  const contentHeight = pdfHeight - 2 * margin;

  const imgWidth = canvas.width;
  const imgHeight = canvas.height;
  const imgRatio = imgWidth / imgHeight;
  let finalImgWidth, finalImgHeight;

  if (contentWidth / imgRatio > contentHeight) {
    finalImgHeight = contentHeight;
    finalImgWidth = finalImgHeight * imgRatio;
  } else {
    finalImgWidth = contentWidth;
    finalImgHeight = finalImgWidth / imgRatio;
  }

  const posX = (pdfWidth - finalImgWidth) / 2;
  const posY = (pdfHeight - finalImgHeight) / 2;

  pdf.addImage(imgData, "PNG", posX, posY, finalImgWidth, finalImgHeight);

  pdf.save("report.pdf");
};
