import React, { useState, useEffect, useContext } from "react";
import { OrderData } from "../../model/orderData";
import { LanguegeContext } from "../../../../../core/hooks/languages/LanguageContext";
import { AppFiltersContext } from "../../../service/appFiltersContext";
import { fetchOrderData } from "../../service/orderTableFetch.service";
import { useHistory } from "react-router-dom";
import handleError from "../../../../../core/errors/handleError";

interface RecentOrderTableComponentProps {
  setExecuteSearch: (searchFunction: () => void) => void;
}
const RecentOrderTableComponent: React.FC<RecentOrderTableComponentProps> = ({
  setExecuteSearch,
}) => {
  const [orderData, setOrderData] = useState<OrderData[]>([]);
  const { json } = useContext(LanguegeContext);
  const { filters } = useContext(AppFiltersContext);
  const history = useHistory();

  const handleSearch = async () => {
    try {
      const data = await fetchOrderData(filters);
      setOrderData(data);
    } catch (error) {
      history.push(handleError(error));
    }
  };

  useEffect(() => {
    handleSearch();
  }, []);

  useEffect(() => {
    setExecuteSearch(() => handleSearch);
  }, [setExecuteSearch, filters]);

  return (
    <div className="table-responsive">
      <table className="table table-hover thead-light">
        <thead>
          <tr>
            <th scope="col">{json.sales.order}</th>
            <th scope="col">{json.sales.date}</th>
            <th scope="col">{json.sales.customer}</th>
            <th scope="col">{json.sales.subtotal}</th>
            <th scope="col">{json.sales.status}</th>
          </tr>
        </thead>
        <tbody>
          {orderData.map((row, index) => (
            <tr key={index}>
              <td>{row.order}</td>
              <td>{row.date}</td>
              <td>{row.customer}</td>
              <td>{row.total}</td>
              <td>{row.status}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RecentOrderTableComponent;
