export enum ErrorForm {
  Date = "Date",
  Client = "Client",
  Company = "Company",
  Branch = "Branch",
  Service = "Service",
  Status = "Status",
  Order = "Order",
  Corporate = "Corporate",
  None = "None",
}
