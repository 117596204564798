import { ErrorForm } from "../../../../core/models/error.form.model";
import { FilterResult } from "../../model/filterResult.model";

export function isValidCorporateID(
  state: any,
  validStringRegex: RegExp
): FilterResult {
  if (state.searchCorporate == undefined) {
    return { error: ErrorForm.Corporate };
  } else {
    if (
      state.searchCorporate !== "" &&
      validStringRegex.test(state.searchCorporate)
    ) {
      return {
        error: ErrorForm.None,
        url: `&corporateID=${state.searchCorporate}`,
      };
    } else {
      return { error: ErrorForm.Corporate };
    }
  }
}
