import { library } from "@fortawesome/fontawesome-svg-core";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

library.add(faEye, faEyeSlash);
export default function InputFieldPasswordComponent(props: {
  label: string;
  placeholder: string;
  defaultValue: string;
  name: string;
  regex: RegExp;
  feedback: string;
  required: boolean;
  setValidity: (validity: boolean) => void;
}) {
  const [value, setValue] = useState(props.defaultValue);
  const [validityInput, setValidityInput] = useState("");
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const newValue = e.target.value;
    setValue(newValue);
    if (props.regex.test(newValue)) {
      setValidityInput("showBorderGreen");
      props.setValidity(true);
    } else {
      setValidityInput("showBorderRed");
      props.setValidity(false);
    }
  };

  return (
    <div className="col-md-12 p-0 mb-2">
      <label htmlFor="validationCustom03">
        {props.label}
        {props.required && <span className="text-danger"> *</span>}
      </label>

      <div className="input-group">
        <input
          type={showPassword ? "text" : "password"}
          className={`form-control ${validityInput}`}
          name={props.name}
          value={value}
          onChange={handleChange}
          placeholder={props.placeholder}
          required={props.required}
        />

        <FontAwesomeIcon
          className="password-icon text-dark"
          icon={showPassword ? "eye-slash" : "eye"}
          onClick={() => setShowPassword(!showPassword)}
        />

        <div
          className={
            validityInput == "" || validityInput == "showBorderGreen"
              ? "invalid-feedback"
              : "invalid-feedback showElementValited"
          }
          id="nameCompanieFeedback"
        >
          {props.feedback}
        </div>
      </div>
    </div>
  );
}
