import React from "react";
import { GeneralReportTableProps } from "../../model/generalReportTableProps.model";
import { useContext } from "react";
import { LanguegeContext } from "../../../../../core/hooks/languages/LanguageContext";

const GeneralReportTable: React.FC<GeneralReportTableProps> = ({
  tdcPaypal,
  cash,
  specials,
  total,
  cancelled,
  additionalData,
  serviceFeeData,
  userInfo,
  startDate,
  endDate,
}) => {
  const { json } = useContext(LanguegeContext);
  const name = userInfo ? userInfo.name : "";
  const rfc = userInfo ? userInfo.rfc : "";
  const email = userInfo ? userInfo.email : "";

  const formattedStartDate = new Date(startDate).toLocaleDateString("es-MX");
  const formattedEndDate = new Date(endDate).toLocaleDateString("es-MX");

  const period = `${formattedStartDate} - ${formattedEndDate}`;

  return (
    <>
      <div style={{ margin: "20px 0", textAlign: "right" }}>
        <h1
          style={{ fontSize: "24px", marginBottom: "5px", textAlign: "center" }}
        >
          {json.sales.salesReport}
        </h1>
        <p>
          <strong>{name}</strong>
        </p>
        <p>
          <strong>{json.sales.rfc}</strong> {rfc}
        </p>
        <p>
          <strong>{json.sales.email}</strong> {email}
        </p>
        <p>
          <strong>{json.sales.actualDate}</strong>{" "}
          {new Date().toLocaleDateString("es-MX")}
        </p>
        <p>
          <strong>{json.sales.period}</strong> {period}
        </p>
      </div>

      <table className="table">
        <thead>
          <tr>
            <th></th>
            <th>{json.sales.tdcPaypal}</th>
            <th>{json.sales.cash}</th>
            <th>{json.sales.specials}</th>
            <th>{json.sales.total}</th>
            <th>{json.sales.cancelled}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{json.sales.noOrders}</td>
            <td>{tdcPaypal.orderCount}</td>
            <td>{cash.orderCount}</td>
            <td>{specials.orderCount}</td>
            <td>{total.orderCount}</td>
            <td>{cancelled.orderCount}</td>
          </tr>
          <tr>
            <td>{json.sales.salesTitle}</td>
            <td>{tdcPaypal.sales}</td>
            <td>{cash.sales}</td>
            <td>{specials.sales}</td>
            <td>{total.sales}</td>
            <td>{cancelled.sales}</td>
          </tr>
          <tr>
            <td>{json.sales.shippingAmount}</td>
            <td>{tdcPaypal.shippingAmount}</td>
            <td>{cash.shippingAmount}</td>
            <td>{specials.shippingAmount}</td>
            <td>{total.shippingAmount}</td>
            <td>{cancelled.shippingAmount}</td>
          </tr>
          <tr>
            <td>{json.sales.discounts}</td>
            <td>{tdcPaypal.discounts}</td>
            <td>{cash.discounts}</td>
            <td>{specials.discounts}</td>
            <td>{total.discounts}</td>
            <td>{cancelled.discounts}</td>
          </tr>
          <tr>
            <td>{json.sales.amountCharged}</td>
            <td>{tdcPaypal.amountCharged}</td>
            <td>{cash.amountCharged}</td>
            <td>{specials.amountCharged}</td>
            <td>{total.amountCharged}</td>
            <td>{cancelled.amountCharged}</td>
          </tr>
          <tr>
            <td>{json.sales.refunds}s</td>
            <td>{tdcPaypal.refunds}</td>
            <td>{cash.refunds}</td>
            <td>{specials.refunds}</td>
            <td>{total.refunds}</td>
            <td>{cancelled.refunds}</td>
          </tr>
          <tr>
            <td>{json.sales.serviceFee}</td>
            <td>{tdcPaypal.serviceFee}</td>
            <td>{cash.serviceFee}</td>
            <td>{specials.serviceFee}</td>
            <td>{total.serviceFee}</td>
            <td>{cancelled.serviceFee}</td>
          </tr>
          <tr>
            <td>{json.sales.amount}</td>
            <td>{tdcPaypal.amount}</td>
            <td>{cash.amount}</td>
            <td>{specials.amount}</td>
            <td>{total.amount}</td>
            <td>{cancelled.amount}</td>
          </tr>
        </tbody>
      </table>
      <br />
      <h6
        style={{ fontSize: "20px", marginBottom: "5px", textAlign: "center" }}
      >
        {json.sales.salesTDCPaypal}
      </h6>
      <br />
      <table className="table mt-4">
        <thead>
          <tr>
            <th>{json.sales.baseIVA}</th>
            <th>{json.sales.baseISR}</th>
            <th>{json.sales.retentionIVA}</th>
            <th>{json.sales.retentionISR}</th>
            <th>{json.sales.toLiquidate}</th>
            <th>{json.sales.iva}</th>
            <th>{json.sales.reference}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{additionalData.baseIVA}</td>
            <td>{additionalData.baseISR}</td>
            <td>{additionalData.retencionIVA}</td>
            <td>{additionalData.retencionISR}</td>
            <td>{additionalData.toLiquidate}</td>
            <td>{additionalData.IVA}</td>
            <td>{additionalData.reference}</td>
          </tr>
        </tbody>
      </table>
      <br />
      <h6
        style={{ fontSize: "20px", marginBottom: "5px", textAlign: "center" }}
      >
        {json.sales.serviceFee}
      </h6>
      <br />
      <table className="table mt-4">
        <thead>
          <tr>
            <th>{json.sales.totalToPay}</th>
            <th>{json.sales.iva}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{serviceFeeData.totalToPay}</td>
            <td>{serviceFeeData.IVA}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default GeneralReportTable;
