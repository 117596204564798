import React from "react";

type UpdateFiltersFunction = (filters: { branch: string }) => void;

const handleBranchChange = (
  event: React.ChangeEvent<HTMLSelectElement>,
  updateFilters: UpdateFiltersFunction
) => {
  const branchId = event.target.value;
  updateFilters({ branch: branchId });
};

export default handleBranchChange;
