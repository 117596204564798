import { DatasetLabel } from "../model/dataSetLabel.model";

export function getLineColor(label: DatasetLabel) {
  switch (label) {
    case DatasetLabel.AllUsers:
      return "#ff6384";
    case DatasetLabel.Active:
      return "#36a2eb";
    case DatasetLabel.NewUsers:
      return "#cc65fe";
    default:
      return "#000";
  }
}
