import { StatusKeys } from "../../model/statusKeys.model";
import { useContext } from "react";
import { LanguegeContext } from "../../../../core/hooks/languages/LanguageContext";

export const EnumStatusTranslations = () => {
  const { json } = useContext(LanguegeContext);
  const statusTranslations = {
    [StatusKeys.All]: json.sales.all,
    [StatusKeys.Cancelled]: json.sales.cancelled,
    [StatusKeys.Confirm]: json.sales.confirm,
    [StatusKeys.Chargeback]: json.sales.chargeback,
    [StatusKeys.Delivered]: json.sales.delivered,
    [StatusKeys.Process]: json.sales.process,
    [StatusKeys.Ready]: json.sales.ready,
    [StatusKeys.Refund]: json.sales.refunds,
    [StatusKeys.Sending]: json.sales.sending,
  };
  return { StatusKeys, statusTranslations };
};
