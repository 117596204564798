import React, { useEffect, useState, useReducer, useContext } from "react";
import DataTable from "react-data-table-component";
import SaleModal from "./DetailsModalScreen";
import OrderHistoryModal from "./OrderHistoryModalScreen";
import FilterOptions from "./FilterOptionsScreen";
import { InitialState } from "../model/orderState.model";
import reducer, {
  handleEmailClick,
  handleDetailsClick,
} from "../reducer/order.reducer";
import { LanguegeContext } from "../../../../core/hooks/languages/LanguageContext";
import SalesBreadCrumbComponent from "../components/SalesBreadCrumbComponent";
import { Order } from "../model/order.model";
import { fetchData } from "../service/orderFetch.service";
import { getTableColumns } from "../components/model/tableColumn.model";
import { useHistory } from "react-router-dom";
import handleError from "../../../../core/errors/handleError";
import { Link } from "react-router-dom";
import SideNavigation from "../../components/layouts/SideNavigationComponent";
import TopNavigation from "../../components/layouts/TopNavigationComponent";
import { exportToCSV } from "../../reports/utils/exportCSV";

const OrdersContent: React.FC = () => {
  const { json } = useContext(LanguegeContext);
  const [state, dispatch] = useReducer(reducer, InitialState);
  const [filteredData, setFilteredData] = useState<Order[]>([]);
  const history = useHistory();
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [paginationInfo, setPaginationInfo] = useState({
    totalRecords: 0,
    currentPage: state.page,
    perPage: state.perPage,
  });
  useEffect(() => {
    const fetchDataAndSetState = async () => {
      try {
        const { orders, totalRecords, currentPage, perPage } = await fetchData(
          state.page,
          state.perPage
        );
        setFilteredData(orders);
        setPaginationInfo({
          totalRecords,
          currentPage,
          perPage,
        });
      } catch (error) {
        history.push(handleError(error));
      }
    };

    fetchDataAndSetState();
  }, [state.page, state.perPage]);

  const columns = getTableColumns({
    json,
    selectOrderId: (order: Order) => order.orderId,
    selectCompanyName: (order: Order) => order.companyInformation.companyName,
    selectSubsidiaryName: (order: Order) =>
      order.companyInformation.subsidaryName,
    selectUserContactInfo: (order: Order) =>
      order.userInformation.userContactInfo,
    selectDateTime: (order: Order) => order.dateTime,
    selectServiceType: (order: Order) => order.serviceType,
    selectTotal: (order: Order) => order.total,
    selectStatus: (order: Order) => order.status,
    handleEmailClick: (e: React.MouseEvent<any, MouseEvent>, row: Order) =>
      handleEmailClick(e, row, dispatch),
    handleDetailsClick: (row: Order) => handleDetailsClick(row, dispatch),
  });

  const handleExport = () => {
    exportToCSV(filteredData, "orders.csv");
  };

  return (
    <div className="ms-body ms-aside-left-open ms-primary-theme ">
      <SideNavigation isSidebarOpen={isSidebarOpen} />
      <main className="body-content">
        <TopNavigation />
        <div className="ms-content-wrapper">
          <SaleModal
            isOpen={state.modalOpen}
            onClose={() => dispatch({ type: "SET_MODAL_OPEN", payload: false })}
            selectedOrder={state.selectedSale}
            state={state}
          />
          <OrderHistoryModal
            isOpen={state.orderHistoryModalOpen}
            onClose={() =>
              dispatch({ type: "SET_ORDER_HISTORY_MODAL_OPEN", payload: false })
            }
            orderHistory={state.orderHistory}
            state={state}
            selectedUserID={state.selectedUserID}
          />

          <div className="row">
            <div className="col-md-12">
              <SalesBreadCrumbComponent />
              <div className="ms-panel">
                <div className="ms-panel-header row">
                  <div className="col text-left">
                    <h4>{json.sales.allOrders}</h4>
                  </div>
                  <div className="col text-right">
                    <Link to="/reportes" className="btn btn-outline-primary">
                      {json.sales.reports}
                    </Link>
                  </div>
                  <button
                    onClick={handleExport}
                    className="ms-btn-icon btn-primary ml-2"
                  >
                    <i className="material-icons">save_alt</i>
                  </button>
                </div>
                <div className="ms-panel-body">
                  <div style={{ marginBottom: "20px" }}>
                    <FilterOptions
                      mappedSalesData={filteredData}
                      state={state}
                      dispatch={dispatch}
                      setFilteredData={setFilteredData}
                    />
                  </div>
                  <div className="table-responsive">
                    <DataTable
                      data={filteredData}
                      columns={columns}
                      customStyles={{
                        headRow: {
                          style: {
                            background: "#fe6876",
                            color: "white",
                            fontSize: "15px",
                          },
                        },
                      }}
                      pagination
                      paginationPerPage={10}
                      paginationRowsPerPageOptions={[10, 50, 100, 1000]}
                      paginationComponentOptions={{
                        rowsPerPageText: "Filas por página:",
                        rangeSeparatorText: "de",
                        selectAllRowsItem: true,
                        selectAllRowsItemText: "Todo",
                      }}
                      noHeader
                      highlightOnHover
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default OrdersContent;
