import React, { useState } from "react";
import { Link } from "react-router-dom";
import Scrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import Logo from "../../../../core/components/LogoComponent";
import { useContext } from "react";
import { LanguegeContext } from "../../../../core/hooks/languages/LanguageContext";

interface SideNavigationComponentProps {
  isSidebarOpen: boolean;
}

const SideNavigationComponent: React.FC<SideNavigationComponentProps> = ({
  isSidebarOpen,
}) => {
  const { json } = useContext(LanguegeContext);
  const [isDashboardActive, setDashboardActive] = useState(false);

  const toggleDashboard = () => {
    setDashboardActive(!isDashboardActive);
  };

  return (
    <div
      className={`side-nav fixed ms-aside-scrollable ms-aside-left ${
        isSidebarOpen ? "ms-aside-open" : ""
      }`}
    >
      <div className="ms-aside-overlay ms-overlay-left ms-toggler"></div>
      <div className="ms-aside-overlay ms-overlay-right ms-toggler"></div>
      <Scrollbar id="ms-side-nav">
        <div className="logo-sn ms-d-block-lg">
          <Logo />
        </div>
        <ul className="accordion ms-main-aside fs-14" id="side-nav-accordion">
          <li className={`menu-item ${isDashboardActive ? "active" : ""}`}>
            <Link to="#" className="has-chevron" onClick={toggleDashboard}>
              <span>
                <i className="material-icons fs-16">dashboard</i>
                {json.sales.dashboard}
              </span>
            </Link>
            {isDashboardActive && (
              <ul id="dashboard" className="collapse show">
                <li>
                  <Link to="/">{json.sales.home}</Link>
                </li>
              </ul>
            )}
          </li>
          <li className="menu-item">
            <Link to="/resumen">{json.sales.resume}</Link>
          </li>
          <li className="menu-item">
            <Link to="/pedidos">{json.sales.sales}</Link>
          </li>
          <li className="menu-item">
            <Link to="/reportes">{json.sales.reports}</Link>
          </li>
        </ul>
      </Scrollbar>
    </div>
  );
};

export default SideNavigationComponent;
