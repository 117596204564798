import React, { createContext, useState, useEffect, ReactNode } from "react";
import validateRequest from "../../../core/service/validateRequest.middleware";
import { HttpMethod } from "../../../core/models/httpMethod";
import { getAccessToken } from "../../../core/storage/localStorage.db";
import { SalesAPIEndpoint } from "../model/salesStartEndpoint.model";
interface AppConfigContextType {
  config: string[];
}
export const AppConfigContext = createContext<AppConfigContextType>({
  config: [],
});

export const AppConfigProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [config, setConfig] = useState<string[]>([]);

  useEffect(() => {
    validateRequest(
      HttpMethod.Get,
      SalesAPIEndpoint.SalesStart,
      getAccessToken()
    )
      .then((res) => {
        setConfig(res.config);
      })
      .catch((error) => {
        console.error("Error loading app config:", error);
      });
  }, []);

  return (
    <AppConfigContext.Provider value={{ config }}>
      {children}
    </AppConfigContext.Provider>
  );
};
