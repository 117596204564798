import { OrderState } from "../model/orderState.model";
import { OrderAction } from "../model/orderAction.model";
import { Order } from "../model/order.model";

function reducer(state: OrderState, action: OrderAction): OrderState {
  switch (action.type) {
    case "SET_SELECTED_SALE":
      return { ...state, selectedSale: action.payload };
    case "SET_MODAL_OPEN":
      return { ...state, modalOpen: action.payload };
    case "SET_SEARCH_CLIENT":
      return { ...state, searchClient: action.payload };
    case "SET_SEARCH_EMAIL":
      return { ...state, searchEmail: action.payload };
    case "SET_SEARCH_BRANCH":
      return { ...state, searchBranch: action.payload };
    case "SET_SEARCH_COMPANY":
      return { ...state, searchCompany: action.payload };
    case "SET_SEARCH_ORDER_NUMBER":
      return { ...state, searchOrderNumber: action.payload };
    case "SET_SEARCH_START_DATE":
      return { ...state, searchStartDate: action.payload };
    case "SET_SEARCH_END_DATE":
      return { ...state, searchEndDate: action.payload };
    case "SET_SEARCH_START_TIME":
      return { ...state, searchStartTime: action.payload };
    case "SET_SEARCH_END_TIME":
      return { ...state, searchEndTime: action.payload };
    case "SET_SEARCH_SERVICE":
      return { ...state, searchService: action.payload };
    case "SET_SEARCH_STATUS":
      return { ...state, searchStatus: action.payload };
    case "SET_ORDER_HISTORY":
      return { ...state, orderHistory: action.payload };
    case "SET_ORDER_HISTORY_MODAL_OPEN":
      return { ...state, orderHistoryModalOpen: action.payload };
    case "SET_PRODUCT_DETAILS":
      return {
        ...state,
        filteredProducts: action.payload,
      };
    case "SET_FILTERED_DATA":
      return {
        ...state,
        filteredData: action.payload,
      };
    case "SET_PAGE":
      return { ...state, page: action.payload };

    case "SET_PER_PAGE":
      return { ...state, perPage: action.payload };
    case "SET_SELECTED_USER_EMAIL":
      return { ...state, selectedUserEmail: action.payload };
    case "SET_SELECTED_USER_ID":
      return { ...state, selectedUserID: action.payload };
    default:
      return state;
  }
}

export function getRowData(dataTable: any, element: any): Order | null {
  const rowData: Order = dataTable.row(element.closest("tr")).data() as Order;
  return rowData;
}

export function handleEmailClick(
  event: React.MouseEvent<HTMLAnchorElement>,
  rowData: Order,
  dispatch: React.Dispatch<OrderAction>
) {
  event.preventDefault();
  const userId = rowData.userInformation.userId;
  if (!userId) {
    console.error("User ID not available");
    return;
  }

  dispatch({ type: "SET_SELECTED_USER_ID", payload: userId });

  dispatch({ type: "SET_ORDER_HISTORY_MODAL_OPEN", payload: true });
}

export function handleDetailsClick(
  rowData: Order,
  dispatch: React.Dispatch<OrderAction>
) {
  const selectedSale: Order | null = rowData
    ? {
        orderId: rowData.orderId,
        companyInformation: {
          companyId: rowData.companyInformation.companyId,
          companyName: rowData.companyInformation.companyName,
          subsidaryId: rowData.companyInformation.subsidaryId,
          subsidaryName: rowData.companyInformation.subsidaryName,
          _id: rowData.companyInformation._id,
        },
        userInformation: {
          userId: rowData.userInformation.userId,
          fullName: rowData.userInformation.fullName,
          email: rowData.userInformation.email,
          phone: rowData.userInformation.phone,
          _id: rowData.userInformation._id,
          userContactInfo: rowData.userInformation.userContactInfo,
        },
        paymentInformation: {
          paymentId: rowData.paymentInformation.paymentId,
          paymentType: rowData.paymentInformation.paymentType,
          cardBrand: {
            stripe: rowData.paymentInformation.cardBrand.stripe,
            _id: rowData.paymentInformation.cardBrand._id,
          },
          _id: rowData.paymentInformation._id,
        },
        dateTime: rowData.dateTime,
        status: rowData.status,
        discount: rowData.discount,
        serviceType: rowData.serviceType,
        total: rowData.total,
        createdAt: rowData.createdAt,
        updatedAt: rowData.updatedAt,
        _id: rowData._id,
      }
    : null;

  dispatch({ type: "SET_SELECTED_SALE", payload: selectedSale });
  dispatch({ type: "SET_MODAL_OPEN", payload: true });
}

export default reducer;
