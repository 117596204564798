import { HttpMethod } from "../../../core/models/httpMethod";
import validateRequest from "../../../core/service/validateRequest.middleware";
import { getAccessToken } from "../../../core/storage/localStorage.db";
import { APICompanyRoutes } from "../../companies/model/apiCompanyRoutes";

export default async function getCompanyData(id: string) {
  const response = await validateRequest(
    HttpMethod.Get,
    `${APICompanyRoutes.GetById}${id}`,
    getAccessToken()
  );
  return response;
}
