import { useContext, useEffect, useState } from "react";
import { LanguegeContext } from "../../../../core/hooks/languages/LanguageContext";
import PropsPage from "../../models/propsWizardPage";
import ServiceComponent from "../ServiceComponent";
import getServices from "../../service/getServices.service";

export default function ServicesComponent(props: PropsPage) {
  const { json } = useContext(LanguegeContext);
  const [services, setServices] = useState<string[]>([]);

  useEffect(() => {
    async function fetchServices() {
      const request = await getServices();
      setServices(request);
    }
    fetchServices();
  }, []);

  return (
    <fieldset className="col-sm-12 pb-3" hidden={props.page !== 5}>
      <legend className="bold mb-3">{json.subsidiaries.services}</legend>
      <div className="form-row pb-5">
        {services.map((service, index) => (
          <ServiceComponent
            checked={props.item?.services.includes(service) || false}
            name={service}
            service={service}
            key={index}
          />
        ))}
      </div>
      <input
        type="button"
        defaultValue={json.companies.buttonBack}
        className="btn btn-primary mx-2"
        onClick={() => props.setPage(props.page - 1)}
      />
      <input
        type="button"
        value={json.companies.buttonNext}
        className="btn btn-primary mx-2"
        onClick={() => props.setPage(props.page + 1)}
      />
    </fieldset>
  );
}
