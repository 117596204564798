export default class ForbiddenError extends Error {
  constructor() {
    super("Forbidden Error");
    this.name = "ForbiddenError";

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ForbiddenError);
    }
  }
}
