import { APIOrderRoutes } from "../model/apiOrderRoutes";
import { getAccessToken } from "../../../../core/storage/localStorage.db";
import validateRequest from "../../../../core/service/validateRequest.middleware";
import { HttpMethod } from "../../../../core/models/httpMethod";
import { mapOrdersData } from "../usecase/mappers/order.mapper.usecase";
import ParsingError from "../../../../core/errors/ParsingError";

export const fetchData = async (page: number, perPage: number) => {
  const apiUrl = `${APIOrderRoutes.AllOrders}?page=${page}&perPage=${perPage}`;
  const accessToken = getAccessToken();

  const response = await validateRequest(HttpMethod.Get, apiUrl, accessToken);
  if (
    !response ||
    typeof response !== "object" ||
    !Array.isArray(response.sales)
  ) {
    throw new ParsingError();
  }
  return {
    orders: mapOrdersData(response.sales),
    totalRecords: response.totalRecords,
    currentPage: response.currentPage,
    perPage: response.perPage,
  };
};
