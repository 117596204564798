export enum StatusCode {
  Ok = 200,
  Saved = 201,
  NoContent = 204,
  BadRequest = 400,
  UnAuthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  InternalServerError = 500,
  ConnectionServerError = 503,
  TimeOutServerError = 504,
}
