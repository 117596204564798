import React from "react";
import { OrderState } from "../model/orderState.model";
import { OrderAction } from "../model/orderAction.model";
import { useContext } from "react";
import { LanguegeContext } from "../../../../core/hooks/languages/LanguageContext";
import { Order } from "../model/order.model";
import { handleFilterCall } from "../service/filterCaller.service";
import { clearFilters } from "../service/filterCaller.service";
import { handleInputChange } from "../reducer/filter.reducer";
import { useState } from "react";
import { applyQuickStatusFilter } from "../service/statusfilterCaller.service";
import { useEffect } from "react";
import handleError from "../../../../core/errors/handleError";
import { useHistory } from "react-router-dom";
import validateRequest from "../../../../core/service/validateRequest.middleware";
import { HttpMethod } from "../../../../core/models/httpMethod";
import { SalesAPIEndpoint } from "../../model/salesStartEndpoint.model";
import { getAccessToken } from "../../../../core/storage/localStorage.db";
import { ConfigNames } from "../../reports/model/configNames.model";

export interface FilterOptionsProps {
  state: OrderState;
  dispatch: React.Dispatch<OrderAction>;
  mappedSalesData: Order[];
  setFilteredData: React.Dispatch<React.SetStateAction<Order[]>>;
}

const FilterOptions = ({
  state,
  dispatch,
  setFilteredData,
}: FilterOptionsProps) => {
  const [showFilters, setShowFilters] = useState(false);
  const { json } = useContext(LanguegeContext);
  const history = useHistory();
  const [config, setConfig] = useState<string[]>([]);
  const handleInputChangeWrapper: React.ChangeEventHandler<HTMLInputElement> = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    handleInputChange(e, dispatch);
  };
  const handleFilterCallWrapper = async () => {
    try {
      await handleFilterCall(state, setFilteredData);
    } catch (error) {
      history.push(handleError(error));
    }
  };

  useEffect(() => {
    validateRequest(
      HttpMethod.Get,
      SalesAPIEndpoint.SalesStart,
      getAccessToken()
    )
      .then((res: { config: string[] }) => {
        setConfig(res.config);
      })
      .catch((error) => {
        history.push(handleError(error));
      });
  }, []);

  useEffect(() => {
    handleFilterCallWrapper();
  }, [state, setFilteredData, history]);

  const clearFiltersWrapper = () => {
    clearFilters(dispatch, handleFilterCallWrapper, state, setFilteredData);
  };

  const toggleFilterVisibility = () => {
    setShowFilters(!showFilters);
  };

  const applyQuickFilter = async (newStatus: string) => {
    try {
      await applyQuickStatusFilter(state, setFilteredData, newStatus);
    } catch (error) {
      history.push(handleError(error));
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col text-right">
          <button
            onClick={toggleFilterVisibility}
            className="btn btn-primary mb-2"
          >
            {showFilters ? json.sales.hideFilters : json.sales.showFilters}
          </button>
        </div>
      </div>
      {showFilters && (
        <>
          <div className="row mb-4">
            <div className="col-6 col-md-4 offset-md-1">
              <div className="form-group mb-4">
                <input
                  id="searchStartDate"
                  type="date"
                  value={state.searchStartDate}
                  onChange={handleInputChangeWrapper}
                  className="form-control form-control-sm small-input"
                />
              </div>
            </div>
            <div className="col-6 col-md-4 offset-md-1">
              <div className="form-group mb-4">
                <input
                  id="searchEndDate"
                  type="date"
                  value={state.searchEndDate}
                  onChange={handleInputChangeWrapper}
                  className="form-control form-control-sm small-input"
                />
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col">
              <div className="form-group mb-4">
                <label htmlFor="searchOrderNumber">
                  {json.sales.orderNumber}
                </label>
                <input
                  id="searchOrderNumber"
                  type="text"
                  value={state.searchOrderNumber}
                  onChange={handleInputChangeWrapper}
                  className="form-control"
                />
              </div>
            </div>

            <div className="col">
              <div className="form-group mb-4">
                <label htmlFor="searchClient">{json.sales.customer}</label>
                <input
                  id="searchClient"
                  type="text"
                  value={state.searchClient}
                  onChange={handleInputChangeWrapper}
                  className="form-control"
                />
              </div>
            </div>
            {config.includes(ConfigNames.Corporates) && (
              <div className="col">
                <div className="form-group mb-4">
                  <label htmlFor="searchCompany">{json.sales.company}</label>
                  <input
                    id="searchCompany"
                    type="text"
                    value={state.searchCompany}
                    onChange={handleInputChangeWrapper}
                    className="form-control"
                  />
                </div>
              </div>
            )}
            {config.includes(ConfigNames.Companies) && (
              <div className="col">
                <div className="form-group mb-4">
                  <label htmlFor="searchBranch">{json.sales.branch}</label>
                  <input
                    id="searchBranch"
                    type="text"
                    value={state.searchBranch}
                    onChange={handleInputChangeWrapper}
                    className="form-control"
                  />
                </div>
              </div>
            )}
            <div className="col">
              <div className="form-group mb-4">
                <label htmlFor="searchService">{json.sales.service}</label>
                <input
                  id="searchService"
                  type="text"
                  value={state.searchService}
                  onChange={handleInputChangeWrapper}
                  className="form-control"
                />
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-auto">
              <button
                className="btn btn-primary mb-2"
                onClick={handleFilterCallWrapper}
              >
                {json.sales.search}
              </button>
            </div>
            <div className="col-auto">
              <button
                className="btn btn-secondary"
                onClick={clearFiltersWrapper}
              >
                {json.sales.clear}
              </button>
            </div>
          </div>
        </>
      )}
      {!showFilters && (
        <div className="row mb-4">
          <div className="col">
            <div
              className="btn-group"
              role="group"
              aria-label="Quick filter buttons"
            >
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={() => applyQuickFilter("")}
                style={{ marginRight: "5px" }}
              >
                {json.sales.allOrders}
              </button>
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={() => applyQuickFilter("Ready")}
                style={{ marginRight: "5px" }}
              >
                {json.sales.ready}
              </button>
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={() => applyQuickFilter("Process")}
                style={{ marginRight: "5px" }}
              >
                {json.sales.process}
              </button>
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={() => applyQuickFilter("Cancelled")}
                style={{ marginRight: "5px" }}
              >
                {json.sales.cancelled}
              </button>
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={() => applyQuickFilter("Delivered")}
                style={{ marginRight: "5px" }}
              >
                {json.sales.delivered}
              </button>
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={() => applyQuickFilter("Refund")}
                style={{ marginRight: "5px" }}
              >
                {json.sales.refund}
              </button>
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={() => applyQuickFilter("Chargeback")}
                style={{ marginRight: "0" }}
              >
                {json.sales.chargeback}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterOptions;
