import React, { useContext, useEffect, useState } from "react";
import { Bar as BarChart } from "react-chartjs-2";
import { LanguegeContext } from "../../../../../core/hooks/languages/LanguageContext";
import { BarChartOptions } from "../../model/barChartOptions.model";
import { BarChartData } from "../../model/barChartData.model";
import { AppFiltersContext } from "../../../service/appFiltersContext";
import { useHistory } from "react-router-dom";
import { fetchChartData } from "../../service/orderChartFetch.service";
import handleError from "../../../../../core/errors/handleError";

interface OrderchartComponentProps {
  setExecuteSearch: (searchFunction: () => void) => void;
}
const OrderchartComponent: React.FC<OrderchartComponentProps> = ({
  setExecuteSearch,
}) => {
  const { json } = useContext(LanguegeContext);
  const { filters } = useContext(AppFiltersContext);
  const [chartData, setChartData] = useState<BarChartData>({
    labels: [],
    datasets: [],
  });
  const [totals, setTotals] = useState({
    totalAll: 0,
    totalCanceled: 0,
    totalDelivered: 0,
    totalRefunds: 0,
  });
  const history = useHistory();
  const handleSearch = async () => {
    try {
      const response = await fetchChartData(filters);

      const { weeklyData, totals } = response;

      const newData = {
        labels: weeklyData.labels,
        datasets: [
          {
            label: json.sales.allOrders,
            backgroundColor: "#36a2eb",
            data: weeklyData.allOrders,
            stack: 1,
          },
          {
            label: json.sales.deliveredOrders,
            backgroundColor: "#cc65fe",
            data: weeklyData.deliveredOrders,
            stack: 3,
          },
          {
            label: json.sales.canceledOrders,
            backgroundColor: "#ff6384",
            data: weeklyData.canceledOrders,
            stack: 2,
          },
          {
            label: json.sales.refunds,
            backgroundColor: "#ffce56",
            data: weeklyData.refunds,
            stack: 4,
          },
        ],
      };

      setChartData(newData);
      setTotals(totals);
    } catch (error) {
      history.push(handleError(error));
    }
  };

  useEffect(() => {
    handleSearch();
  }, []);
  useEffect(() => {
    setExecuteSearch(() => handleSearch);
  }, [setExecuteSearch, filters]);

  return (
    <div className="ms-panel-body pt-0">
      <div className="d-flex justify-content-between ms-graph-meta">
        <ul className="ms-list-flex mt-3 mb-5">
          <li>
            <span>{json.sales.allOrders}</span>
            <h3 className="ms-count">{totals.totalAll}</h3>
          </li>
          <li>
            <span>{json.sales.canceledOrders}</span>
            <h3 className="ms-count">{totals.totalCanceled}</h3>
          </li>
          <li>
            <span>{json.sales.deliveredOrders}</span>
            <h3 className="ms-count">{totals.totalDelivered}</h3>
          </li>
          <li>
            <span>{json.sales.refunds}</span>
            <h3 className="ms-count">{totals.totalRefunds}</h3>
          </li>
        </ul>
      </div>
      <BarChart data={chartData} options={BarChartOptions} />
    </div>
  );
};

export default OrderchartComponent;
