import { useContext, useEffect, useState } from "react";
import { LanguegeContext } from "../../../../core/hooks/languages/LanguageContext";
import { regexList } from "../../../../core/utils/validationRegex";
import PropsPage from "../../models/propsWizardPage";
import InputFieldText from "../../../../core/components/formFields/InputFieldTextComponent";
import { InputSubsidiaryNames } from "../../models/inputSubsidiaryNames";

export default function FiscalDetailsComponent(props: PropsPage) {
  const { json } = useContext(LanguegeContext);

  const [validityPage, setValidityPage] = useState({
    regime: false,
    business: false,
    rfc: false,
    residence: false,
    representative: false,
    curp: false,
    entity: false,
    clabe: false,
    email: false,
  });

  useEffect(() => {
    setValidityPage({
      regime: !!props.company?.regime || !!props.item?.regime,
      business: !!props.company?.business || !!props.item?.business,
      rfc: !!props.company?.RFC || !!props.item?.RFC,
      residence: !!props.company?.residence || !!props.item?.residence,
      representative:
        !!props.company?.representative || !!props.item?.representative,
      curp: !!props.company?.curp || !!props.item?.curp,
      entity: !!props.company?.entity || !!props.item?.entity,
      clabe: !!props.company?.clabe || !!props.item?.clabe,
      email: !!props.company?.billingEmail || !!props.item?.billingEmail,
    });
  }, [props.company]);

  return (
    <fieldset className="col-sm-12 pb-3" hidden={props.page !== 6}>
      <legend className="bold mb-3">{json.companies.bankDetails}</legend>
      <p className="text-danger">{json.companies.requiredFields}</p>
      <div className="form-row">
        <InputFieldText
          label={json.companies.regime}
          name={InputSubsidiaryNames.Regime}
          defaultValue={props.company?.regime || props.item?.regime || ""}
          placeholder={json.companies.regimePlaceholder}
          regex={regexList.name}
          feedback={json.companies.regimeFeedback}
          setValidity={(validity: boolean) =>
            setValidityPage({
              ...validityPage,
              regime: validity,
            })
          }
          required
        />

        <InputFieldText
          label={json.companies.business}
          name={InputSubsidiaryNames.Business}
          defaultValue={props.company?.business || props.item?.business || ""}
          placeholder={json.companies.businessPlaceholder}
          regex={regexList.name}
          feedback={json.companies.businessFeedback}
          setValidity={(validity: boolean) =>
            setValidityPage({
              ...validityPage,
              business: validity,
            })
          }
          required
        />

        <InputFieldText
          label={json.companies.RFC}
          name={InputSubsidiaryNames.RFC}
          defaultValue={props.company?.RFC || props.item?.RFC || ""}
          placeholder={json.companies.RFCPlaceholder}
          regex={regexList.RFC}
          feedback={json.companies.RFCFeedback}
          setValidity={(validity: boolean) =>
            setValidityPage({
              ...validityPage,
              rfc: validity,
            })
          }
          required
        />

        <InputFieldText
          label={json.companies.residence}
          name={InputSubsidiaryNames.Residence}
          defaultValue={props.company?.residence || props.item?.residence || ""}
          placeholder={json.companies.residencePlaceholder}
          regex={regexList.description}
          feedback={json.companies.residenceFeedback}
          setValidity={(validity: boolean) =>
            setValidityPage({
              ...validityPage,
              residence: validity,
            })
          }
          required
        />
        <InputFieldText
          label={json.companies.representative}
          name={InputSubsidiaryNames.Representative}
          defaultValue={
            props.company?.representative || props.item?.representative || ""
          }
          placeholder={json.companies.representativePlaceholder}
          regex={regexList.name}
          feedback={json.companies.representativeFeedback}
          setValidity={(validity: boolean) =>
            setValidityPage({
              ...validityPage,
              representative: validity,
            })
          }
          required
        />

        <InputFieldText
          label={json.companies.curp}
          name={InputSubsidiaryNames.Curp}
          defaultValue={props.company?.curp || props.item?.curp || ""}
          placeholder={json.companies.curpPlaceholder}
          regex={regexList.CURP}
          feedback={json.companies.curpFeedback}
          setValidity={(validity: boolean) =>
            setValidityPage({
              ...validityPage,
              curp: validity,
            })
          }
          required
        />

        <InputFieldText
          label={json.companies.entity}
          name={InputSubsidiaryNames.Entity}
          defaultValue={props.company?.entity || props.item?.entity || ""}
          placeholder={json.companies.entityPlaceholder}
          regex={regexList.description}
          feedback={json.companies.entityFeedback}
          setValidity={(validity: boolean) =>
            setValidityPage({
              ...validityPage,
              entity: validity,
            })
          }
          required
        />

        <InputFieldText
          label={json.companies.clabe}
          name={InputSubsidiaryNames.Clabe}
          defaultValue={props.company?.clabe || props.item?.clabe || ""}
          placeholder={json.companies.clabePlaceholder}
          regex={regexList.CLABE}
          feedback={json.companies.clabeFeedback}
          setValidity={(validity: boolean) =>
            setValidityPage({
              ...validityPage,
              clabe: validity,
            })
          }
          required
        />

        <InputFieldText
          label={json.companies.billingEmail}
          name={InputSubsidiaryNames.BillingEmail}
          defaultValue={
            props.company?.billingEmail || props.item?.billingEmail || ""
          }
          placeholder={json.companies.emailPlaceholder}
          regex={regexList.email}
          feedback={json.companies.contactEmailFeedback}
          setValidity={(validity: boolean) =>
            setValidityPage({
              ...validityPage,
              email: validity,
            })
          }
          required
        />
      </div>
      <input
        type="button"
        value={json.companies.buttonBack}
        className="btn btn-primary mx-2"
        onClick={() => props.setPage(props.page - 1)}
      />
      <input
        type="button"
        value={json.companies.buttonNext}
        className="btn btn-primary mx-2"
        onClick={() => props.setPage(props.page + 1)}
        disabled={
          !(
            validityPage.regime &&
            validityPage.business &&
            validityPage.rfc &&
            validityPage.residence &&
            validityPage.representative &&
            validityPage.curp &&
            validityPage.entity &&
            validityPage.clabe &&
            validityPage.email
          )
        }
      />
      <input
        disabled={props.page != 6}
        type="submit"
        hidden={!!props.item}
        value={json.companies.buttonLater}
        className="btn btn-primary mx-2"
      />
    </fieldset>
  );
}
