import { ErrorForm } from "../../../../core/models/error.form.model";
import { FilterResult } from "../../model/filterResult.model";

export function isValidClient(
  state: any,
  validStringRegex: RegExp
): FilterResult {
  if (state.searchClient == undefined) {
    return { error: ErrorForm.Client };
  } else {
    if (
      state.searchClient !== "" &&
      validStringRegex.test(state.searchClient)
    ) {
      return { error: ErrorForm.None, url: `&name=${state.searchClient}` };
    } else {
      return { error: ErrorForm.Client };
    }
  }
}
