import { getAccessToken } from "../../../../core/storage/localStorage.db";
import validateRequest from "../../../../core/service/validateRequest.middleware";
import { HttpMethod } from "../../../../core/models/httpMethod";
import { APIOrderRoutes } from "../../sales/model/apiOrderRoutes";
import { ReportState } from "../model/reportState.model";
import { GeneralReportTableProps } from "../model/generalReportTableProps.model";
import { applyReportFilters } from "../../usecase/report.applyReportFilter";
import { ErrorForm } from "../../../../core/models/error.form.model";

export type ApiResponse = Pick<
  GeneralReportTableProps,
  | "tdcPaypal"
  | "cash"
  | "specials"
  | "total"
  | "cancelled"
  | "additionalData"
  | "serviceFeeData"
>;

export const fetchReportData = async (
  reportState: ReportState
): Promise<ApiResponse> => {
  const apiUrl = `${APIOrderRoutes.ReportRoutes}?startDate=${reportState.startDate}&endDate=${reportState.endDate}`;
  const completeUrl = applyReportFilters(apiUrl, reportState);
  if (completeUrl.error === ErrorForm.None && completeUrl.url) {
    const accessToken = getAccessToken();
    const response = await validateRequest(
      HttpMethod.Get,
      completeUrl.url,
      accessToken
    );

    if (response && typeof response === "object") {
      return {
        tdcPaypal: response.tdcPaypal,
        cash: response.cash,
        specials: response.specials,
        total: response.total,
        cancelled: response.cancelled,
        additionalData: response.additionalData,
        serviceFeeData: response.serviceFeeData,
      };
    } else {
      throw new Error("Invalid data format");
    }
  } else {
    throw new Error("Error handling filters");
  }
};
