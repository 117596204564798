import { useEffect, useState } from "react";

export default function InputFieldTextComponent(props: {
  label: string;
  placeholder: string;
  defaultValue: string | number;
  name: string;
  regex: RegExp;
  feedback: string;
  setValidity?: (validity: boolean) => void;
  required?: boolean;
  disabled?: boolean;
}) {
  const [value, setValue] = useState(props.defaultValue);
  const [borderInput, setBorderInput] = useState("");

  useEffect(() => {
    setValue(props.defaultValue);
  }, [props.defaultValue]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const newValue = e.target.value;
    setValue(newValue);
    if (props.regex.test(newValue)) {
      setBorderInput("showBorderGreen");
      props.setValidity?.(true);
    } else {
      setBorderInput("showBorderRed");
      props.setValidity?.(false);
    }
  };

  return (
    <div className="col-md-12 p-0 mb-2">
      <label htmlFor="validationCustom03">
        {props.label}
        {props.required && <span className="text-danger"> *</span>}
      </label>

      <div className="input-group">
        <input
          type="text"
          className={`form-control ${borderInput}`}
          value={value}
          onChange={handleChange}
          placeholder={props.placeholder}
          required={props.required}
          name={props.name}
          disabled={props.disabled}
        />

        <div
          className={
            borderInput == "" || borderInput == "showBorderGreen"
              ? "invalid-feedback"
              : "invalid-feedback showElementValited"
          }
          id="nameCompanieFeedback"
        >
          {props.feedback}
        </div>
      </div>
    </div>
  );
}
