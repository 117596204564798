import { useContext, useState } from "react";
import { LanguegeContext } from "../../hooks/languages/LanguageContext";

export default function InputFieldFileComponent(props: {
  label: string;
  name: string;
  regex: RegExp;
  setValidity?: (validity: boolean) => void;
  required?: boolean;
  disabled?: boolean;
}) {
  const [value, setValue] = useState("");
  const [borderInput, setBorderInput] = useState("");
  const { json } = useContext(LanguegeContext);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const newValue = e.target.value;
    setValue(newValue);
    if (props.regex.test(newValue)) {
      setBorderInput("showBorderGreen");
      props.setValidity?.(true);
    } else {
      setBorderInput("showBorderRed");
      props.setValidity?.(false);
    }
  };
  return (
    <div className="col-md-12 mb-4">
      <label htmlFor="validationCustom03">
        {props.label}
        {props.required && <span className="text-danger"> *</span>}
      </label>
      <div className="custom-file">
        <input
          type="file"
          className={`custom-file-input ${borderInput}`}
          value={value}
          onChange={handleChange}
          required={props.required}
          name={props.name}
          disabled={props.disabled}
        />
        <label className="custom-file-label" htmlFor="validatedCustomFile">
          {json.companies.selectFile}
        </label>
        <div
          className={
            borderInput == "" || borderInput == "showBorderGreen"
              ? "invalid-feedback"
              : "invalid-feedback showElementValited"
          }
          id="nameCompanieFeedback"
        >
          {json.companies.invalidFile}
        </div>
      </div>
    </div>
  );
}
