import { APIAuthRoutes } from "../../modules/auth/models/apiAuthRoutes.model";
import AuthError from "../errors/AuthError";
import ConnectionError from "../errors/ConnectionError";
import NotFoundError from "../errors/NotFoundError";
import { headerAuth } from "../models/headerAuth.model";
import { HttpMethod } from "../models/httpMethod";
import { NodeEnviroments } from "../models/nodeEnviroments";
import { StatusCode } from "../models/statusCode.model";
import { getRefreshToken, updateTokens } from "../storage/localStorage.db";

export default function validateRequest(
  method: string,
  endPoint: string,
  accessToken: string,
  body?: any
) {
  const headers = headerAuth(accessToken);
  let baseUrl = process.env.REACT_APP_BASE_URL;
  if (process.env.NODE_ENV == NodeEnviroments.Development)
    baseUrl = process.env.REACT_APP_BASE_MOCK_URL;
  if (process.env.NODE_ENV == NodeEnviroments.Test)
    baseUrl = process.env.REACT_BASE_SANDBOX_URL;

  return fetch(`${baseUrl}${endPoint}`, {
    method: method,
    headers: headers,
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (response.status == StatusCode.Ok) {
        return response.json();
      }
      if (response.status == StatusCode.Saved) {
        return response.json();
      }
      if (response.status == StatusCode.NoContent) {
        return;
      }
      if (response.status == StatusCode.BadRequest) {
        return response.json();
      }
      if (response.status == StatusCode.NotFound) {
        throw new NotFoundError();
      }
      if (response.status == StatusCode.UnAuthorized) {
        if (endPoint === APIAuthRoutes.Refresh) {
          updateTokens("", "");
          throw new AuthError();
        }

        const recallRequest = async (): Promise<[]> => {
          try {
            const response = await validateRequest(
              HttpMethod.Post,
              APIAuthRoutes.Refresh,
              accessToken,
              {
                refreshToken: getRefreshToken(),
              }
            );
            const newAccessToken = response.accessToken;
            updateTokens(newAccessToken, response.refreshToken);

            return validateRequest(method, endPoint, newAccessToken, body);
          } catch {
            updateTokens("", "");
            throw new AuthError();
          }
        };
        return recallRequest();
      }
      if (response.status == StatusCode.Forbidden) {
        throw new AuthError();
      }
      if (
        response.status == StatusCode.InternalServerError ||
        response.status == StatusCode.ConnectionServerError ||
        response.status == StatusCode.TimeOutServerError
      ) {
        throw new ConnectionError();
      }
      throw new ConnectionError();
    })
    .then((res) => res)

    .catch((message) => Promise.reject(message));
}
