import "react-perfect-scrollbar/dist/css/styles.css";
import $ from "jquery";
import LanguageChanger from "../../../../core/components/LanguajeChangerComponent";

const TopNavigationComponent = () => {
  const addsidenavigation = () => {
    $(".ms-body").toggleClass("ms-aside-left-open");
    $("#ms-side-nav").toggleClass("ms-aside-open");
    $(".ms-aside-overlay.ms-overlay-left").toggleClass("d-block");
  };

  return (
    <nav className="navbar ms-navbar">
      <div
        className="ms-aside-toggler ms-toggler pl-0"
        onClick={addsidenavigation}
      >
        <br></br>
        <br></br>
        <span className="ms-toggler-bar bg-primary"></span>
        <span className="ms-toggler-bar bg-primary"></span>
        <span className="ms-toggler-bar bg-primary"></span>
        <br></br>
        <br></br>
      </div>
      <ul className="ms-nav-list ms-inline mb-0" id="ms-nav-options">
        <li className="ms-nav-item ms-search-form pb-0 py-0">
          <LanguageChanger />
        </li>
      </ul>
    </nav>
  );
};

export default TopNavigationComponent;
