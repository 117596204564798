import { Dispatch, SetStateAction } from "react";
import { getAccessToken } from "../../../../core/storage/localStorage.db";
import validateRequest from "../../../../core/service/validateRequest.middleware";
import { HttpMethod } from "../../../../core/models/httpMethod";
import { APIOrderRoutes } from "../model/apiOrderRoutes";
import { OrderState } from "../model/orderState.model";
import { Order } from "../model/order.model";
import { applyFilters } from "../../usecase/orders.applyFilters.usecase";
import { ErrorForm } from "../../../../core/models/error.form.model";
import { OrderAction } from "../model/orderAction.model";

export type FilterCallFunction = (
  state: OrderState,
  setFilteredData: Dispatch<SetStateAction<Order[]>>
) => Promise<void>;

export const handleFilterCall: FilterCallFunction = async (
  state,
  setFilteredData
) => {
  const apiUrl = `${APIOrderRoutes.AllOrders}?page=${state.page}&perPage=${state.perPage}`;
  const completeUrl = applyFilters(apiUrl, state);

  if (completeUrl.error === ErrorForm.None && completeUrl.url) {
    const accessToken = getAccessToken();
    const response = await validateRequest(
      HttpMethod.Get,
      completeUrl.url,
      accessToken
    );
    if (
      response &&
      typeof response === "object" &&
      Array.isArray(response.sales)
    ) {
      setFilteredData(response.sales);
    } else {
      throw new Error("Invalid data format");
    }
  } else {
    throw new Error("Error handling filters");
  }
};

export const clearFilters = (
  dispatch: React.Dispatch<OrderAction>,
  handleFilterCall: FilterCallFunction,
  state: OrderState,
  setFilteredData: React.Dispatch<React.SetStateAction<Order[]>>
) => {
  dispatch({ type: "SET_SEARCH_CLIENT", payload: "" });
  dispatch({ type: "SET_SEARCH_EMAIL", payload: "" });
  dispatch({ type: "SET_SEARCH_BRANCH", payload: "" });
  dispatch({ type: "SET_SEARCH_COMPANY", payload: "" });
  dispatch({ type: "SET_SEARCH_ORDER_NUMBER", payload: "" });
  dispatch({ type: "SET_SEARCH_START_DATE", payload: "" });
  dispatch({ type: "SET_SEARCH_END_DATE", payload: "" });
  dispatch({ type: "SET_SEARCH_START_TIME", payload: "" });
  dispatch({ type: "SET_SEARCH_END_TIME", payload: "" });
  dispatch({ type: "SET_SEARCH_SERVICE", payload: "" });
  dispatch({ type: "SET_SEARCH_STATUS", payload: "" });

  const inputs = document.querySelectorAll(".showBorderRed");
  inputs.forEach((input) => {
    input.classList.remove("showBorderRed");
  });

  const alertContainer = document.getElementById("alertContainer");
  if (alertContainer) {
    alertContainer.innerHTML = "";
  }

  handleFilterCall(state, setFilteredData);
};
