export default class NotFoundError extends Error {
  constructor() {
    super("Not Found");

    this.name = "NotFoundError";

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, NotFoundError);
    }
  }
}
