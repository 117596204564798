import { Order } from "./order.model";
import { ProductDetail } from "./productDetail.model";

export interface OrderState {
  selectedSale: Order | null;
  modalOpen: boolean;
  searchClient: string;
  searchEmail: string;
  searchBranch: string;
  searchCompany: string;
  searchOrderNumber: string;
  searchStartDate: string;
  searchEndDate: string;
  searchStartTime: string;
  searchEndTime: string;
  searchService: string;
  searchStatus: string;
  orderHistory: Order[];
  orderHistoryModalOpen: boolean;
  filteredProducts: ProductDetail[];
  filteredData: Order[];
  page: number;
  perPage: number;
  selectedUserEmail: string | null;
  selectedUserID: string | null;
}

export const InitialState: OrderState = {
  selectedSale: null,
  modalOpen: false,
  searchClient: "",
  searchEmail: "",
  searchBranch: "",
  searchCompany: "",
  searchOrderNumber: "",
  searchStartDate: "",
  searchEndDate: "",
  searchStartTime: "",
  searchEndTime: "",
  searchService: "",
  searchStatus: "",
  orderHistory: [],
  orderHistoryModalOpen: false,
  filteredProducts: [],
  filteredData: [],
  page: 1,
  perPage: 100,
  selectedUserEmail: "",
  selectedUserID: "",
};
