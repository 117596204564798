import { useContext, useState } from "react";
import { LanguegeContext } from "../../../core/hooks/languages/LanguageContext";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";

export default function InputFieldScheduleComponent(props: {
  day: string;
  key: number;
  checked: boolean;
  open: string;
  close: string;
}) {
  const { json } = useContext(LanguegeContext);
  const [enableChangeTime, setEnableChangeTime] = useState(props.checked);
  const [startTime, setStarTime] = useState([0, 0]);

  return (
    <div className="col-md-12 mb-4">
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center pb-2">
          <p className="mb-0">{json.subsidiaries[props.day]}</p>
          <div className="ms-switch-container ml-2">
            <label className="ms-switch mb-0">
              <input
                defaultChecked={props.checked}
                name={props.day}
                type="checkbox"
                onChange={() => setEnableChangeTime(!enableChangeTime)}
              />
              <span className="ms-switch-slider round" />
            </label>
          </div>
        </div>
      </div>
      <div className="input-group flex-column flex-sm-row gap-3 align-items-center">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className="col-sm-12 col-md-6 mb-2 mb-sm-0 pl-0">
            <TimePicker
              label={json.subsidiaries.startTime}
              minutesStep={30}
              className="w-100"
              ampm={false}
              disabled={!enableChangeTime}
              onChange={(e: { $H: number; $M: number } | null) => {
                if (e == null) return;
                setStarTime([e.$H, e.$M]);
              }}
            />
          </div>
          <div className="col-sm-12 col-md-6 pl-0">
            <TimePicker
              label={json.subsidiaries.checkoutTime}
              minutesStep={30}
              className="w-100"
              ampm={false}
              disabled={!enableChangeTime}
              minTime={dayjs()
                .set("hour", startTime[0])
                .set("minute", startTime[1])
                .set("second", 0)}
            />
          </div>
        </LocalizationProvider>
      </div>
    </div>
  );
}
