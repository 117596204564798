import React, { createContext, useState, useEffect } from "react";
import validateRequest from "../../../core/service/validateRequest.middleware";
import { HttpMethod } from "../../../core/models/httpMethod";
import { APIOrderRoutes } from "../sales/model/apiOrderRoutes";
import { getAccessToken } from "../../../core/storage/localStorage.db";
import { UserInfoContextType } from "../charts/model/userInfoContextType.model";
import { ReactNode } from "react";
import { UserInfo } from "../charts/model/userInfo.model";

export const UserInfoContext = createContext<UserInfoContextType | null>(null);

export const UserInfoProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        setLoading(true);
        const response = await validateRequest(
          HttpMethod.Get,
          APIOrderRoutes.ReportUserInfo,
          getAccessToken()
        );
        setUserInfo(response as UserInfo);
      } catch (err) {
        setError(err as Error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserInfo();
  }, []);

  return (
    <UserInfoContext.Provider value={{ userInfo, loading, error }}>
      {children}
    </UserInfoContext.Provider>
  );
};
