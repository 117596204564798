import { HttpMethod } from "../../../core/models/httpMethod";
import validateRequest from "../../../core/service/validateRequest.middleware";
import { getAccessToken } from "../../../core/storage/localStorage.db";
import { BrowserRoutes } from "../model/browserRoutes.model";

export default async function getConfig() {
  const response: { config: string[] } = await validateRequest(
    HttpMethod.Get,
    BrowserRoutes.ApiStart,
    getAccessToken()
  );

  return response.config;
}
