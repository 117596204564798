import { useHistory } from "react-router-dom";
import { updateTokens } from "../../../core/storage/localStorage.db";
import { useContext } from "react";
import { LanguegeContext } from "../../../core/hooks/languages/LanguageContext";
import { HandleErrorRoutes } from "../../../core/models/handleErrorRoutes.model";

export default function LogOutComponent() {
  const history = useHistory();
  const { json } = useContext(LanguegeContext);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    updateTokens("", "");
    history.push(HandleErrorRoutes.Login);
  };
  return (
    <div className="col-md-6 logout">
      <div className="input-group">
        <select className="form-control" onChange={handleChange}>
          <option disabled selected>
            {json.dashboard.options}
          </option>
          <option>{json.dashboard.logOut}</option>
        </select>
      </div>
    </div>
  );
}
