import { useContext, useState } from "react";
import { LanguegeContext } from "../../../../core/hooks/languages/LanguageContext";
import { regexList } from "../../../../core/utils/validationRegex";
import PropsPage from "../../model/propsWizardPage.model";
import InputFieldText from "../../../../core/components/formFields/InputFieldTextComponent";
import InputFieldCellphone from "../../../../core/components/formFields/InputFieldCellphoneComponent";
import { InputCompanyNames } from "../../model/inputCompanyNames";

export default function ContactInfoComponent(props: PropsPage) {
  const { json } = useContext(LanguegeContext);

  const [validityPage, setValidityPage] = useState({
    contactName: !!props.item,
    email: !!props.item,
    phone: !!props.item,
  });

  return (
    <fieldset className="col-sm-12 pb-3" hidden={props.page !== 2}>
      <legend className="bold mb-4">{json.companies.contactInfo}</legend>
      <p className="text-danger">{json.companies.requiredFields}</p>
      <div className="form-row">
        <InputFieldText
          label={json.companies.contactName}
          name={InputCompanyNames.ContactName}
          defaultValue={props.item?.contactName || ""}
          placeholder={json.companies.namePlaceholder}
          regex={regexList.name}
          feedback={json.companies.nameFeedback}
          required
          setValidity={(validity: boolean) =>
            setValidityPage({ ...validityPage, contactName: validity })
          }
        />
        <InputFieldText
          label={json.companies.contactEmail}
          name={InputCompanyNames.ContactEmail}
          defaultValue={props.item?.contactEmail || ""}
          placeholder={json.companies.emailPlaceholder}
          regex={regexList.email}
          feedback={json.companies.contactEmailFeedback}
          required
          setValidity={(validity: boolean) =>
            setValidityPage({ ...validityPage, email: validity })
          }
        />
        <InputFieldText
          label={json.companies.phone}
          name={InputCompanyNames.Phone}
          defaultValue={props.item?.phone || ""}
          placeholder={json.companies.phonePlaceholder}
          regex={regexList.phone}
          feedback={json.companies.phoneFeedback}
          required
          setValidity={(validity: boolean) =>
            setValidityPage({ ...validityPage, phone: validity })
          }
        />

        <InputFieldCellphone
          label={json.companies.whatsapp}
          countryCode={props.item?.countryCode || "+52"}
          defaultValue={props.item?.whatsapp || ""}
          placeholder={json.companies.whatsappPlaceholder}
          feedback={json.companies.phoneFeedback}
          name={InputCompanyNames.Whatsapp}
          regex={regexList.phone}
        />
      </div>
      <input
        type="button"
        name={InputCompanyNames.CountryCode}
        value={json.companies.buttonBack}
        className="btn btn-primary mx-2"
        onClick={() => props.setPage(props.page - 1)}
      />
      <input
        type="button"
        value={json.companies.buttonNext}
        className="btn btn-primary mx-2"
        onClick={() => props.setPage(props.page + 1)}
        disabled={
          !(
            validityPage.contactName &&
            validityPage.email &&
            validityPage.phone
          )
        }
      />
    </fieldset>
  );
}
