import { Modal } from "react-bootstrap";
import Logo from "../../../core/components/LogoComponent";
import { useContext, useState } from "react";
import LanguageChanger from "../../../core/components/LanguajeChangerComponent";
import BreadCrumb from "../components/BreadCrumbComponent";
import { LanguegeContext } from "../../../core/hooks/languages/LanguageContext";
import { Link } from "react-router-dom";
import ListSubsidiaries from "./ListSubsidiariesScreen";
import AddSubsidiary from "./AddSubsidiaryScreen";
import useConfig from "../../../core/hooks/config/useConfig";
import { ModulesNames } from "../../dashboard/model/modulesNames.model";

export default function Subsidiaries() {
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [sizeModal, setSizeModal] = useState<"lg" | "xl">("lg");
  const { json } = useContext(LanguegeContext);
  const config = useConfig();

  return (
    <div>
      <LanguageChanger />
      <Logo />
      <div className="ms-panel mb-0">
        <div className="ms-panel-header">
          <BreadCrumb />
          <h3 className="mb-0 mt-4">
            {config.includes(ModulesNames.Companies)
              ? json.subsidiaries.title
              : json.dashboard["Subsidiary"]}
          </h3>
        </div>
        {config.includes(ModulesNames.Companies) && (
          <div className="ms-panel-header py-3">
            <Link
              onClick={() => setShowModalAdd(true)}
              to="#"
              className="btn btn-primary"
            >
              {json.subsidiaries.newSubsidiary}
            </Link>
          </div>
        )}

        <ListSubsidiaries />
      </div>
      <Modal
        size={sizeModal}
        show={showModalAdd}
        onHide={() => setShowModalAdd(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <button
            type="button"
            className="close"
            onClick={() => setShowModalAdd(false)}
          >
            <span aria-hidden="true">×</span>
          </button>
          <AddSubsidiary
            setSizeModal={setSizeModal}
            isCompany={config.includes(ModulesNames.Companies)}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}
