import { HttpMethod } from "../../../core/models/httpMethod";
import validateRequest from "../../../core/service/validateRequest.middleware";
import { getAccessToken } from "../../../core/storage/localStorage.db";
import { APICorporateRoutes } from "../model/apiCorporateRoutes";

export default async function getCorporate(page: number, groupBy: number, totalPages: number, totalCorporates: number) {
  const response = await validateRequest(
    HttpMethod.Get,
    `${APICorporateRoutes.GetAll}?page=${page}&groupBy=${groupBy}`,
    getAccessToken()
  );
  return response;
}

