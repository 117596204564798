import React, { useState, useEffect, useContext } from "react";
import { Line } from "react-chartjs-2";
import { options } from "../../model/lineChartOptions";
import { LineChartData } from "../../model/lineChartData.model";
import { AppFiltersContext } from "../../../service/appFiltersContext";
import { useHistory } from "react-router-dom";
import { fetchTicketChartData } from "../../service/ticketChartFetch.service";
import handleError from "../../../../../core/errors/handleError";

interface TicketchartComponentProps {
  setExecuteSearch: (searchFunction: () => void) => void;
}

const TicketchartComponent: React.FC<TicketchartComponentProps> = ({
  setExecuteSearch,
}) => {
  const { filters } = useContext(AppFiltersContext);
  const [chartData, setChartData] = useState<LineChartData>({
    labels: [],
    datasets: [],
  });
  const history = useHistory();
  const handleSearch = async () => {
    try {
      const newData = await fetchTicketChartData(filters);
      setChartData(newData);
    } catch (error) {
      history.push(handleError(error));
    }
  };

  useEffect(() => {
    handleSearch();
  }, []);
  useEffect(() => {
    setExecuteSearch(() => handleSearch);
  }, [setExecuteSearch, filters]);

  return (
    <>
      <Line data={chartData} options={options} />
    </>
  );
};

export default TicketchartComponent;
