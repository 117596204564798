import { SingleValue } from "react-select";
import { Corporate } from "../model/corporate.model";
import { Company } from "../model/company.model";
import { Subsidiary } from "../model/subsidiary.model";
import { SelectOption } from "../model/selectOption.model";
import { ConfigNames } from "../../reports/model/configNames.model";
import { UserInfo } from "../model/userInfo.model";

type SetOptionsFunction = (options: SelectOption[]) => void;
type UpdateFiltersFunction = (filters: {
  corporate: string;
  company: string;
  branch: string;
}) => void;

export const handleCorporateSelector = (
  newValue: SingleValue<SelectOption>,
  updateFilters: UpdateFiltersFunction,
  setCompanyOptions: SetOptionsFunction,
  setBranchOptions: SetOptionsFunction,
  userInfo: UserInfo
) => {
  if (!newValue) return;

  updateFilters({ corporate: newValue.value, company: "", branch: "" });
  setCompanyOptions([]);
  setBranchOptions([]);

  if (userInfo?.type === ConfigNames.Admin && userInfo.corporates) {
    const selectedCorporate = userInfo.corporates.find(
      (corporate: Corporate) => corporate.id === newValue.value
    );

    if (selectedCorporate && selectedCorporate.companies) {
      const newCompanyOptions = selectedCorporate.companies.map(
        (company: Company) => ({
          label: company.name,
          value: company.id,
        })
      );
      setCompanyOptions(newCompanyOptions);
      const newBranchOptions = selectedCorporate.companies.flatMap(
        (company: Company) =>
          company.subsidiaries
            ? company.subsidiaries.map((subsidiary: Subsidiary) => ({
                label: subsidiary.name,
                value: subsidiary.id,
              }))
            : []
      );
      setBranchOptions(newBranchOptions);
    }
  }
};
