import React, { createContext, useState, ReactNode } from "react";

interface FiltersState {
  corporate: string;
  company: string;
  branch: string;
  dateRange: string;
}

interface AppFiltersContextProps {
  filters: FiltersState;
  updateFilters: (updatedFilters: Partial<FiltersState>) => void;
}

const defaultFiltersState: AppFiltersContextProps = {
  filters: {
    corporate: "",
    company: "",
    branch: "",
    dateRange: "thisWeek",
  },
  updateFilters: (updatedFilters: Partial<FiltersState>) => {
    // no-op
  },
};

export const AppFiltersContext =
  createContext<AppFiltersContextProps>(defaultFiltersState);

interface AppFiltersProviderProps {
  children: ReactNode;
}

export const AppFiltersProvider: React.FC<AppFiltersProviderProps> = ({
  children,
}) => {
  const [filters, setFilters] = useState<FiltersState>(
    defaultFiltersState.filters
  );

  const updateFilters = (updatedFilters: Partial<FiltersState>) => {
    setFilters((prevFilters) => ({ ...prevFilters, ...updatedFilters }));
  };

  return (
    <AppFiltersContext.Provider value={{ filters, updateFilters }}>
      {children}
    </AppFiltersContext.Provider>
  );
};
