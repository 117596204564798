import "react-perfect-scrollbar/dist/css/styles.css";
import Orderchart from "./components/OrderchartComponent";
import ResumeBreadCrumb from "../components/ResumeBreadCrumbComponent";
import RecentOrderTable from "./components/RecentOrderTableComponent";
import { useContext, useEffect } from "react";
import { LanguegeContext } from "../../../../core/hooks/languages/LanguageContext";
import TotalOrdersChart from "./components/TotalOrdersChartComponent";
import TotalSalesChart from "./components/TotalSalesChartComponent";
import TicketChart from "./components/TicketchartComponent";
import UsersChart from "./components/UserschartComponent";
import { useState } from "react";
import { Link } from "react-router-dom";
import SideNavigation from "../../components/layouts/SideNavigationComponent";
import TopNavigation from "../../components/layouts/TopNavigationComponent";
import { UserInfoProvider } from "../../service/userInfoContext";
import UserSelector from "../../components/selector/UserSelectorComponent";
import DateRangeSelector from "../components/DateRangeSelectorComponent";
import { AppFiltersContext } from "../../service/appFiltersContext";
import { ConfigNames } from "../../reports/model/configNames.model";
import { AppConfigContext } from "../../service/appConfigContext";

const ResumeContent: React.FC = () => {
  const { json } = useContext(LanguegeContext);
  const { filters, updateFilters } = useContext(AppFiltersContext);
  const { config } = useContext(AppConfigContext);
  const [orderChartSearch, setOrderChartSearch] = useState<() => void>(
    () => () => {
      //
    }
  );
  const [lastOrdersSearch, setLastOrdersSearch] = useState<() => void>(
    () => () => {
      //
    }
  );
  const [totalOrdersSearch, setTotalOrdersSearch] = useState<() => void>(
    () => () => {
      //
    }
  );
  const [totalSalesSearch, setTotalSalesSearch] = useState<() => void>(
    () => () => {
      //
    }
  );
  const [usersSearch, setUsersSearch] = useState<() => void>(() => () => {
    //
  });
  const [ticketSearch, setTicketSearch] = useState<() => void>(() => () => {
    //
  });
  const [localCorporate, setLocalCorporate] = useState<string>("");
  const [localCompany, setLocalCompany] = useState<string>("");
  const [localBranch, setLocalBranch] = useState<string>("");

  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const setFiltersForSearch = () => {
    updateFilters({
      corporate: localCorporate,
      company: localCompany,
      branch: localBranch,
    });
  };

  const handleSearchClick = () => {
    setFiltersForSearch();
    orderChartSearch();
    lastOrdersSearch();
    totalOrdersSearch();
    totalSalesSearch();
    usersSearch();
    ticketSearch();
  };

  useEffect(() => {
    handleSearchClick();
  }, []);
  return (
    <UserInfoProvider>
      <div className="ms-body ms-aside-left-open ms-primary-theme ">
        <SideNavigation isSidebarOpen={isSidebarOpen} />
        <main className="body-content">
          <TopNavigation />
          <div className="ms-content-wrapper">
            <ResumeBreadCrumb />

            <div className="row">
              <div className="col-md-12">
                <h1 className="db-header-title">{json.sales.resume}</h1>
              </div>
              <div className="col-md-12">
                <div className="ms-panel">
                  <div
                    className="ms-panel-body"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <UserSelector
                      isDisabled={false}
                      setCorporateValue={setLocalCorporate}
                      setCompanyValue={setLocalCompany}
                      setBranchValue={setLocalBranch}
                      setFiltersForSearch={setFiltersForSearch}
                    />
                    <DateRangeSelector
                      selectedRange={filters.dateRange}
                      onChange={(value) => updateFilters({ dateRange: value })}
                    />
                    {config.includes(ConfigNames.Companies) && (
                      <button
                        className="ms-btn-icon btn-primary"
                        style={{ alignSelf: "center", marginLeft: "15px" }}
                        onClick={handleSearchClick}
                      >
                        <i className="material-icons">search</i>
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-md-12">
                <div className="ms-panel ms-panel-fh">
                  <div className="ms-panel-header">
                    <div className="d-flex justify-content-between">
                      <div className="ms-header-text">
                        <h6>{json.sales.thisWeek}</h6>
                      </div>
                    </div>
                  </div>
                  <Orderchart setExecuteSearch={setOrderChartSearch} />
                </div>
              </div>
              <div className="col-xl-6 col-md-12">
                <div className="ms-panel">
                  <div className="ms-panel-header">
                    <div className="d-flex justify-content-between">
                      <div className="align-self-center align-left">
                        <h6>{json.sales.lastOrders}</h6>
                      </div>
                      <Link to="/pedidos">
                        <button type="button" className="btn btn-primary">
                          {json.sales.viewAll}
                        </button>
                      </Link>
                    </div>
                  </div>
                  <div className="ms-panel-body">
                    <RecentOrderTable setExecuteSearch={setLastOrdersSearch} />
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-md-12">
                <div className="ms-panel ms-panel-fh">
                  <div className="ms-panel-header">
                    <div className="d-flex justify-content-between">
                      <div className="ms-header-text">
                        <h6>{json.sales.totalOrders}</h6>
                      </div>
                    </div>
                  </div>
                  <TotalOrdersChart setExecuteSearch={setTotalOrdersSearch} />
                </div>
              </div>
              <div className="col-xl-6 col-md-12">
                <div className="ms-panel ms-panel-fh">
                  <div className="ms-panel-header">
                    <div className="d-flex justify-content-between">
                      <div className="ms-header-text">
                        <h6>{json.sales.users}</h6>
                      </div>
                    </div>
                  </div>
                  <UsersChart setExecuteSearch={setUsersSearch} />
                </div>
              </div>
              <div className="col-xl-6 col-md-12">
                <div className="ms-panel ms-panel-fh">
                  <div className="ms-panel-header">
                    <div className="d-flex justify-content-between">
                      <div className="ms-header-text">
                        <h6>{json.sales.ticket}</h6>
                      </div>
                    </div>
                  </div>
                  <TicketChart setExecuteSearch={setTicketSearch} />
                </div>
              </div>
              <div className="col-xl-6 col-md-12">
                <div className="ms-panel ms-panel-fh">
                  <div className="ms-panel-header">
                    <div className="d-flex justify-content-between">
                      <div className="ms-header-text">
                        <h6>{json.sales.totalSales}</h6>
                      </div>
                    </div>
                  </div>
                  <TotalSalesChart setExecuteSearch={setTotalSalesSearch} />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </UserInfoProvider>
  );
};

export default ResumeContent;
