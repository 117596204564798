import { ErrorForm } from "../../../../core/models/error.form.model";
import { FilterResult } from "../../model/filterResult.model";

export function validateEndDate(
  state: any,
  validDateRegex: RegExp
): FilterResult {
  if (state.searchEndDate !== undefined && state.searchEndDate !== "") {
    if (!validDateRegex.test(state.searchEndDate)) {
      return { error: ErrorForm.Date };
    }

    const endDateObj = new Date(state.searchEndDate);

    if (isNaN(endDateObj.getTime())) {
      return { error: ErrorForm.Date };
    }

    const startDateObj = new Date(state.searchStartDate);

    if (endDateObj.getTime() < startDateObj.getTime()) {
      return { error: ErrorForm.Date };
    }

    return {
      error: ErrorForm.None,
      url: `&endDate=${state.searchEndDate}`,
    };
  }

  return { error: ErrorForm.Date };
}
