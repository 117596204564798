export const BarChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: "bottom",
    },
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};
