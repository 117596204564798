import { ErrorForm } from "../../../../core/models/error.form.model";
import { FilterResult } from "../../model/filterResult.model";

export function isValidStatus(
  state: any,
  validStringRegex: RegExp
): FilterResult {
  if (state.searchStatus == undefined) {
    return { error: ErrorForm.Status };
  } else {
    if (
      state.searchStatus !== "" &&
      validStringRegex.test(state.searchStatus)
    ) {
      return { error: ErrorForm.None, url: `&status=${state.searchStatus}` };
    } else {
      return { error: ErrorForm.Status };
    }
  }
}
