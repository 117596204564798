import { ErrorForm } from "../../../core/models/error.form.model";
import { FilterResult } from "../model/filterResult.model";
import { isValidClient } from "./validator/orders.checkClient.validator";
import { isValidCompany } from "./validator/orders.checkCompany.validator";
import { containsDateRange } from "./validator/orders.checkDate.validator";
import { isValidOrder } from "./validator/orders.checkOrder.validator";
import { isValidService } from "./validator/orders.checkService.validator";
import { validateEndDate } from "./validator/orders.checkEndDate.validator";
import { isValidBranch } from "./validator/orders.checkBranch.validator";
import { isValidStatus } from "./validator/orders.checkStatus.validator";

export function applyFilters(baseUrl: string, state: any): FilterResult {
  const validDateRegex = /^\d{4}-\d{2}-\d{2}$/;
  const validStringRegex = /^[A-Za-z0-9\s]+$/;

  let completeUrl = baseUrl;

  const dateRangeResult = containsDateRange(state, validDateRegex);

  if (dateRangeResult.error == ErrorForm.None) {
    completeUrl += dateRangeResult.url;

    const endDateResult = validateEndDate(state, validDateRegex);

    if (endDateResult.error == ErrorForm.None) {
      completeUrl += endDateResult.url;
    }
  }

  const branchResult = isValidBranch(state, validStringRegex);
  if (branchResult.error == ErrorForm.None && branchResult.url) {
    completeUrl += branchResult.url;
  }

  const clientResult = isValidClient(state, validStringRegex);
  if (clientResult.error == ErrorForm.None && clientResult.url) {
    completeUrl += clientResult.url;
  }

  const companyResult = isValidCompany(state, validStringRegex);
  if (companyResult.error == ErrorForm.None && companyResult.url) {
    completeUrl += companyResult.url;
  }

  const orderResult = isValidOrder(state, validStringRegex);
  if (orderResult.error == ErrorForm.None && orderResult.url) {
    completeUrl += orderResult.url;
  }

  const serviceResult = isValidService(state, validStringRegex);
  if (serviceResult.error == ErrorForm.None && serviceResult.url) {
    completeUrl += serviceResult.url;
  }

  const statusResult = isValidStatus(state, validStringRegex);
  if (statusResult.error == ErrorForm.None && statusResult.url) {
    completeUrl += statusResult.url;
  }

  return { error: ErrorForm.None, url: completeUrl };
}
