enum ColumnNames {
  Id = "id",
  OrderId = "orderId",
  CompanyName = "companyName",
  SubsidiaryName = "subsidiaryName",
  UserName = "userName",
  UserEmail = "userEmail",
  UserPhone = "userPhone",
  DateTime = "dateTime",
  Status = "status",
  Total = "total",
}

export default ColumnNames;
