import { Route, BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import AuthGuard from "./core/service/AuthGuard";
import Login from "./modules/auth/screens/LoginScreen";
import Companies from "./modules/companies/screens/CompaniesScreen";
import Corporates from "./modules/corporates/screens/CorporatesScreen";
import Dashboard from "./modules/dashboard/screens/DashboardScreen";
import ResumeContent from "./modules/orders/charts/screen/ResumeContentScreen";
import ReportComponent from "./modules/orders/reports/screens/ReportPageScreen";
import OrdersContent from "./modules/orders/sales/screens/OrdersContentScreen";
import { AppConfigProvider } from "./modules/orders/service/appConfigContext";
import { AppFiltersProvider } from "./modules/orders/service/appFiltersContext";
import { UserInfoProvider } from "./modules/orders/service/userInfoContext";
import Subsidiaries from "./modules/subsidiaries/screens/SubsidiariesScreen";

function App() {
  return (
    <Router>
      <Route path="/login" component={Login} />
      <AuthGuard>
        <Route exact path="/" component={Dashboard} />
        <Route exact path="/corporativos" component={Corporates} />
        <Route exact path="/empresas" component={Companies} />
        <Route exact path="/sucursales" component={Subsidiaries} />
        <Route exact path="/pedidos" component={OrdersContent} />
        <AppFiltersProvider>
          <AppConfigProvider>
            <Route exact path="/resumen" component={ResumeContent} />
          </AppConfigProvider>
        </AppFiltersProvider>
        <UserInfoProvider>
          <Route path="/reportes" component={ReportComponent} />
        </UserInfoProvider>
      </AuthGuard>
    </Router>
  );
}

export default App;
