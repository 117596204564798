import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { LanguegeContext } from "../../../core/hooks/languages/LanguageContext";

import GeneralData from "../components/subsidiaryWizard/GeneralDataComponent";
import ContactInfo from "../components/subsidiaryWizard/ContactInfoComponent";
import CollectionsAndCommissions from "../components/subsidiaryWizard/CollectionsAndCommissionsComponent";
import ModalityComponent from "../components/subsidiaryWizard/ModalityComponent";
import Services from "../components/subsidiaryWizard/ServicesComponent";
import FiscalDetails from "../components/subsidiaryWizard/FiscalDetailsComponent";
import Schedule from "../components/subsidiaryWizard/ScheduleComponent";
import Company from "../../companies/model/company.model";
import getCompanyData from "../service/getCompanyData.service";
import handleError from "../../../core/errors/handleError";
import { useHistory } from "react-router-dom";
import addSubsidiary from "../service/addSubsidiary.service";
import { Calendar } from "../models/calendar.model";
import { InputSubsidiaryNames } from "../models/inputSubsidiaryNames";
import Subsidiary from "../models/subsidiary.model";
import Modality from "../../companies/model/modality.model";
import Carousel from "../components/subsidiaryWizard/CarouselComponent";
import Page9 from "../components/subsidiaryWizard/GoogleMapComponent";

export default function AddSubsidiary(props: {
  setSizeModal: Dispatch<SetStateAction<"lg" | "xl">>;
  isCompany?: boolean;
}) {
  const { json } = useContext(LanguegeContext);
  const [company, setCompany] = useState<Company>();
  const history = useHistory();

  const [page, setPage] = useState(1);

  useEffect(() => {
    if (page == 4) {
      props.setSizeModal("xl");
    } else {
      props.setSizeModal("lg");
    }
  }, [page]);

  const getCompany = async () => {
    try {
      const response = await getCompanyData("id");
      setCompany(response);
    } catch (error: unknown) {
      if (error instanceof Error) history.push(handleError(error));
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const elements = e.currentTarget.elements;
    const name = (
      elements.namedItem(InputSubsidiaryNames.Name) as HTMLInputElement
    ).value;
    const description = (
      elements.namedItem(InputSubsidiaryNames.Description) as HTMLInputElement
    ).value;
    const square = (
      elements.namedItem(InputSubsidiaryNames.Square) as HTMLInputElement
    ).value;
    const category = [
      (elements.namedItem(InputSubsidiaryNames.Categorie) as HTMLInputElement)
        .value,
    ];
    const webPage = (
      elements.namedItem(InputSubsidiaryNames.WebPage) as HTMLInputElement
    ).value;
    const active = (
      elements.namedItem(InputSubsidiaryNames.Active) as HTMLInputElement
    ).checked;

    const contactName = (
      elements.namedItem(InputSubsidiaryNames.ContactName) as HTMLInputElement
    ).value;
    const contactEmail = (
      elements.namedItem(InputSubsidiaryNames.ContactEmail) as HTMLInputElement
    ).value;
    const phone = (
      elements.namedItem(InputSubsidiaryNames.Phone) as HTMLInputElement
    ).value;
    const countryCode = (
      elements.namedItem(InputSubsidiaryNames.CountryCode) as HTMLInputElement
    ).value;
    const whatsapp = (
      elements.namedItem(InputSubsidiaryNames.Whatsapp) as HTMLInputElement
    ).value;

    const commission = parseInt(
      (elements.namedItem(InputSubsidiaryNames.Commission) as HTMLInputElement)
        .value
    );
    const licensee = (
      elements.namedItem(InputSubsidiaryNames.Licensee) as HTMLInputElement
    ).value;
    const cash = (
      elements.namedItem(InputSubsidiaryNames.Cash) as HTMLInputElement
    ).checked;
    const paypal = (
      elements.namedItem(InputSubsidiaryNames.Paypal) as HTMLInputElement
    ).checked;
    const stripe = (
      elements.namedItem(InputSubsidiaryNames.Stripe) as HTMLInputElement
    ).checked;

    const eatTheLine = (
      elements.namedItem(InputSubsidiaryNames.EatTheLine) as HTMLInputElement
    ).checked;
    const minTimeEat = (
      elements.namedItem(InputSubsidiaryNames.MinTimeEat) as HTMLInputElement
    ).value;
    const minConsumptionEat = (
      elements.namedItem(
        InputSubsidiaryNames.MinConsumptionEat
      ) as HTMLInputElement
    ).value;
    const serviceEat = (
      elements.namedItem(InputSubsidiaryNames.ServiceEat) as HTMLInputElement
    ).value;

    const delivery = (
      elements.namedItem(InputSubsidiaryNames.Delivery) as HTMLInputElement
    ).checked;
    const minTimeDelivery = (
      elements.namedItem(
        InputSubsidiaryNames.MinTimeDelivery
      ) as HTMLInputElement
    ).value;
    const minConsumptionDelivery = (
      elements.namedItem(
        InputSubsidiaryNames.MinConsumptionDelivery
      ) as HTMLInputElement
    ).value;
    const serviceDelivery = (
      elements.namedItem(
        InputSubsidiaryNames.ServiceDelivery
      ) as HTMLInputElement
    ).value;
    const zone = (
      elements.namedItem(InputSubsidiaryNames.Zone) as HTMLInputElement
    ).value;
    const zone2 = (
      elements.namedItem(InputSubsidiaryNames.Zone2) as HTMLInputElement
    ).value;
    const distinct = (
      elements.namedItem(InputSubsidiaryNames.Distinct) as HTMLInputElement
    ).value;
    const range = (
      elements.namedItem(InputSubsidiaryNames.Range) as HTMLInputElement
    ).value;
    const range2 = (
      elements.namedItem(InputSubsidiaryNames.Range2) as HTMLInputElement
    ).value;

    const stadium = (
      elements.namedItem(InputSubsidiaryNames.Stadium) as HTMLInputElement
    ).checked;
    const minTimeStadium = (
      elements.namedItem(
        InputSubsidiaryNames.MinTimeStadium
      ) as HTMLInputElement
    ).value;
    const minConsumptionStadium = (
      elements.namedItem(
        InputSubsidiaryNames.MinConsumptionStadium
      ) as HTMLInputElement
    ).value;
    const serviceStadium = (
      elements.namedItem(
        InputSubsidiaryNames.ServiceStadium
      ) as HTMLInputElement
    ).value;

    const creditCards = (
      elements.namedItem(InputSubsidiaryNames.CreditCards) as HTMLInputElement
    ).checked;
    const parkingLot = (
      elements.namedItem(InputSubsidiaryNames.ParkingLot) as HTMLInputElement
    ).checked;
    const music = (
      elements.namedItem(InputSubsidiaryNames.Music) as HTMLInputElement
    ).checked;
    const petFriendly = (
      elements.namedItem(InputSubsidiaryNames.PetFriendly) as HTMLInputElement
    ).checked;
    const veganFood = (
      elements.namedItem(InputSubsidiaryNames.VeganFood) as HTMLInputElement
    ).checked;
    const childishGames = (
      elements.namedItem(InputSubsidiaryNames.ChildishGames) as HTMLInputElement
    ).checked;

    const monday = (
      elements.namedItem(InputSubsidiaryNames.Monday) as HTMLInputElement
    ).checked;

    const monday_start = (
      elements.namedItem(
        `${InputSubsidiaryNames.Monday}${InputSubsidiaryNames.start}`
      ) as HTMLInputElement
    ).value;
    const monday_end = (
      elements.namedItem(
        `${InputSubsidiaryNames.Monday}${InputSubsidiaryNames.end}`
      ) as HTMLInputElement
    ).value;

    const tuesday = (
      elements.namedItem(InputSubsidiaryNames.Tuesday) as HTMLInputElement
    ).checked;
    const tuesday_start = (
      elements.namedItem(
        `${InputSubsidiaryNames.Tuesday}${InputSubsidiaryNames.start}`
      ) as HTMLInputElement
    ).value;
    const tuesday_end = (
      elements.namedItem(
        `${InputSubsidiaryNames.Tuesday}${InputSubsidiaryNames.end}`
      ) as HTMLInputElement
    ).value;

    const wednesday = (
      elements.namedItem(InputSubsidiaryNames.Wednesday) as HTMLInputElement
    ).checked;
    const wednesday_start = (
      elements.namedItem(
        `${InputSubsidiaryNames.Wednesday}${InputSubsidiaryNames.start}`
      ) as HTMLInputElement
    ).value;
    const wednesday_end = (
      elements.namedItem(
        `${InputSubsidiaryNames.Wednesday}${InputSubsidiaryNames.end}`
      ) as HTMLInputElement
    ).value;

    const thursday = (
      elements.namedItem(InputSubsidiaryNames.Thursday) as HTMLInputElement
    ).checked;
    const thursday_start = (
      elements.namedItem(
        `${InputSubsidiaryNames.Thursday}${InputSubsidiaryNames.start}`
      ) as HTMLInputElement
    ).value;
    const thursday_end = (
      elements.namedItem(
        `${InputSubsidiaryNames.Thursday}${InputSubsidiaryNames.end}`
      ) as HTMLInputElement
    ).value;

    const friday = (
      elements.namedItem(InputSubsidiaryNames.Friday) as HTMLInputElement
    ).checked;
    const friday_start = (
      elements.namedItem(
        `${InputSubsidiaryNames.Friday}${InputSubsidiaryNames.start}`
      ) as HTMLInputElement
    ).value;
    const friday_end = (
      elements.namedItem(
        `${InputSubsidiaryNames.Friday}${InputSubsidiaryNames.end}`
      ) as HTMLInputElement
    ).value;

    const saturday = (
      elements.namedItem(InputSubsidiaryNames.Saturday) as HTMLInputElement
    ).checked;
    const saturday_start = (
      elements.namedItem(
        `${InputSubsidiaryNames.Saturday}${InputSubsidiaryNames.start}`
      ) as HTMLInputElement
    ).value;
    const saturday_end = (
      elements.namedItem(
        `${InputSubsidiaryNames.Saturday}${InputSubsidiaryNames.end}`
      ) as HTMLInputElement
    ).value;

    const sunday = (
      elements.namedItem(InputSubsidiaryNames.Sunday) as HTMLInputElement
    ).checked;
    const sunday_start = (
      elements.namedItem(
        `${InputSubsidiaryNames.Sunday}${InputSubsidiaryNames.start}`
      ) as HTMLInputElement
    ).value;
    const sunday_end = (
      elements.namedItem(
        `${InputSubsidiaryNames.Sunday}${InputSubsidiaryNames.end}`
      ) as HTMLInputElement
    ).value;

    const regime = (
      elements.namedItem(InputSubsidiaryNames.Regime) as HTMLInputElement
    ).value;
    const business = (
      elements.namedItem(InputSubsidiaryNames.Business) as HTMLInputElement
    ).value;
    const RFC = (
      elements.namedItem(InputSubsidiaryNames.RFC) as HTMLInputElement
    ).value;
    const residence = (
      elements.namedItem(InputSubsidiaryNames.Residence) as HTMLInputElement
    ).value;
    const representative = (
      elements.namedItem(
        InputSubsidiaryNames.Representative
      ) as HTMLInputElement
    ).value;
    const curp = (
      elements.namedItem(InputSubsidiaryNames.Curp) as HTMLInputElement
    ).value;
    const entity = (
      elements.namedItem(InputSubsidiaryNames.Entity) as HTMLInputElement
    ).value;
    const clabe = (
      elements.namedItem(InputSubsidiaryNames.Clabe) as HTMLInputElement
    ).value;
    const billingEmail = (
      elements.namedItem(InputSubsidiaryNames.BillingEmail) as HTMLInputElement
    ).value;

    const paymentMethods: string[] = [];
    if (cash) paymentMethods.push("Cash");
    if (paypal) paymentMethods.push("PayPal");
    if (stripe) paymentMethods.push("Stripe");

    const modalities: Modality[] = [];
    if (eatTheLine)
      modalities.push({
        eatTheLine: {
          minTime: +minTimeEat,
          minConsumption: +minConsumptionEat,
          service: +serviceEat,
        },
      });
    if (stadium)
      modalities.push({
        stadium: {
          minTime: +minTimeStadium,
          minConsumption: +minConsumptionStadium,
          service: +serviceStadium,
        },
      });
    if (delivery)
      modalities.push({
        delivery: {
          minTime: +minTimeDelivery,
          minConsumption: +minConsumptionDelivery,
          service: +serviceDelivery,
          zone: +zone,
          zone2: +zone2,
          distinct: +distinct,
          range: +range,
          range2: +range2,
        },
      });

    const services: string[] = [];
    if (creditCards) services.push(InputSubsidiaryNames.CreditCards);
    if (parkingLot) services.push(InputSubsidiaryNames.ParkingLot);
    if (music) services.push(InputSubsidiaryNames.Music);
    if (petFriendly) services.push(InputSubsidiaryNames.PetFriendly);
    if (veganFood) services.push(InputSubsidiaryNames.VeganFood);
    if (childishGames) services.push(InputSubsidiaryNames.ChildishGames);

    const schedule: Calendar[] = [];

    if (monday)
      schedule.push({
        monday: {
          openTime: monday_start,
          closeTime: monday_end,
        },
      });

    if (tuesday)
      schedule.push({
        tuesday: {
          openTime: tuesday_start,
          closeTime: tuesday_end,
        },
      });

    if (wednesday)
      schedule.push({
        wednesday: {
          openTime: wednesday_start,
          closeTime: wednesday_end,
        },
      });

    if (thursday)
      schedule.push({
        thursday: {
          openTime: thursday_start,
          closeTime: thursday_end,
        },
      });

    if (friday)
      schedule.push({
        friday: {
          openTime: friday_start,
          closeTime: friday_end,
        },
      });

    if (saturday)
      schedule.push({
        saturday: {
          openTime: saturday_start,
          closeTime: saturday_end,
        },
      });

    if (sunday)
      schedule.push({
        sunday: {
          openTime: sunday_start,
          closeTime: sunday_end,
        },
      });

    const subsidiaryToUpdate: Subsidiary = {
      company: "company1",
      name,
      description,
      square,
      category,
      webPage,
      active,
      contactName,
      contactEmail,
      phone,
      countryCode,
      whatsapp,
      commission,
      licensee,
      paymentMethods,
      modalities,
      regime,
      business,
      RFC,
      residence,
      representative,
      curp,
      entity,
      clabe,
      billingEmail,
      schedule,
      services,
    };
    try {
      await addSubsidiary(subsidiaryToUpdate);
      history.go(0);
    } catch (error: unknown) {
      if (error instanceof Error) history.push(handleError(error));
    }
  };

  return (
    <div className="ms-content-wrapper">
      <div className="ms-panel-header mb-4 pl-0">
        <h6>{json.subsidiaries.addNewSubsidiary}</h6>
        {page == 1 && (
          <input
            className="mt-2 btn btn-primary"
            type="button"
            value="Usar datos de la empresa"
            onClick={getCompany}
          />
        )}
      </div>

      <form
        className="needs-validation pb-3"
        encType="multipart/form-data"
        onSubmit={handleSubmit}
        noValidate
      >
        <GeneralData
          isCompany
          company={company}
          page={page}
          setPage={setPage}
        />
        <ContactInfo page={page} company={company} setPage={setPage} />
        <CollectionsAndCommissions
          page={page}
          company={company}
          setPage={setPage}
        />
        <ModalityComponent page={page} company={company} setPage={setPage} />
        <Services page={page} setPage={setPage} />
        <FiscalDetails page={page} company={company} setPage={setPage} />
        <Schedule page={page} setPage={setPage} />
        <Carousel page={page} setPage={setPage} />
        <Page9 page={page} setPage={setPage} />
      </form>
    </div>
  );
}
