import { useContext, useState } from "react";
import { LanguegeContext } from "../../../../core/hooks/languages/LanguageContext";
import { regexList } from "../../../../core/utils/validationRegex";
import PropsPage from "../../model/propsWizardPage.model";
import InputFieldText from "../../../../core/components/formFields/InputFieldTextComponent";
import { InputCompanyNames } from "../../model/inputCompanyNames";

export default function FiscalDetailsComponent(props: PropsPage) {
  const { json } = useContext(LanguegeContext);

  const [validityPage5, setValidityPage5] = useState({
    regime: !!props.item?.regime,
    business: !!props.item?.business,
    rfc: !!props.item?.RFC,
    residence: !!props.item?.residence,
    representative: !!props.item?.representative,
    curp: !!props.item?.curp,
    entity: !!props.item?.entity,
    clabe: !!props.item?.clabe,
    email: !!props.item?.billingEmail,
  });

  return (
    <fieldset className="col-sm-12 pb-3" hidden={props.page !== 5}>
      <legend className="bold mb-3">{json.companies.bankDetails}</legend>
      <p className="text-danger">{json.companies.requiredFields}</p>
      <div className="form-row">
        <InputFieldText
          label={json.companies.regime}
          name={InputCompanyNames.Regime}
          defaultValue={props.item?.regime || ""}
          placeholder={json.companies.regimePlaceholder}
          regex={regexList.name}
          feedback={json.companies.regimeFeedback}
          setValidity={(validity: boolean) =>
            setValidityPage5({
              ...validityPage5,
              regime: validity,
            })
          }
          required
        />

        <InputFieldText
          label={json.companies.business}
          name={InputCompanyNames.Business}
          defaultValue={props.item?.business || ""}
          placeholder={json.companies.businessPlaceholder}
          regex={regexList.name}
          feedback={json.companies.businessFeedback}
          setValidity={(validity: boolean) =>
            setValidityPage5({
              ...validityPage5,
              business: validity,
            })
          }
          required
        />

        <InputFieldText
          label={json.companies.RFC}
          name={InputCompanyNames.RFC}
          defaultValue={props.item?.RFC || ""}
          placeholder={json.companies.RFCPlaceholder}
          regex={regexList.RFC}
          feedback={json.companies.RFCFeedback}
          setValidity={(validity: boolean) =>
            setValidityPage5({
              ...validityPage5,
              rfc: validity,
            })
          }
          required
        />

        <InputFieldText
          label={json.companies.residence}
          name={InputCompanyNames.Residence}
          defaultValue={props.item?.residence || ""}
          placeholder={json.companies.residencePlaceholder}
          regex={regexList.description}
          feedback={json.companies.residenceFeedback}
          setValidity={(validity: boolean) =>
            setValidityPage5({
              ...validityPage5,
              residence: validity,
            })
          }
          required
        />

        <InputFieldText
          label={json.companies.representative}
          name={InputCompanyNames.Representative}
          defaultValue={props.item?.representative || ""}
          placeholder={json.companies.representativePlaceholder}
          regex={regexList.name}
          feedback={json.companies.representativeFeedback}
          setValidity={(validity: boolean) =>
            setValidityPage5({
              ...validityPage5,
              representative: validity,
            })
          }
          required
        />

        <InputFieldText
          label={json.companies.curp}
          name={InputCompanyNames.Curp}
          defaultValue={props.item?.curp || ""}
          placeholder={json.companies.curpPlaceholder}
          regex={regexList.CURP}
          feedback={json.companies.curpFeedback}
          setValidity={(validity: boolean) =>
            setValidityPage5({
              ...validityPage5,
              curp: validity,
            })
          }
          required
        />

        <InputFieldText
          label={json.companies.entity}
          name={InputCompanyNames.Entity}
          defaultValue={props.item?.entity || ""}
          placeholder={json.companies.entityPlaceholder}
          regex={regexList.description}
          feedback={json.companies.entityFeedback}
          setValidity={(validity: boolean) =>
            setValidityPage5({
              ...validityPage5,
              entity: validity,
            })
          }
          required
        />

        <InputFieldText
          label={json.companies.clabe}
          name={InputCompanyNames.Clabe}
          defaultValue={props.item?.clabe || ""}
          placeholder={json.companies.clabePlaceholder}
          regex={regexList.CLABE}
          feedback={json.companies.clabeFeedback}
          setValidity={(validity: boolean) =>
            setValidityPage5({
              ...validityPage5,
              clabe: validity,
            })
          }
          required
        />

        <InputFieldText
          label={json.companies.billingEmail}
          name={InputCompanyNames.BillingEmail}
          defaultValue={props.item?.billingEmail || ""}
          placeholder={json.companies.emailPlaceholder}
          regex={regexList.email}
          feedback={json.companies.contactEmailFeedback}
          setValidity={(validity: boolean) =>
            setValidityPage5({
              ...validityPage5,
              email: validity,
            })
          }
          required
        />
      </div>
      <input
        type="button"
        value={json.companies.buttonBack}
        className="btn btn-primary mx-2"
        onClick={() => props.setPage(props.page - 1)}
      />
      <input
        type="button"
        value={json.companies.buttonNext}
        className="btn btn-primary mx-2"
        onClick={() => props.setPage(props.page + 1)}
        disabled={
          !(
            validityPage5.regime &&
            validityPage5.business &&
            validityPage5.rfc &&
            validityPage5.residence &&
            validityPage5.representative &&
            validityPage5.curp &&
            validityPage5.entity &&
            validityPage5.clabe &&
            validityPage5.email
          )
        }
      />
      <input
        disabled={props.page != 5}
        type="submit"
        hidden={!!props.item}
        value={json.companies.buttonLater}
        className="btn btn-primary mx-2"
      />
    </fieldset>
  );
}
