import { HttpMethod } from "../../../core/models/httpMethod";
import validateRequest from "../../../core/service/validateRequest.middleware";
import { getAccessToken } from "../../../core/storage/localStorage.db";
import { APICompanyRoutes } from "../../companies/model/apiCompanyRoutes";
import Subsidiary from "../models/subsidiary.model";

export default async function addSubsidiary(subsidiary: Subsidiary) {
  await validateRequest(
    HttpMethod.Post,
    APICompanyRoutes.Create,
    getAccessToken(),
    subsidiary
  );
}
