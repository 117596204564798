import { createContext, useState, useEffect } from "react";

import loginEn from "../languages/login/en_US.json";
import loginEs from "../languages/login/es_MX.json";
import companiesEn from "../languages/companies/en_US.json";
import companiesEs from "../languages/companies/es_MX.json";
import salesEn from "../languages/sales/en_US.json";
import salesEs from "../languages/sales/es_MX.json";
import subsidiariesEn from "../languages/subsidiaries/en_US.json";
import subsidiariesEs from "../languages/subsidiaries/es_MX.json";
import corporatesEn from "../languages/corporates/en_US.json";
import corporatesEs from "../languages/corporates/es_MX.json";
import dashboardEn from "../languages/dashboard/en_US.json";
import dashboardEs from "../languages/dashboard/es_MX.json";

export let LanguegeContext: React.Context<{
  json: Record<string, Record<string, string>>;
  language: string;
  setLanguage: React.Dispatch<React.SetStateAction<string>>;
}>;

export function LanguageProvider({ children }: { children: React.ReactNode }) {
  const languagesEn = {
    login: loginEn,
    companies: companiesEn,
    sales: salesEn,
    subsidiaries: subsidiariesEn,
    corporates: corporatesEn,
    dashboard: dashboardEn,
  };

  const languagesEs = {
    login: loginEs,
    companies: companiesEs,
    sales: salesEs,
    subsidiaries: subsidiariesEs,
    corporates: corporatesEs,
    dashboard: dashboardEs,
  };

  const [language, setLanguage] = useState("es_MX");
  const [json, setJson] =
    useState<Record<string, Record<string, string>>>(languagesEs);

  LanguegeContext = createContext({ json, language, setLanguage });
  useEffect(() => {
    if (language == "es_MX") {
      setJson(languagesEs);
    }
    if (language == "en_US") {
      setJson(languagesEn);
    }
  }, [language]);

  return (
    <LanguegeContext.Provider value={{ json, setLanguage, language }}>
      {children}
    </LanguegeContext.Provider>
  );
}
