import AuthError from "./AuthError";
import ForbiddenError from "./ForbiddenError";
import { HandleErrorRoutes } from "../models/handleErrorRoutes.model";
import { updateTokens } from "../storage/localStorage.db";
import ConnectionError from "./ConnectionError";
import NotFoundError from "./NotFoundError";
import ParsingError from "./ParsingError";

export default function handleError(error: any) {
  if (error instanceof AuthError) {
    updateTokens("", "");
    return HandleErrorRoutes.Login;
  }
  if (error instanceof ForbiddenError) {
    return HandleErrorRoutes.NotFound;
  }
  if (error instanceof NotFoundError) {
    return HandleErrorRoutes.NotFound;
  }
  if (error instanceof ConnectionError) {
    return HandleErrorRoutes.ConnectionError;
  }
  if (error instanceof ParsingError) {
    return HandleErrorRoutes.ConnectionError;
  }
  return HandleErrorRoutes.NotFound;
}
