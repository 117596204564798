import img1 from "../../../assets/img/costic/food-5.jpg";
import img3 from "../../../assets/img/costic/food-4.jpg";
import { Link } from "react-router-dom";
import LanguageChanger from "../../../core/components/LanguajeChangerComponent";
import LogOut from "../components/LogoutComponent";
import { useContext, useEffect, useState } from "react";
import { BrowserRoutes } from "../model/browserRoutes.model";
import { ModulesNames } from "../model/modulesNames.model";
import { LanguegeContext } from "../../../core/hooks/languages/LanguageContext";
import useConfig from "../../../core/hooks/config/useConfig";

export default function DashboardScreen() {
  const { json } = useContext(LanguegeContext);
  const config = useConfig();
  const [cards, setCards] = useState<
    {
      photo: string;
      title: string;
      route: string;
    }[]
  >([]);

  useEffect(() => {
    if (config.length == 0) return;
    const auxCards = [];
    if (config.includes(ModulesNames.Login)) {
      auxCards.push({
        photo: img1,
        title: BrowserRoutes.Login,
        route: "/login",
      });
    }
    if (config.includes(ModulesNames.Subsidiaries)) {
      auxCards.push({
        photo: img1,
        title: config.includes(ModulesNames.Companies)
          ? json.dashboard["Subsidiaries"]
          : json.dashboard["Subsidiary"],
        route: "/sucursales",
      });
    }
    if (config.includes(ModulesNames.Companies)) {
      auxCards.push({
        photo: img3,
        title: config.includes(ModulesNames.Corporates)
          ? json.dashboard["companies"]
          : json.dashboard["company"],
        route: "/empresas",
      });
    }
    if (config.includes(ModulesNames.Corporates)) {
      auxCards.push({
        photo: img1,
        title: config.includes(ModulesNames.Login)
          ? json.dashboard["corporates"]
          : json.dashboard["corporate"],
        route: "/corporativos",
      });
    }
    setCards(auxCards);
  }, [config]);

  // useEffect(() => {
  //   if (config.length == 0) return;

  // }, [config]);

  return (
    <div className="ms-panel">
      <div className="ms-panel-header">
        <h6>{json.dashboard.dashboard}</h6>
      </div>
      <div className="ms-panel-body">
        <div className="row">
          {cards.map((item, i) => (
            <Link
              to={item.route}
              key={i}
              className="col-xl-3 col-lg-6 col-md-6"
            >
              <div className="ms-card no-margin">
                <div className="ms-card-img">
                  <img
                    className="text-uppercase"
                    src={item.photo}
                    alt="card_img"
                  />
                </div>
                <div className="ms-card-body">
                  <div className="ms-card-heading-title">
                    <h6 className="text-capitalize">{item.title}</h6>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
      <LogOut />
      <LanguageChanger />
    </div>
  );
}
