import { HttpMethod } from "../../../core/models/httpMethod";
import validateRequest from "../../../core/service/validateRequest.middleware";
import { getAccessToken } from "../../../core/storage/localStorage.db";
import { APICorporateRoutes } from "../model/apiCorporateRoutes";
import Corporate from "../model/corporate.model";

export default async function addCorporate(corporate: Corporate) {
  await validateRequest(
    HttpMethod.Post,
    APICorporateRoutes.Create,
    getAccessToken(),
    corporate
  );
}
