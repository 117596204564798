import ColumnNames from "./model/columnNames.model";

export const exportToCSV = (data: any[], filename: string) => {
  const csvRows = [];
  const headers = [
    ColumnNames.Id,
    ColumnNames.OrderId,
    ColumnNames.CompanyName,
    ColumnNames.SubsidiaryName,
    ColumnNames.UserName,
    ColumnNames.UserEmail,
    ColumnNames.UserPhone,
    ColumnNames.DateTime,
    ColumnNames.Status,
    ColumnNames.Total,
  ];
  csvRows.push(headers.join(","));

  for (const row of data) {
    const values = headers.map((header) => {
      let value;

      switch (header) {
        case ColumnNames.CompanyName:
          value = row.companyInformation
            ? row.companyInformation.companyName
            : "";
          break;
        case ColumnNames.SubsidiaryName:
          value = row.companyInformation
            ? row.companyInformation.subsidaryName
            : "";
          break;
        case ColumnNames.UserName:
          value = row.userInformation ? row.userInformation.fullName : "";
          break;
        case ColumnNames.UserEmail:
          value = row.userInformation ? row.userInformation.email : "";
          break;
        case ColumnNames.UserPhone:
          value = row.userInformation ? row.userInformation.phone : "";
          break;
        default:
          value = row[header] ?? "";
      }
      const escaped = ("" + value).replace(/"/g, '\\"');
      return `"${escaped}"`;
    });

    csvRows.push(values.join(","));
  }

  const blob = new Blob([csvRows.join("\n")], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.setAttribute("hidden", "");
  a.setAttribute("href", url);
  a.setAttribute("download", filename);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const handleExport = (filteredData: any[], filename = "orders.csv") => {
  exportToCSV(filteredData, filename);
};
