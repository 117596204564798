export const options = {
  responsive: true,
  scales: {
    y: {
      beginAtZero: true,
      title: {
        display: true,
      },
    },
    x: {
      title: {
        display: true,
      },
    },
  },
  plugins: {
    legend: {
      display: true,
      position: "top",
    },
  },
};
