import { useContext, useState } from "react";
import { LanguegeContext } from "../../../../core/hooks/languages/LanguageContext";
import { regexList } from "../../../../core/utils/validationRegex";
import PropsPage from "../../model/propsWizardPage.model";
import InputFieldFile from "../../../../core/components/formFields/InputFieldFileComponent";
import { InputCompanyNames } from "../../model/inputCompanyNames";

export default function FilesComponent(props: PropsPage) {
  const { json } = useContext(LanguegeContext);

  const [validityPage, setValidityPage] = useState({
    proof: false,
    cover: false,
    id: false,
  });

  return (
    <fieldset className="col-sm-12 pb-3" hidden={props.page !== 6}>
      <p className="text-danger">{json.companies.requiredFields}</p>
      <legend className="bold mb-3">{json.companies.files}</legend>
      <div className="form-row">
        <InputFieldFile
          label={json.companies.proofTaxSituation}
          name={InputCompanyNames.ProofTaxSituation}
          regex={regexList.file}
          required
          setValidity={(validity: boolean) =>
            setValidityPage({ ...validityPage, proof: validity })
          }
        />

        <InputFieldFile
          label={json.companies.bankCover}
          name={InputCompanyNames.BankCover}
          regex={regexList.file}
          required
          setValidity={(validity: boolean) =>
            setValidityPage({ ...validityPage, cover: validity })
          }
        />

        <InputFieldFile
          label={json.companies.idCopy}
          name={InputCompanyNames.IdCopy}
          regex={regexList.file}
          required
          setValidity={(validity: boolean) =>
            setValidityPage({ ...validityPage, id: validity })
          }
        />

        <InputFieldFile
          label={json.companies.alcoholLicense}
          name={InputCompanyNames.AlcoholLicense}
          regex={regexList.file}
          required={false}
        />
      </div>
      <input
        type="button"
        value={json.companies.buttonBack}
        className="btn btn-primary mx-2"
        onClick={() => props.setPage(props.page - 1)}
      />
      <input
        type="submit"
        value={json.companies.buttonEnd}
        className="btn btn-primary mx-2"
        disabled={
          !(validityPage.proof && validityPage.cover && validityPage.id)
        }
      />
    </fieldset>
  );
}
