export default function InputFieldSelectComponent(props: {
  label: string;
  name: string;
  items: string[];
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}) {
  return (
    <div className="col-md-12 mb-2">
      <label htmlFor="validationCustom04">
        {props.label}
        <span className="text-danger"> *</span>
      </label>
      <div className="input-group">
        <select
          name={props.name}
          className="form-control form-select border-secondar"
          defaultValue="bebidas"
          onChange={props.onChange}
        >
          {props.items.map((item, index) => (
            <option key={index} value={item}>
              {item}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
