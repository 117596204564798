import { HttpMethod } from "../../../core/models/httpMethod";
import validateRequest from "../../../core/service/validateRequest.middleware";
import { getAccessToken } from "../../../core/storage/localStorage.db";
import { APICorporateRoutes } from "../model/apiCorporateRoutes";

export default async function deleteCorporate(id: string) {
  await validateRequest(
    HttpMethod.Delete,
    `${APICorporateRoutes.Delete}${id}`,
    getAccessToken()
  );
}
